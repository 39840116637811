import React from "react";
import Tnews from "../../assets/css/components/TopNews.module.css";
import Global from "../../assets/css/Global.module.css";
import NewsContainer from "../../containers/News";
import AllVideos from "../../containers/videoOvervie/Videos";

export default class News extends React.Component {
  render() {
    return (
      <div className={`${Global.container} ${Global.row} ${Tnews.two_column}`}>
        <NewsContainer />
        <div className={Global.right_box_block}>
          <AllVideos />
        </div>
      </div>
    );
  }
}
