import React from "react";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import { connect } from "react-redux";
import {
  getCurrencyDescription,
  getSymbolQuotData
} from "../../redux/actions/indexActions";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class CurrencyPairs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstDesc: "",
      secondDesc: ""
    };
  }

  getCurrencyDesc() {
    // console.error(this.props.match.params);
    if (
      !this.props.match.params.type ||
      this.props.match.params.type === "forex"
    ) {
      const query = {
        lang: "en",
        phrases: `${this.props.currency.value
          .slice(0, 3)
          .toUpperCase()}_F_PROFILE`
      };
      const query_2 = {
        lang: "en",
        phrases: `${this.props.currency.value
          .slice(3, 6)
          .toUpperCase()}_F_PROFILE`
      };
      const queryParams = Object.keys(query)
        .map(key => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
        })
        .join("&");
      const queryParams_2 = Object.keys(query_2)
        .map(key => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(query_2[key])
          );
        })
        .join("&");

      fetch("https://www.ifcm.co.uk:2083/phrases", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: queryParams
      })
        .then(res => res.json())
        .then(res => {
          this.setState({ firstDesc: res });
        })
        .catch(err => console.log(err));
      fetch("https://www.ifcm.co.uk:2083/phrases", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: queryParams_2
      })
        .then(res2 => res2.json())
        .then(res2 => {
          this.setState({ secondDesc: res2 });
        })
        .catch(err => console.log(err));
    } else {
      // fetch(`https://www.ifcm.co.uk:2083/page/about-us?lang=${this.props.language}`, {
      fetch(
        `https://www.ifcm.co.uk:2083/instrument/description?lang=en&instrName=${this.props.currency.value}`,
        {
          method: "get",
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
      )
        .then(res => res.json())
        .then(data => {
          // console.error(data);
          this.setState({ firstDesc: data, secondDesc: "" });
        })
        .catch(err => console.log(err));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currency.value !== prevProps.currency.value) {
      this.getCurrencyDesc();
    }
  }

  componentDidMount() {
    // console.warn("TYPE", this.props.match.params);
    this.getCurrencyDesc();
  }

  render() {
    const { t } = this.props;
    // console.error("T^T^T^T^T^T^T^T^T^T^T^T^T^T^T^T", this.props.currency.value)

    return (
      <div className="bg_wrap container section_cont">
        {/*<div className="txt_bl_curr">*/}
        {/*  <h2>Currency Pairs</h2>*/}
        {/*  <p>*/}
        {/*    Currency pairs are traditional trading instruments in Forex market.*/}
        {/*    A currency pair is the quotation of two different currencies, with*/}
        {/*     the value of one being quoted against another. Based on popularity*/}
        {/*     they are called Major/ Minor/ Exotic pairs.*/}
        {/*   </p>*/}
        {/**/}
        {/*   <p>*/}
        {/*    Live chart is designed to assist you on choosing the most profitable*/}
        {/*    currency pair based on current market conditions. Compare the live*/}
        {/*    prices and make successful deals.*/}
        {/*  </p>*/}
        {/*</div>*/}
        {renderHtml(t("CURRENCY_PAIRS"))}

        <h2>About {this.props.currency.value}</h2>
        {!this.props.match.params.type ||
        this.props.match.params.type === "forex" ? (
          <div className="txt_bl_curr curr_p_block">
            <div>
              {/*<div className="top_desc"></div>*/}
              <div className="subheadline_block">
                {renderHtml(
                  this.state.firstDesc[
                    `${this.props.currency.value
                      .slice(0, 3)
                      .toUpperCase()}_F_PROFILE`
                  ]
                )}
              </div>
            </div>
            <div>
              {/*<div className="top_desc"></div>*/}
              <div className="subheadline_block">
                {renderHtml(
                  this.state.secondDesc[
                    `${this.props.currency.value
                      .slice(3, 6)
                      .toUpperCase()}_F_PROFILE`
                  ]
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="txt_bl_curr curr_p_block">
            <div className="one_column">
              {/*<div className="top_desc"></div>*/}
              <div className="subheadline_block">
                {renderHtml(this.state.firstDesc.description)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    newQuoteData: store.newQuoteData,
    instrName: store.instrName,
    chartInstrument: store.chartInstrument,
    currencyDescriotion: store.currencyDescriotion
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getSymbolQuotData: newCurrency => dispatch(getSymbolQuotData(newCurrency)),
    getCurrencyDescription: curDesc => dispatch(getCurrencyDescription(curDesc))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrencyPairs))
);
