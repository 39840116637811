import React from "react";
import BrokerRating from "../components/BrokerRating";

import "./../assets/css/components/broker_rating.css";
import ANALYSIS from "../containers/Analysis";
import { connect } from "react-redux";
import { attemptGetBrokerList } from "../redux/actions/indexActions";
import { translate } from "react-i18next";
import { BROKERS_ROUTE } from "../configs/constants";
import { Link } from "react-router-dom";

const BROKER_RATING_HEADERS = [
  "",
  "",
  "",
  "MINIMUM_SPREAD",
  "MINIMUM_DEPOSIT",
  "FOUNDED",
  ""
];

class BrokerRatings extends React.Component {
  componentDidMount() {
    this.props.attemptGetBrokerList(this.props.language, 10, 1);
  }

  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    const { t } = this.props;
    console.error("BROKERS", this.props.brokers);
    return (
      <div className="container brokers_rating row">
        <div className="brokers_container main_with_left">
          <h2>
            {/*Brokers Rating*/}
            {t("BROKERS_RATING")}
          </h2>
          <div className="bg_wrap">
            <div className="swipe">
              {/*Swipe table <span className="icon-right-small"></span>*/}
              {t("SWIPE_TABLE")}
            </div>
            <div>
              <table>
                <thead className="RatingCustom">
                  <tr>
                    {BROKER_RATING_HEADERS.map((header, index) => (
                      <th key={index}>{t(header)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.props.brokers ? (
                    this.props.brokers.map((item, index) => {
                      return (
                        <BrokerRating
                          id={index}
                          key={index}
                          logo={item.logo}
                          name={item.name}
                          rate={item.averageRate.toFixed(1)}
                          // minSpread={item.minSpread.toFixed(1)}
                          minSpread={item.minSpread}
                          minDeposit={item.minDeposit}
                          founded={item.founded}
                          btnText={item.externalUrl}
                          sysname={item.sysname}
                        />
                      );
                    })
                  ) : (
                    <h1 className="loader">
                      {/*loading***/}
                      {t("LOADING")}
                    </h1>
                  )}
                </tbody>
              </table>
            </div>
            <Link onClick={this.scrolling.bind(this)} to={BROKERS_ROUTE}>
              {t("SEE_ALL_BROKERS")}
              <span className="right_arrow">
                <i className="icon-right-small"></i>
              </span>
            </Link>
          </div>
        </div>
        <div className="right_box_block">
          <ANALYSIS />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brokers: store.brokers,
    language: store.language
  };
};
function mapDispatchToProps(dispatch) {
  return {
    attemptGetBrokerList: (lang, limit, orderby) =>
      dispatch(attemptGetBrokerList(lang, limit, orderby))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(BrokerRatings)
);
