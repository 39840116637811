import React from "react";
import { translate } from "react-i18next";
import ForexGradSocket from "../api/WebSocket";
import { connect } from "react-redux";
import TopGainersItem from "./TopGainersItem";

class TabsContDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      down: []
    };
  }

  getTopGainer() {
    const Socket = new ForexGradSocket(
      () => Socket.getTopGainers(this.props.language, 1),
      data => {
        this.setState({ down: data.down });
      }
    );
  }

  componentDidMount() {
    this.getTopGainer(this.props.language, 1);
  }

  render() {
    console.warn("*(*(*(*(*(*(*()*)*)*)*)*)*)*", this.state);

    const { t } = this.props;
    return (
      <div className="tab_container">
        <div>
          <table>
            <thead>
              <tr>
                <td className="table_head">
                  {/*Name*/}
                  {t("NAME")}
                </td>
                <td className="table_head">
                  {/*Instrument*/}
                  {t("INSTRUMENT")}
                </td>
                <td className="table_head">
                  {/*Change*/}
                  {t("CHANGE")}, %
                </td>
                <td className="table_head">
                  {/*Change*/}
                  {t("CHANGE")}, $
                </td>
                <td className="table_head">
                  {/*Live Prices*/}
                  {t("LIVE_PRICES")}
                </td>
              </tr>
            </thead>
            <tbody>
              {this.state.down.length ? (
                this.state.down.map((item, index) => {
                  return (
                    <TopGainersItem
                      symbol={item.symbol}
                      transname={item.transname}
                      percent={item.percent}
                      change={item.change}
                      key={index}
                    />
                  );
                })
              ) : (
                <tr>
                  <td className="table_head">
                    {/*Name*/}
                    ---
                  </td>
                  <td className="table_head">
                    {/*Instrument*/}
                    ---
                  </td>
                  <td className="table_head">
                    {/*Change*/}
                    ---
                  </td>
                  <td className="table_head">
                    {/*Instrument*/}
                    ---
                  </td>
                  <td className="table_head">
                    {/*Change*/}
                    ---
                  </td>
                  <td className="table_head">
                    {/*Change*/}
                    ---
                  </td>
                  <td className="table_head">
                    {/*Live Prices*/}
                    ---
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language
  };
};
export default connect(mapStateToProps)(translate("common")(TabsContDown));
