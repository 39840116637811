import React from "react";
import { Link } from "react-router-dom";
import {
  ARTICLES,
  ARTICLES_RSS,
  BROKERS_ABOUT_US,
  BROKERS_CHARTS,
  BROKERS_EDUCATIONS,
  EXPERTS,
  MARKET_COMMODITY_CALENDAR,
  MARKET_MOVERS,
  MARKET_OVERVIEW,
  MARKET_SENTIMENT,
  RSS_1,
  RSS_10,
  RSS_11,
  RSS_12,
  RSS_13,
  RSS_14,
  RSS_15,
  RSS_16,
  RSS_17,
  RSS_18,
  RSS_19,
  RSS_2,
  RSS_20,
  RSS_21,
  RSS_3,
  RSS_4,
  RSS_5,
  RSS_6,
  RSS_7,
  RSS_8,
  RSS_9,
  TOP_TRADERS
} from "../../configs/constants";
import { BROKERS_ROUTE } from "../../configs/constants";
import { BROKERS_ANALYTICS } from "../../configs/constants";
import { BROKERS_TRADING_PLATFORMS } from "../../configs/constants";
import { BROKERS_CALENDARS } from "../../configs/constants";
import { MARKET_OVERVIEW_LIST } from "../../configs/constants";
import { BROKERS_INNOVATION } from "../../configs/constants";
import { translate } from "react-i18next";
import LangMenu from "./LangMenu";
import Watch from "./Watch";
import "./custom.css";

class MenuNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "active",
      flag_none: "none"
    };
  }

  openNav = () => {
    document.getElementById("mobMenu").style.right = "0";
    var body = document.body;
    body.classList.add("body_hover");
    // if (document.getElementById("fil")) {
    //   document.getElementById("fil").style.width = "0";
    //   var content = document.getElementsByClassName("content")[0];
    //   content.classList.remove("content_hover");
    //   document
    //     .getElementsByClassName("black_opacity")[0]
    //     .classList.remove("active");
    // }

    document.getElementsByTagName("body")[0].addEventListener("click", e => {
      if (!e.target.classList.contains("dropbtn")) {
        document.getElementById("mobMenu").style.right = "-250px";
        var body = document.body;
        body.classList.remove("body_hover");
      }
    });
  };

  // closeNav = (e) => {
  //   console.log(e.target);
  //   if(e.target.parents())
  //   document.getElementById("mobMenu").style.width = "0";
  //   var body = document.body;
  //   body.classList.remove("body_hover");
  // };

  scrolling(e) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const elem = document.querySelectorAll(".toggable");
    elem.forEach(elem => {
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
      }
    });
    e.target.classList.add(this.state.activeClass);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="menu">
        <div className="container">
          <LangMenu />
          <Watch />
          <div>
            <button type="img" className="close sendsubmit">
              <i className="icon-search"></i>
            </button>
            <div id="menu_button" onClick={this.openNav.bind(this)}>
              &#9776;
            </div>
          </div>
          <div className="top_menu_wrapper">
            <ul id="mobMenu" className="menu_list">
              <li>
                <div className="closebtn">&times;</div>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_ABOUT_US}
                >
                  {/*About Us*/}
                  {t("ABOUT_US")}
                </Link>
              </li>

              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_INNOVATION}
                >
                  {/*Innovations*/}
                  {t("INNOVATIONS")}
                </Link>
              </li>

              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_CHARTS}
                >
                  {/*Charts*/}
                  {t("GO_TO_CHART_HEADER")}
                </Link>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_ROUTE}
                >
                  {/*Brokers*/}
                  {t("BROKERS")}
                </Link>
              </li>
              <li>
                {/*<Link onClick={this.scrolling.bind(this)} to={BROKERS_FOREX_TOOLS}>*/}
                {/*/!*Forex Tools*!/*/}
                {/*{(t('FOREX_TOOLS'))}*/}
                {/*</Link>*/}
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_TRADING_PLATFORMS}
                >
                  {/*Trading Platform***/}
                  {t("TRADING_PLATFORM")}
                </Link>
              </li>
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_CALENDARS}
                >
                  {/*Calendars*/}
                  {t("CALENDARS")}
                </Link>
              </li>
              <li className="menu-dropdown-event">
                <div className="dropdown w-100 dropdown-menu-right">
                  <button className="dropbtn w-100 text-right">
                    {/*Technical analytics***/}
                    {t("ANALYTICS")}
                  </button>
                  <div className="dropdown-content">
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={BROKERS_ANALYTICS}
                    >
                      {/*Analytics*/}
                      {t("TECHNICAL_ANALYTICS")}
                    </Link>
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={MARKET_SENTIMENT}
                    >
                      {/*Market sentiment**RSS*/}
                      {t("MARKET_SENTIMENT")}
                    </Link>
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={MARKET_OVERVIEW}
                    >
                      {t("LIST_MARKET_OVERVIEW")}
                      {/*{t("MARKET_SENTIMENT")}*/}
                      {/*{t("MARKET_SENTIMENT")}*/}
                      {/*{t("MARKET_SENTIMENT")}*/}
                    </Link>
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={MARKET_MOVERS}
                    >
                      {/*Analytics*/}
                      {/*Market movers***/}
                      {t("WIDGETS_MARKET_MOVERS")}
                    </Link>
                    <Link
                      className="toggable"
                      onClick={this.scrolling.bind(this)}
                      to={TOP_TRADERS}
                    >
                      {/*Analytics*/}
                      {/*Top trades***/}
                      {t("TOP_TRADES")}
                    </Link>
                  </div>
                </div>
              </li>
              {/*<li>*/}
              {/*  <Link onClick={this.scrolling.bind(this)} to={BROKERS_EDUCATIONS}>*/}
              {/*  /!*Educations*!/*/}
              {/*  {(t('EDUCATIONS'))}*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link onClick={this.scrolling.bind(this)} to={EXPERTS}>*/}
              {/*  Experts*/}
              {/*  /!*{(t('EDUCATIONS'))}*!/*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link onClick={this.scrolling.bind(this)} to={ARTICLES}>*/}
              {/*    /!*Articles*!/*/}
              {/*    {t("ADVISORS_LINKS")}*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={ARTICLES}
                >
                  {/*Articles*/}
                  {t("ADVISORS_LINKS")}
                  {/*Experts*/}
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link onClick={this.scrolling.bind(this)} to={ARTICLES_RSS}>*/}
              {/*    Articles_rss*/}
              {/*    /!*{t("ADVISORS_LINKS")}*!/*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link
                  className="toggable"
                  onClick={this.scrolling.bind(this)}
                  to={MARKET_OVERVIEW_LIST}
                >
                  {/*News*/}
                  {t("WIDGETS_NEWS")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("common")(MenuNav);
