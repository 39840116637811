import React from "react";
import "./../../assets/css/components/menu.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onLanguageChange } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";

class LangMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      cur: 0,
      show: "none",
      show2: "none",
      // common: "en",
      data: [
        {
          name: "United Kingdom",
          lang: "flag_24 en",
          href: "/",
          common: "en"
        },
        {
          name: "Russia",
          lang: "flag_24 ru",
          href: "https://www.cfdrates.com/ru",
          common: "ru"
        },
        // {
        //   name: "Spain",
        //   lang: "flag_24 es",
        //   href: "https://www.cfdrates.com/es",
        //   common: "es"
        // },
        // {
        //   name: "Portugal",
        //   lang: "flag_24 pt",
        //   href: "https://www.cfdrates.com/pt",
        //   common: "pt"
        // },
        {
          name: "Saudi Arabia",
          lang: "flag_24 ar",
          href: "https://www.cfdrates.com/ar",
          common: "ar"
        }
        // {
        //   name: "Turkey",
        //   lang: "flag_24 tr",
        //   href: "https://www.cfdrates.com/tr",
        //   common: "tr"
        // },
        // {
        //   name: "Iran",
        //   lang: "flag_24 fa",
        //   href: "https://www.cfdrates.com/fa",
        //   common: "fa"
        // },
        // {
        //   name: "Vietnam",
        //   lang: "flag_24 vi",
        //   href: "https://www.cfdrates.com/vi",
        //   common: "vi"
        // },
        // {
        //   name: "Japan",
        //   lang: "flag_24 ja",
        //   href: "https://www.cfdrates.com/ja",
        //   common: "ja"
        // },
        // {
        //   name: "Taiwan",
        //   lang: "flag_24 zh",
        //   href: "https://www.cfdrates.com/zh",
        //   common: "zh"
        // },
        // {
        //   name: "China",
        //   lang: "flag_24 zh_cn",
        //   href: "https://www.cfdrates.com/zh_cn",
        //   common: "zh_cn"
        // },
        // {
        //   name: "Indonesia",
        //   lang: "flag_24 ID",
        //   href: "https://www.cfdrates.com/ID",
        //   common: "ID"
        // },
        // {
        //   name: "France",
        //   lang: "flag_24 fr",
        //   href: "https://www.cfdrates.com/fr",
        //   common: "fr"
        // },
        // {
        //   name: "Czech Republic",
        //   lang: "flag_24 cs",
        //   href: "https://www.cfdrates.com/cs",
        //   common: "cs"
        // },
        // {
        //   name: "India",
        //   lang: "flag_24 hi",
        //   href: "https://www.cfdrates.com/hi",
        //   common: "hi"
        // },
        // {
        //   name: "Italy",
        //   lang: "flag_24 it",
        //   href: "https://www.cfdrates.com/it",
        //   common: "it"
        // },
        // {
        //   name: "Malaysia",
        //   lang: "flag_24 ms",
        //   href: "https://www.cfdrates.com/ms",
        //   common: "ms"
        // }
      ]
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

  hydrateStateWithLocalStorage() {
    // for all items in state
    for (let key in this.state) {
      // if the key exists in localStorage
      if (localStorage.hasOwnProperty(key)) {
        // get the key's value from localStorage
        let value = localStorage.getItem(key);
        // parse the localStorage string and setState
        try {
          value = JSON.parse(value);
          this.setState({ [key]: value });
        } catch (e) {
          // handle empty string
          this.setState({ [key]: value });
        }
      }
    }
  }

  componentDidMount() {
    this.hydrateStateWithLocalStorage();
  }

  render() {
    const { language, i18n } = this.props;

    return (
      <div className="lang_menu">
        <ul>
          <li className="flag" onClick={this.showMenu}>
            <span className={`flag_24 ${language}`} />
            <span className="flag_text">
              {localStorage.getItem("language") || "en"}
            </span>
            <span className="flag_arrow" />

            {this.state.showMenu ? (
              <div className="flag_none" style={{ display: "block" }}>
                {this.state.data.map((item, index) => {
                  return (
                    <div className=" " key={index}>
                      <a
                        href={item.href}
                        className="local_href"
                        onClick={() => {
                          this.props.onLanguageChange(item.common);
                          const pathsArray = this.props.location.pathname.split(
                            "/"
                          );
                          let newPathName;
                          pathsArray.forEach((e, i) => {
                            newPathName += "/";
                            if (i === 1) {
                              newPathName += item.common;
                            } else {
                              newPathName += e;
                            }
                          });
                          i18n.changeLanguage(item.common);
                          document.body.dir = `${
                            item.common === "ar" || item.common === "fa"
                              ? "rtl"
                              : "ltr"
                          }`;
                          this.setState({ cur: index, common: item.common });
                          // localStorage.setItem (index, index);
                          localStorage.setItem("cur", JSON.stringify(index));
                          localStorage.setItem("language", item.common);
                          // localStorage.setItem("common", JSON.stringify(index));
                        }}
                      >
                        <span className={item.lang} />
                        {item.name}
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </li>
        </ul>
      </div>
    );
  }
}

export default connect(
  state => ({
    language: state.language
  }),
  dispatch => ({
    onLanguageChange: language => dispatch(onLanguageChange(language))
  })
)(translate("common")(withRouter(LangMenu)));
