import React from "react";
import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import TopBrokers from "../../containers/TopBrokers";
import Videos from "../../containers/videoOvervie/Videos";
import { getMarketOverviewList } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import Global from "../../assets/css/Global.module.css";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Select from "react-select";
import Modal from "../rss/Modal";
import Pagination from "react-js-pagination";

// overwrite style
const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0,0.5)"
  }
};

const mainStyle = {
  app: {},
  button: {
    backgroundColor: "#408cec",
    border: 0,
    padding: "12px 20px",
    color: "#fff",
    margin: "0 auto",
    width: 150,
    display: "block",
    borderRadius: 3
  }
};

const scrolling = () =>  {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}


// ifcm-top-trades
// https://127.0.0.1:2083/rss/${this.props.currentPage}?lang=${this.props.language}
const instrGroup = [
  // { value: "", label: "select all" },
  { value: "gurutrade-news-finance", label: "Gurutrade" },
  { value: "ifc", label: "ifc" },
  { value: "instaforex-news", label: "instaforex" },
  { value: "cnbc-device", label: "cnbc" },
  { value: "myfxbook-forex-community", label: "myfxbook" }
];

const DATA = {
  "gurutrade-news-finance": "GURUTRADE",
  ifc: "ifc",
  "nbc-device": "cnbc",
  "myfxbook-forex-community": "myfxbook",
  "instaforex-news": "instaforex"
};

// import EducationSections from "./MarketOverviewDetail";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class TechnicalAnalysisRss extends React.Component {
  constructor(props) {
    super(props);
    const path = this.props.history.location.pathname.split("/")[2] || "ifc";
    this.state = {
      rssItems: null,
      brokerName:
        DATA[this.props.history.location.pathname.split("/")[2]] || "ifc",
      news: "gurutrade-news-finance",
      brokerData: { value: path, label: DATA[path] },
      show: false,
      imageUrl: "",
      isModalOpen: false,
      isInnerModalOpen: false,

      itemsCount: 0,
      activePage: 1,
      page: 1
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  // close modal (set isModalOpen, true)
  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  // open modal (set isModalOpen, false)
  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  getDate = arg => {
    const date = new Date(arg);
    let month = null;
    if (date.getMonth() + 1 < 10) {
      month = "0" + (date.getMonth() + 1);
    } else month = date.getMonth() + 1;
    let day = null;
    if (date.getDate() + 1 < 10) {
      day = "0" + (date.getDate() + 1);
    } else day = date.getDate() + 1;

    return date.getFullYear() + "-" + month + "-" + day;
  };

  handleChangeBroker(name, news) {
    this.setState({ brokerName: name, news });
  }

  attemptGetData(lang, activePage) {
    fetch(
      `https://www.ifcm.co.uk:2083/tech-analys?lang=${lang}&countPerPage=5&page=${activePage}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data.list, itemsCount: data.count }, () => {
          const images = Array.from(document.querySelectorAll("img"));
          images.forEach(image => {
            console.log(image.src);
            image.addEventListener("click", () => {
              // alert(image.src)

              this.setState({ imageUrl: image.src }, () => this.openModal());
            });
          });
        });
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.attemptGetData(this.props.language, this.state.activePage);
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
    // this.attemptGetData(this.props.language, this.state.activePage);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.language !== prevProps.language ||
      this.state.activePage !== prevState.activePage
    ) {
      scrolling();
      this.attemptGetData(this.props.language, this.state.activePage);
    }
  }

  render() {
    console.log(
      this.props.history.location.pathname.split("/")[2],
      "pppppppppppppppppppppppppppppppp"
    );

    const { marketOverviewList, t } = this.props;
    const { rssItems, brokerName } = this.state;
    // console.log(this.state, "ppppppppppppppppppppppppppp");
    // console.log(this.props.history.location.pathname, "oooooooooooDATA[this.props.history.location.pathname]");
    console.log(rssItems, "brokerData");

    return (
      <div>
        <Helmet>
          <title>{`Market Overview | Daily Forex News | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="row">
          <div className="container row">
            <div className="video_less_wrap main_with_left">
              <h1>
                {/*NEWS*/}
                {t("TECHNICAL_ANALYTICS")}
              </h1>
              <div className="bg_wrap">
                {/*{marketOverviewList ? (*/}
                {/*    marketOverviewList.length > 0 ? (*/}
                {/*        marketOverviewList.map((item, index) => {*/}
                {rssItems ? (
                  rssItems.map((item, index) => {
                    return (
                      <div key={index} className="video_cont_block">
                        {/*<div className="vid_cont_block_txt no-padding">*/}
                        <div>
                          <div className="video_less_title">
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={item.link}
                              // href={item.url}
                            >
                              {/*<Link to={MARKET_OVERVIEW_DETAILS_CREATOR(item.slug)}>*/}
                              {item.title}
                              {/*</Link>*/}
                            </a>
                          </div>
                          <div className="new_cont_block tech_analysis_img">
                            {renderHtml(item.description)}
                            {/*{renderHtml(item.text)}*/}
                          </div>
                          <div className="vdl_footer_bl">
                            {/*<div className="date_block">{this.getDate(item.date)}</div>*/}
                            {/*<div className="date_block">{`${*/}
                            {/*  item.date.split("T")[0]*/}
                            {/*} ${item.date.split("T")[1].slice(0, 5)}`}</div>*/}
                            <div className="date_block">{item.date}</div>

                            <div>
                              source by: <a href={item.link}>{item.sysname}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </h1>
                )}
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={5}
                  totalItemsCount={this.state.itemsCount}
                  pageRangeDisplayed={5}
                  onChange={e => this.handlePageChange(e)}
                />
              </div>
            </div>
            <div className="right_box_block">
              <div className="expel_class">
                <Videos />
              </div>
              <div className="expel_class">
                <TopBrokers />
              </div>
            </div>
          </div>
        </div>
        <main>
          <Modal
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            style={modalStyle}
          >
            <img
              width="100%"
              style={{ borderRadius: 3, height: 500, width: "auto" }}
              src={this.state.imageUrl}
              alt="unsplash"
            />
          </Modal>
        </main>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    marketOverviewList: store.marketOverviewList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getMarketOverviewList: lang => dispatch(getMarketOverviewList(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TechnicalAnalysisRss))
);
