import React from "react";
import LiveQuotes from "./LiveQuotes";
import Global from "../../assets/css/Global.module.css";
import News from "./News";
import BrokerRatings from "../../containers/BrokerRatings";
import CalendarContainer from "./../content/CalendarContainer";
import ExpertCorner from "../../containers/articles/ExpertCorner";
import TopTradesBlock from "../../containers/topTrades/TopTradesBlock";
import { Helmet } from "react-helmet";

export default class Index extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>{`Financial Market News | Live Market Quotes | Brokers Ratings | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div>
          <LiveQuotes />
          <News />
          <BrokerRatings />
          <CalendarContainer />
          {/*<TopTradesContainer showTradeBlock={true} />*/}
          <div className={`${Global.container} ${Global.row}`}>
            <TopTradesBlock />
            <ExpertCorner />
          </div>
          {/*<EducationSteps />*/}
        </div>
      </div>
    );
  }
}
