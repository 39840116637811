import React from "react";
import "./../../assets/css/components/brokers/latest_reviews.css";
import ReactStars from "react-stars";
import { attemptGetLatestReviews } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";

class LatestReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      page: 1
    };
  }

  handlePageChange(pageNumber) {
    this.props.attemptGetLatestReviews(this.props.sysname, 5, pageNumber);
    this.setState({ activePage: pageNumber });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.sysname !== prevProps.sysname ||
      this.props.countPerPage !== prevProps.countPerPage
    ) {
      this.props.attemptGetLatestReviews(
        this.props.sysname,
        5,
        this.state.activePage
      );
    }
  }

  componentDidMount() {
    this.props.attemptGetLatestReviews(this.props.sysname, 5, this.state.page);
  }

  render() {
    const { t } = this.props;
    console.error(this.props.latestReviews);
    return (
      <div className="">
        {this.props.latestReviews ? (
          this.props.latestReviews.list &&
          this.props.latestReviews.list.length > 0 ? (
            this.props.latestReviews.list.map((item, index) => {
              return (
                <div
                  className={`reviews_cont ${
                    item.rate * 2 < 4
                      ? "wewak"
                      : item.rate * 2 > 7
                      ? "yello_green"
                      : "hawkes_blue"
                  }`}
                  key={index}
                >
                  <div className="left_part_reviews">
                    <div className="top_reviews_img">
                      <img
                        src={item.logo}
                        width={80}
                        height={40}
                        alt={"dummyimage"}
                      />
                    </div>
                    <div className="rating">
                      <ReactStars
                        count={5}
                        value={item.rate}
                        size={20}
                        edit={false}
                        half={true}
                        color1={"#cfcfcf"}
                        color2={"#ffcc36"}
                      />
                    </div>
                    {/*<div className="rating_num">{item.rate*2}</div>*/}
                  </div>
                  <p className="reviews_txt">{item.review}</p>
                  <p className="reviews_author">{item.name}</p>
                </div>
              );
            })
          ) : (
            <h1>
              {/*NO REVIEWS***/}
              {t("NO_REVIEWS")}
            </h1>
          )
        ) : (
          <h1 className="loader">
            {/*loading***/}
            {t("LOADING")}
          </h1>
        )}
        {this.props.latestReviews &&
        this.props.latestReviews.list &&
        this.props.latestReviews.list.length > 0 ? (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={5}
            totalItemsCount={
              this.props.latestReviews && this.props.latestReviews.count
            }
            pageRangeDisplayed={5}
            onChange={e => this.handlePageChange(e)}
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language,
    latestReviews: store.latestReviews
  };
};
function mapDispatchToProps(dispatch) {
  return {
    attemptGetLatestReviews: (sysname, countPerPage, page) =>
      dispatch(attemptGetLatestReviews(sysname, countPerPage, page))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(LatestReviews)
);
