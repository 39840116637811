import React from "react";

import "./../assets/css/components/right_box/Analysis.css";
import { getTechAnalysList } from "../redux/actions/indexActions";
import { connect } from "react-redux";
import { translate } from "react-i18next";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);
class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      techAnalys: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.getTechAnalysList(this.props.language, 7, 1);
    }
  }

  componentDidMount() {
    this.props.getTechAnalysList(this.props.language, 7, 1);
    if (this.props.techAnalys) {
      this.setState({ techAnalys: this.props.techAnalys });
    }
  }

  render() {
    const { t } = this.props;
    // console.log("aaa " + JSON.stringify(this.props.techAnalys));
    return (
      <div className="right_box">
        <h2>
          {/*Analysis*/}
          {t("ANALYSIS")}
        </h2>
        <div className="analysis_cont">
          <div className="analysis">
            {this.props.techAnalys &&
              this.props.techAnalys.list.map(function(ta, index) {
                return (
                  <div className="analysis_blocks" key={index}>
                    <a
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href={ta.url}
                    >
                      <div className="title_block">{ta && ta.title}</div>
                    </a>
                    <div className="text_block">
                      {ta && renderHtml(ta.short_text)}
                    </div>
                    <div className="date_block">{ta && ta.date}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    techAnalys: store.techAnalys,
    language: store.language
  };
};

function mapDisspatchToProps(dispatch) {
  return {
    getTechAnalysList: (lang, countPerPage, page) =>
      dispatch(getTechAnalysList(lang, countPerPage, page))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDisspatchToProps)(Analysis)
);
