import React from "react";
import QuoteBlock from "./QuoteBlock";
import LiveQoute from "../../assets/css/components/LiveQoutes.module.css";
import Global from "../../assets/css/Global.module.css";
import { connect } from "react-redux";
import {
  attemptGetInstrumentsList,
  onChangeLiveQuote
} from "../../redux/actions/indexActions";
import { translate } from "react-i18next";

class LiveQuotes extends React.Component {
  componentDidMount() {
    this.props.getInstrumentsList();
    this.props.onChangeLiveQuote(this.props.live_quote_0, "0");
    this.props.onChangeLiveQuote(this.props.live_quote_1, "1");
    this.props.onChangeLiveQuote(this.props.live_quote_2, "2");
    this.props.onChangeLiveQuote(this.props.live_quote_3, "3");
    this.props.onChangeLiveQuote(this.props.live_quote_4, "4");
  }

  render() {
    const { t } = this.props;
    const array = [
      this.props.live_quote_0,
      this.props.live_quote_1,
      this.props.live_quote_2,
      this.props.live_quote_3,
      this.props.live_quote_4
    ];

    return (
      <div
        className={`${Global.container} ${LiveQoute.container} ${LiveQoute.live_quotes}`}
      >
        <h2>
          {/*Live Quotes*/}
          {t("LIVE_QUOTES_TITLE")}
        </h2>
        {array.map((item, index) => {
          return (
            <QuoteBlock
              quote={item}
              key={index}
              index={index}
              instruments={this.props.instruments}
              onChangeLiveQuote={quote =>
                this.props.onChangeLiveQuote(quote, index)
              }
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (store, ownProps = {}) => {
  return {
    live_quote_0: store.live_quote_0,
    live_quote_1: store.live_quote_1,
    live_quote_2: store.live_quote_2,
    live_quote_3: store.live_quote_3,
    live_quote_4: store.live_quote_4,
    instruments: store.instruments
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getInstrumentsList: () => dispatch(attemptGetInstrumentsList()),
    onChangeLiveQuote: (newQuote, index) =>
      dispatch(onChangeLiveQuote(newQuote, index))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(LiveQuotes)
);
