import React from "react";
import TopBrokers from "../../containers/TopBrokers";
import TopPromotionsBlock from "../../containers/TopPromotionsBlock.js";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Global from "../../assets/css/Global.module.css";
import Select from "react-select";

import {
  attemptSelectInstrumentsList,
  getTechAnalysList
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";
import { translate } from "react-i18next";

const SeeTechnicalAnalysis = [
  { value: "", label: "select all" },
  { value: "1day", label: "1day" },
  { value: "3days", label: "3days" },
  { value: "week", label: "week" },
  { value: "month", label: "month" }
];
const instrGroup = [
  { value: "", label: "select all" },
  { value: "forex", label: "forex" },
  { value: "indices", label: "indices" },
  { value: "commodities", label: "commodities" },
  { value: "stocks", label: "stocks" },
  { value: "precious-metals", label: "precious-metals" },
  { value: "gold-trading", label: "gold-trading" },
  { value: "personal-instrument-pci", label: "personal-instrument-pci" },
  { value: "futures", label: "futures" },
  { value: "cfd-etf", label: "cfd-etf" },
  { value: "cryptocurrency", label: "cryptocurrency" },
  { value: "cripto", label: "cripto" }
];

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);
const renderHtml2 = props => (
  <table dangerouslySetInnerHTML={{ __html: props }}></table>
);

class CurrencyTechnicalAnalysis extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      techAnalys: null,
      dateInterval: { value: null, label: "Select..." },
      // dateInterval: { value: null, label: `${t("SELECT_FROM_LIST")}`},//todo change after rendering
      instrGroup: { value: null, label: "Select..." },
      instrName: { value: null, label: "Select..." },
      selectedCategory: "forex",
      selectedInstrument: [],
      countPerPage: 1,
      activePage: 1
    };
  }

  handlePageChange(pageNumber) {
    this.props.getTechAnalysList(
      this.props.language,
      5,
      pageNumber,
      this.state.dateInterval.value,
      this.state.instrGroup.value,
      this.state.instrName.value
    );
    this.setState({ activePage: pageNumber });
  }

  handleSeeTechChange = dateInterval => {
    this.setState({ dateInterval });
  };

  handleInstrumentChange = instrName => {
    this.setState({ instrName });
  };

  onSearchTechnicalAnalysis() {
    this.props.getTechAnalysList(
      this.props.language,
      5,
      1,
      this.state.dateInterval.value,
      this.state.instrGroup.value,
      this.state.instrName.value
    );
  }

  handleInstrGroupChange = instrGroup => {
    this.setState({ instrGroup });
    // console.log(`Option selected:`, instrGroup.value);
    const self = this;
    fetch(`https://www.ifcm.co.uk:2083/instrument?group=${instrGroup.value}`)
      .then(res => res.json())
      .then(selectedInstrument => {
        self.setState({ selectedInstrument });
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.getTechAnalysList(
        this.props.language,
        5,
        this.state.countPerPage,
        this.state.dateInterval.value,
        this.state.instrGroup.value,
        this.state.instrName.value
      );
    }

    if (this.state.instrGroup.value !== prevState.instrGroup.value) {
      this.setState({ instrName: { value: null, label: "Select..." } });
    }
  }

  componentDidMount() {
    this.props.getTechAnalysList(
      this.props.language,
      5,
      this.state.countPerPage,
      this.state.dateInterval.value,
      this.state.instrGroup.value,
      this.state.instrName.value
    );

    const self = this;
    fetch(`https://www.ifcm.co.uk:2083/instrument`)
      .then(res => res.json())
      .then(selectedInstrument => {
        self.setState({ selectedInstrument });
      });
    if (this.props.techAnalys) {
      this.setState({ techAnalys: this.props.techAnalys });
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     if(this.props.language !== prevProps.language){
  //         this.props.attemptGetPlatformList(this.props.language);
  //     }
  // }
  //
  // componentDidMount() {
  //     this.props.attemptGetPlatformList(this.props.language);
  // }
  //

  render() {
    const instruments = this.state.selectedInstrument.map(item => {
      return { value: `${item.name}`, label: `${item.name}` };
    });
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <title>{`FOREX ANALYTICS | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className={`${Global.container} ${Global.row}`}>
          <div className={Global.main_with_left}>
            <h1>
              {/*FOREX ANALYTICS***/}
              {t("FOREX_ANALYTICS")}
            </h1>
            <div className={`${Global.bg_wrap} ${Analytics.filter_wrap}`}>
              <div className={Analytics.category_block}>
                <div className={Analytics.search_filter_form_row}>
                  <Select
                    value={this.state.dateInterval}
                    onChange={this.handleSeeTechChange}
                    options={SeeTechnicalAnalysis}
                  />
                </div>
                <div className={Analytics.search_filter_form_row}>
                  <Select
                    value={this.state.instrGroup}
                    onChange={this.handleInstrGroupChange}
                    options={instrGroup}
                  />
                </div>
                <div className={Analytics.search_filter_form_row}>
                  <Select
                    value={this.state.instrName}
                    onChange={this.handleInstrumentChange}
                    options={instruments}
                  />
                </div>

                <button
                  className={`${Global.btn} ${Global.btn_100} ${Global.button_green}`}
                  id="search_filter_submit"
                  href=""
                  onClick={() => this.onSearchTechnicalAnalysis()}
                >
                  {/*Search*/}
                  {t("FIND")}
                </button>
              </div>
            </div>
            <div className={Global.bg_wrap}>
              {this.props.techAnalys &&
                this.props.techAnalys.list.map((ta, index) => {
                  return (
                    <div key={index} className={Analytics.tech_wrap}>
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={ta.url}
                        // to={BROKERS_ANALYTICS_DETAILS_CREATOR(ta.instrName, ta.date)}
                      >
                        <div className={Analytics.tech_top}>
                          <h3>{ta && ta.title}</h3>
                          <div className={Global.date_block}>
                            {ta && ta.date}
                          </div>
                        </div>
                      </a>
                      <div className={Analytics.tech_cont}>
                        <div className={Analytics.analysis_left_part}>
                          {ta && renderHtml(ta.short_text)}
                          <div className={Analytics.author_regarding}>
                            <div className="img_author">
                              <img src={ta && ta.picture} alt="author" />
                            </div>
                            <div className="block_author">
                              <div>{ta && ta.author}</div>
                            </div>
                          </div>
                        </div>
                        <div className="tech_table">
                          {ta && renderHtml2(ta.table)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {this.props.techAnalys && this.props.techAnalys.length !== 0 ? (
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={5}
                  totalItemsCount={
                    this.props.techAnalys &&
                    this.props.techAnalys.count[0].count
                  }
                  pageRangeDisplayed={5}
                  onChange={e => this.handlePageChange(e)}
                />
              ) : (
                /*<a  className={Analytics.next_analysis}
                                     onClick={*/
                // ()=>{
                //     this.props.getTechAnalysList(this.props.language, 10, this.state.countPerPage+1, this.state.dateInterval.value, this.state.instrGroup.value, this.state.instrName.value);
                //     this.setState({countPerPage: this.state.countPerPage+1})
                // }
                // }
                //     >
                //     Show next 10 analysis
                // </a>
                <h1>NO DATA</h1>
              )}

              <p className={Global.btm_txt}>
                {/*Technical analysis is a useful methodology of predicting market*/}
                {/*direction and making buy/sell decisions using market data. The*/}
                {/*key points of technical analysis are: Forex Trend, Retracement*/}
                {/*Levels, Channels, Support and Resistance Levels. The wide range*/}
                {/*of Technical Indicators and Forex Oscillators are the main tools*/}
                {/*of making technical analysis.***/}
                {t("TECHNICAL_ANALYSIS_MAIN_TOOL")}
              </p>
            </div>
          </div>
          <div className={Global.right_box_block}>
            <div className={Global.expel_class}>
              <TopBrokers />
            </div>
            <div className={Global.expel_class}>
              <TopPromotionsBlock />
            </div>
          </div>
        </div>
        ;
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    techAnalys: store.techAnalys,
    language: store.language,
    selectInstruments: store.selectInstruments
  };
};

function mapDisspatchToProps(dispatch) {
  return {
    getTechAnalysList: (
      lang,
      countPerPage,
      page,
      dateInterval,
      instrGroup,
      instrName
    ) =>
      dispatch(
        getTechAnalysList(
          lang,
          countPerPage,
          page,
          dateInterval,
          instrGroup,
          instrName
        )
      ),
    getInstrumentsList: group => dispatch(attemptSelectInstrumentsList(group))
  };
}

export default connect(
  mapStateToProps,
  mapDisspatchToProps
)(translate("common")(CurrencyTechnicalAnalysis));
