import React from "react";
import "../../assets/css/components/brokers/broker_page.css";
import "../../assets/css/components/brokers/brokers_review.css";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import {
  attemptGetBrokerList,
  attemptGetBrokerPage,
  attemptGetLatestReviews,
  onClearBrokerInfo
} from "../../redux/actions/indexActions";
import TopBrokers from "../TopBrokers";
import ReactStars from "react-stars";
import RegisterForm from "./BrokerReview";
import TopPromotionsBlock from "../../containers/TopPromotionsBlock";
import LatestBrokerReview from "./LatestBrokerReview";
import Pagination from "react-js-pagination";
import { Helmet } from "react-helmet";

const renderHtml = props => (
  <span dangerouslySetInnerHTML={{ __html: props }} />
);

class BrokerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: false,
      activeIndex: 0,
      showOptions: false,
      arrow: "",
      showInstruments: false,
      showHeadquarters: false,
      arrowInstruments: "",
      arrowHeadquarters: "",
      render: true,
      activePage: 15,
      page: 1
    };
  }

  componentDidMount() {
    this.props.attemptGetBrokerPage(
      this.props.language,
      this.props.match.params.sysname
    );
    this.props.attemptGetBrokerList(this.props.language, 5, "average_rate");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.match.params.sysname !== prevProps.match.params.sysname ||
      this.props.language !== prevProps.language
    ) {
      this.props.onClearBrokerInfo();
      this.props.attemptGetBrokerPage(
        this.props.language,
        this.props.match.params.sysname
      );
      this.props.attemptGetLatestReviews(
        this.props.sysname,
        5,
        this.state.page
      );
    }
  }

  componentWillUnmount() {
    this.props.onClearBrokerInfo();
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  render() {
    const { brokerPage, t } = this.props;
    console.log(brokerPage, "BROKERPAGE");

    return (
      <div>
        <Helmet>
          <title>{`${
            brokerPage ? brokerPage.sysname : ""
          } Broker Review | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div>
          {this.props.brokerPage ? (
            <div>
              <div className="container row">
                <div className="main_with_left forex_company">
                  <h2>
                    <strong>
                      {/*Forex Broker***/}
                      {t("FOREX_BROKER")} /
                    </strong>
                    <span>{` ${brokerPage.sysname}`}</span>
                  </h2>
                  <div className="bg_wrap">
                    <div className="rating_header">
                      <div>
                        <img
                          className="broker_logo"
                          src={brokerPage.logo}
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="st_left">
                          <ReactStars
                            count={5}
                            // value={3}
                            value={brokerPage.averageRate}
                            size={30}
                            edit={false}
                            half={true}
                            color1={"#cfcfcf"}
                            color2={"#ffcc36"}
                            // rating={3}
                            rating={brokerPage.averageRate}
                          />
                          <span className="rating_stars">
                            <span>{brokerPage.averageRate}</span> / 5
                          </span>
                        </div>
                        {/*<div className="reviews_block">{`${brokerPage.reviewCount} qualified reviews**`}</div>*/}
                        <div className="reviews_block">
                          {renderHtml(
                            t("QUALIFIED_REVIEWS", {
                              REV: brokerPage.reviewCount
                            })
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="broker_txt_block">
                      <span>{brokerPage.description}</span>
                    </div>
                    <div className="rating_wrap">
                      <div className="rating_info">
                        <div>
                          <span>
                            {/*Company*/}
                            {t("COMPANY")}
                          </span>
                          <span>{brokerPage.sysname}</span>
                        </div>
                        <div>
                          <span>
                            {/*Founded in***/}
                            {t("FOUNDED")}
                          </span>
                          <span>{brokerPage.founded}</span>
                        </div>
                        <div>
                          <span>
                            {/*Headquarters***/}
                            {t("HEADQUARTER")}
                          </span>
                          <span
                            onClick={() => {
                              if (this.state.showHeadquarters) {
                                this.setState({
                                  showHeadquarters: false,
                                  arrowHeadquarters: ""
                                });
                              } else
                                this.setState({
                                  showHeadquarters: true,
                                  arrowHeadquarters: "u_turn"
                                });
                            }}
                          >
                            {/*<span className="extra_block">Show Options</span>***/}
                            {renderHtml(t("SHOW_OPTIONS"))}
                            <span
                              className={`arrow_to_show ${this.state.arrowHeadquarters}`}
                            />
                          </span>
                        </div>
                        <span
                          className="show_options"
                          style={{
                            display: this.state.showHeadquarters ? "" : "none"
                          }}
                        >
                          <span className="show_options_cont">
                            {brokerPage.headquarters.map((item, index) => {
                              return <span key={index}>{item.label}</span>;
                            })}
                          </span>
                        </span>

                        <div>
                          <span>
                            {/*Payment Options***/}
                            {t("PAYMENT_OPTIONS")}
                          </span>
                          <span
                            onClick={() => {
                              if (this.state.showOptions) {
                                this.setState({
                                  showOptions: false,
                                  arrow: ""
                                });
                              } else
                                this.setState({
                                  showOptions: true,
                                  arrow: "u_turn"
                                });
                            }}
                          >
                            {/*<span className="extra_block">Show Options</span>***/}
                            {renderHtml(t("SHOW_OPTIONS"))}
                            <span
                              className={`arrow_to_show ${this.state.arrow}`}
                            />
                          </span>
                        </div>
                        <span
                          className="show_options"
                          style={{
                            display: this.state.showOptions ? "" : "none"
                          }}
                        >
                          <span className="show_options_cont">
                            {brokerPage.paymentOptions.map((item, index) => {
                              return <span key={index}>{item.label}</span>;
                            })}
                          </span>
                        </span>
                        <div>
                          <span>
                            {/*Demo Platforms***/}
                            {t("DEMO_PLATFORMS")}
                          </span>
                          <span>
                            {brokerPage.demoPlatform
                              .toString()
                              .replace(/,/g, ",  ")}
                          </span>
                          {// console.error("(brokerPage.demoPlatform.toString())", this.props.brokerPage.demoPlatform.toString()),
                          console.error(
                            this.props.brokerPage.demoPlatform
                              .toString()
                              .replace(/,/g, ",  ")
                          )}
                        </div>
                        <div>
                          <span>
                            {/*Account Currencies***/}
                            {t("ACCOUNT_CURRENCIES")}
                          </span>
                          <span
                            className="text-overflow-60"
                            title={brokerPage.currencies
                              .toString()
                              .replace(/,/g, ",  ")}
                          >
                            {brokerPage.currencies
                              .toString()
                              .replace(/,/g, ",  ")}
                          </span>
                        </div>
                        <div>
                          <span>
                            {/*Maximum Leverage***/}
                            {t("MAXIMUM_LEVERAGE")}
                          </span>
                          <span>{brokerPage.maxLeverage}</span>
                        </div>
                        <div>
                          <span>
                            {/*Currency Pairs***/}
                            {t("CURRENCY_PAIRS_NAME")}
                          </span>
                          <span>{brokerPage.currencyPairsAmount}</span>
                        </div>
                        <div>
                          <span>
                            {/*Regulated by***/}
                            {t("REGULATED_BY")}
                          </span>
                          <span className="text-wrap">
                            {brokerPage.regulatedBy
                              .toString()
                              .replace(/,/g, ",  ")}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div className="offers">
                          <ul className="ul-tick">
                            <li
                              className={`there_is_${brokerPage.advantages[0].bonusProgram}`}
                            >
                              {/*Bonus offers***/}
                              {t("BONUS_OFFERS")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[1].tradingContests}`}
                            >
                              {/*Trading contests***/}
                              {t("TRADING_CONTESTS")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[2].freeEducation}`}
                            >
                              {/*Free education***/}
                              {t("FREE_EDUCATION")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[3].personalManager}`}
                            >
                              {/*Personal manager***/}
                              {t("PERSONAL_MANAGER")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[4].islamicAccountsAvailable}`}
                            >
                              {/*Islamic accounts available***/}
                              {t("ISLAMIC_ACCOUNTS_AVAILABLE")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[5].tradingByPhone}`}
                            >
                              {/*Trading by telephone***/}
                              {t("TELEPHONE_TRADING")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[6].affiliateProgram}`}
                            >
                              {/*Affiliate program***/}
                              {t("AFFILIATE_PROGRAM")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[7].freeAnalytics}`}
                            >
                              {/*Free analytics***/}
                              {t("FREE_ANALYTICS")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[8].wideChoiceInstruments}`}
                            >
                              {/*Wide Choice Instruments***/}
                              {t("WIDE_CHOICE")}
                            </li>
                            <li
                              className={`there_is_${brokerPage.advantages[9].freeMarginInterest}`}
                            >
                              {/*Free Margin Interest***/}
                              {t("FREE_MARGIN")}
                            </li>
                          </ul>
                        </div>
                        <a
                          className="btn btn_md button_green"
                          href={brokerPage.externalUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("START_TRADING_1")}
                        </a>
                      </div>
                    </div>

                    {brokerPage &&
                    brokerPage.accountTypes &&
                    brokerPage.accountTypes.length ? (
                      <div className="acc_type_info">
                        <div className="rating_info">
                          <h2>
                            {/*Account Types*/}
                            {t("TYPES_OF_ACCOUNTS")}
                          </h2>
                          {brokerPage.accountTypes.map((item, activeIndex) => {
                            return (
                              <span
                                key={activeIndex}
                                className={`${
                                  this.state.activeIndex === activeIndex
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.setState({ activeIndex })}
                              >
                                {`${item.accountType} (${item.tradingPlatform})`}
                              </span>
                            );
                          })}

                          <div>
                            <span>
                              {/*Minimum account size***/}
                              {t("MIN_ACCOUNT_SIZE")}
                            </span>
                            {brokerPage &&
                            brokerPage.accountTypes &&
                            brokerPage.accountTypes.length ? (
                              <span>
                                {" "}
                                {`${brokerPage &&
                                  brokerPage.accountTypes &&
                                  brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].minAccountSize}` || "---"}
                              </span>
                            ) : (
                              <span>"---"</span>
                            )}
                          </div>
                          <div>
                            <span>
                              {/*Minimum fixed spread***/}
                              {t("MIN_FIXED_SPREAD")}
                            </span>
                            <span>
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .minFixedSpread
                                ? brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].minFixedSpread ||
                                  `${
                                    brokerPage.accountTypes[
                                      this.state.activeIndex
                                    ].minFixedSpread
                                  }`
                                : ""}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Minimum position size***/}
                              {t("MIN_POSITION_SIZE")}
                            </span>
                            <span>
                              {" "}
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .minPositionSize ||
                                `${
                                  brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].minPositionSize
                                }` ||
                                "---"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Spread type***/}
                              {t("SPREAD_TYPE")}
                            </span>
                            <span>
                              {" "}
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .spreadType ||
                                `${
                                  brokerPage.accountTypes[
                                    this.state.activeIndex
                                  ].spreadType
                                }` ||
                                "---"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Scalping***/}
                              {t("SCALPING")}
                            </span>
                            <span>
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .isScalping
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Expert advisors***/}
                              {t("EXPERT_ADVISORS")}
                            </span>
                            <span>
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .isExpertAdvisors
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                          <div>
                            <span>
                              {/*Trading Instruments***/}
                              {t("TRADIN_INSTRUMENTS")}
                            </span>
                            <span
                              onClick={() => {
                                if (this.state.showInstruments) {
                                  this.setState({
                                    showInstruments: false,
                                    arrowInstruments: ""
                                  });
                                } else
                                  this.setState({
                                    showInstruments: true,
                                    arrowInstruments: "u_turn"
                                  });
                              }}
                            >
                              {/*<span className="extra_block">Show Options**</span>*/}
                              {renderHtml(t("SHOW_OPTIONS"))}
                              <span
                                className={`arrow_to_show ${this.state.arrowInstruments}`}
                              />
                            </span>
                          </div>
                          <span
                            className="show_options"
                            style={{
                              display: this.state.showInstruments ? "" : "none"
                            }}
                          >
                            <span className="show_options_cont">
                              {brokerPage.accountTypes[this.state.activeIndex]
                                .tradingInstrument &&
                                brokerPage.accountTypes[
                                  this.state.activeIndex
                                ].tradingInstrument.map((item, index) => {
                                  return <span key={index}>{item}</span>;
                                })}
                              {/*<span> {brokerPage.accountTypes[this.state.activeIndex].tradingInstrument}</span>*/}
                            </span>
                          </span>
                          <div>
                            <span>
                              {/*Trading platforms***/}
                              {t("TRADING_PLATFORMS")}
                            </span>
                            <span>
                              {" "}
                              {
                                brokerPage.accountTypes[this.state.activeIndex]
                                  .tradingPlatform
                              }
                            </span>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    ) : (
                      <div className="acc_type_info">
                        <div className="rating_info">
                          <h2>
                            {/*No Account Types***/}
                            {t("NO_ACCOUNT_TYPES")}
                          </h2>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/*<LatestReviews />*/}
                <div className="right_box_block">
                  <div className="expel_class">
                    <TopBrokers />
                  </div>
                  {/*<BrokrReviwe/>*/}
                  <div className="expel_class">
                    <TopPromotionsBlock />
                  </div>
                </div>
              </div>
              <div className="container row">
                <div className="main_with_left comments">
                  <h2>
                    {/*COMMENTS*/}
                    {t("COMMENTS").toUpperCase()}
                  </h2>
                  <div className="bg_wrap fb">
                    <LatestBrokerReview
                      sysname={this.props.match.params.sysname}
                      countPerPage={5}
                      handlePageChange={e => this.handlePageChange(e)}
                      activePage={this.state.activePage}
                    />
                    {/*<Pagination*/}
                    {/*activePage={this.state.activePage}*/}
                    {/*itemsCountPerPage={10}*/}
                    {/*totalItemsCount={450}*/}
                    {/*pageRangeDisplayed={5}*/}
                    {/*onChange={(e)=>this.handlePageChange(e)}*/}
                    {/*/>*/}
                  </div>
                  {this.props.latestReviews &&
                  this.props.latestReviews.count ? (
                    this.props.latestReviews.count > 5 ? (
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={
                          this.props.latestReviews &&
                          this.props.latestReviews.count
                        }
                        pageRangeDisplayed={5}
                        onChange={e => this.handlePageChange(e)}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="right_box_block">
                  <RegisterForm
                    sysname={this.props.match.params.sysname}
                    countPerPage={5}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <h1 className="loader">
                {/*loading***/}
                {t("LOADING")}
              </h1>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    // broker: store.brokers[props.match.params.sysname],
    language: store.language,
    brokerPage: store.brokerPage,
    latestReviews: store.latestReviews
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetBrokerPage: (lang, sysname) =>
      dispatch(attemptGetBrokerPage(lang, sysname)),
    attemptGetBrokerList: (lang, limit, orderby) =>
      dispatch(attemptGetBrokerList(lang, limit, orderby)),
    attemptGetLatestReviews: (sysname, countPerPage, page) =>
      dispatch(attemptGetLatestReviews(sysname, countPerPage, page)),
    onClearBrokerInfo: () => dispatch(onClearBrokerInfo())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(BrokerPage)
);
