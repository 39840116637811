import React from "react";
import OutsideAlerter from "./OutsideAlerter";
import "./Rss.css";
import {
  getArticleList,
  setStockExchangeArray
} from "../../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";

class Rss extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      show: false,

      TSE: false,
      NASDAQ: false,
      NYSE: false,
      ASX: false,
      LSE_IOB: false,
      LSE: false,
      HKEx: false,
      TSX: false,
      Xetra: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    // this.props.setStockExchangeArray(this.props.stockExchangeData)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // alert("You clicked outside of me!");
    }
  }
  render() {
    console.log(this.props.stockExchangeArray, "stockExchangeArray");
    const {
      selectAll,
      show,
      TSE,
      NASDAQ,
      NYSE,
      ASX,
      LSE_IOB,
      LSE,
      HKEx,
      TSX,
      Xetra
    } = this.state;
    const totalCount =
      TSE + NASDAQ + NYSE + ASX + LSE_IOB + LSE + HKEx + TSX + Xetra;

    return (
      <div
        style={{ width: "200px", marginBottom: "20px", marginRight: "30px" }}
      >
        <OutsideAlerter handledShow={() => this.setState({ show: false })}>
          <div className="first" onClick={() => this.setState({ show: !show })}>
            <div
              className={`diiiv ${totalCount === 9 ? "active" : ""}`}
              onClick={() => {
                this.setState({ selectAll: !selectAll, show: true }, () => {
                  selectAll
                    ? this.setState(
                        {
                          TSE: false,
                          NASDAQ: false,
                          NYSE: false,
                          ASX: false,
                          LSE_IOB: false,
                          LSE: false,
                          HKEx: false,
                          TSX: false,
                          Xetra: false
                        },
                        () => {
                          const stockExchangeData = [];
                          this.props.setStockExchangeArray(stockExchangeData);
                        }
                      )
                    : this.setState(
                        {
                          TSE: true,
                          NASDAQ: true,
                          NYSE: true,
                          ASX: true,
                          LSE_IOB: true,
                          LSE: true,
                          HKEx: true,
                          TSX: true,
                          Xetra: true
                        },
                        () => {
                          const stockExchangeData = [
                            "31",
                            "30",
                            "108",
                            "54",
                            "88",
                            "93",
                            "86",
                            "232",
                            "87"
                          ];
                          this.props.setStockExchangeArray(stockExchangeData);
                        }
                      );
                });
              }}
            />
            <div className="text">{`Stock exchange(${TSE +
              NASDAQ +
              NYSE +
              ASX +
              LSE_IOB +
              LSE +
              HKEx +
              TSX +
              Xetra})`}</div>
          </div>
          {show && (
            <div
              className="second"
              onClick={() => this.setState({ show: true })}
            >
              <div className="secondItem">
                <div
                  className={`diiiv ${NASDAQ ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ NASDAQ: !NASDAQ }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        !NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        LSE ? "88" : "",
                        HKEx ? "86" : "",
                        TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">NASDAQ</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${NYSE ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ NYSE: !NYSE }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        !NYSE ? "30" : "",
                        ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        LSE ? "88" : "",
                        HKEx ? "86" : "",
                        TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">NYSE</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${ASX ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ ASX: !ASX }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        !ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        LSE ? "88" : "",
                        HKEx ? "86" : "",
                        TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">ASX</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${LSE_IOB ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ LSE_IOB: !LSE_IOB }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        ASX ? "108" : "",
                        !LSE_IOB ? "54" : "",
                        LSE ? "88" : "",
                        HKEx ? "86" : "",
                        TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">LSE(IOB)</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${LSE ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ LSE: !LSE }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        !LSE ? "88" : "",
                        HKEx ? "86" : "",
                        TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">LSE</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${TSE ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ TSE: !TSE }, () => {
                      const stockExchangeData = [
                        !TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        LSE ? "88" : "",
                        HKEx ? "86" : "",
                        TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">TSE</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${HKEx ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ HKEx: !HKEx }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        LSE ? "88" : "",
                        !HKEx ? "86" : "",
                        TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">HKEx</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${TSX ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ TSX: !TSX }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        LSE ? "88" : "",
                        HKEx ? "86" : "",
                        !TSX ? "232" : "",
                        Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">TSX</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Xetra ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Xetra: !Xetra }, () => {
                      const stockExchangeData = [
                        TSE ? "93" : "",
                        NASDAQ ? "31" : "",
                        NYSE ? "30" : "",
                        ASX ? "108" : "",
                        LSE_IOB ? "54" : "",
                        LSE ? 33 : "",
                        HKEx ? "86" : "",
                        TSX ? "232" : "",
                        !Xetra ? "87" : ""
                      ];
                      this.props.setStockExchangeArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Xetra</div>
              </div>
            </div>
          )}
        </OutsideAlerter>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    stockExchangeArray: store.stockExchangeArray
  };
};
function mapDispatchToProps(dispatch) {
  return {
    setStockExchangeArray: componentState =>
      dispatch(setStockExchangeArray(componentState))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(Rss)
);
