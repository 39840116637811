import React from "react";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import { connect } from "react-redux";
import {
  closeQuoteSocket,
  getSymbolQuotData,
  getSymbolQuotDataClose
} from "../../redux/actions/indexActions";
import ForexGradSocket from "../../api/WebSocket";
import { withRouter } from "react-router-dom";

class RightBoxBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldCurName: ""
    };
  }

  // getSymbolQuotDataClose(){
  //     const Socket = new ForexGradSocket(
  //         () => (Socket.close()),
  //     )
  // }

  componentDidMount() {
    // console.error("this.props.instrName.value", this.props.currency);
    this.props.getSymbolQuotData(this.props.currency.value);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currency.value !== prevProps.currency.value) {
      this.props.getSymbolQuotDataClose();
      // console.error("componentDidUpdateIF", this.props.newQuoteData)
      this.props.getSymbolQuotData(this.props.currency.value);
    }

    if (
      !this.props.currency.value &&
      this.state.oldCurName !== this.props.currency.value
    ) {
    }
  }

  getByValue2(arr, value, findValue) {
    return (
      arr.find(obj => obj[findValue] === value) || {
        name: `${
          this.props.match.params.cur
            ? this.props.match.params.cur
            : this.props.instrName.value
            ? this.props.instrName.value
            : "AUDUSD"
        }`
      }
    );
  }

  render() {
    const { instrName, newQuoteData } = this.props;
    return (
      <div className="percent_cont">
        <div className="percent_title">
          {`${
            this.props.match.params.cur
              ? `${
                  this.getByValue2(
                    this.props.chartInstrument,
                    this.props.match.params.cur,
                    "slug"
                  ).name
                }`
              : instrName.value
              ? instrName.value
              : "AUDUSD"
          }`}
        </div>
        <div className="percent_block">
          <div className="current_instrument_bid">
            {newQuoteData && newQuoteData.ask ? newQuoteData.ask : "---"}
            {/*{this.props.newQuoteData.ask}*/}
          </div>
          <div
            className={`change_with_percent_${newQuoteData &&
              newQuoteData.state}`}
          >
            {newQuoteData
              ? `${(
                  `${newQuoteData && newQuoteData.high}` -
                  `${newQuoteData && newQuoteData.low}`
                ).toFixed(4)} (${newQuoteData && newQuoteData.percent}%)`
              : "---"}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    newQuoteData: store.newQuoteData,
    instrName: store.instrName,
    chartInstrument: store.chartInstrument
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getSymbolQuotData: newCurrency => dispatch(getSymbolQuotData(newCurrency)),
    getSymbolQuotDataClose: () => dispatch(closeQuoteSocket())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RightBoxBlock));
