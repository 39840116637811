import React from "react";
import EconomicCalendar from "../../containers/EconomicCalendar";
import GainersLosers from "../../containers/GainersLosers";

export default class News extends React.Component {
  render() {
    return (
      <div className={"container main_half_cont row"}>
        <EconomicCalendar width={"widthcalendar"} heigth={"heigth"} />
        <GainersLosers />
      </div>
    );
  }
}
