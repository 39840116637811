import React from "react";
import "./../assets/css/components/about_us/about_us.css";
import Global from "./../assets/css/Global.module.css";
import AllVideos from "../containers/videoOvervie/Videos";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ExpertCorner from "../containers/articles/ExpertCorner";

const renderHtml = props => (
  <div
    className="main_with_left about_us_wrap"
    dangerouslySetInnerHTML={{ __html: props }}
  ></div>
);

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staticPageData: ""
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      fetch(
        `https://www.ifcm.co.uk:2083/page/about-us?lang=${this.props.language}`,
        {
          method: "get",
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
      )
        .then(res => res.json())
        .then(data => {
          this.setState({ staticPageData: data });
        })
        .catch(err => console.log(err));
    }
  }
  componentDidMount() {
    fetch(
      `https://www.ifcm.co.uk:2083/page/about-us?lang=${this.props.language}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ staticPageData: data });
      })
      .catch(err => console.log(err));
    if (this.props.marketOverview) {
      this.setState({ marketOverview: this.props.marketOverview });
    }
  }

  render() {
    // console.log("about us",this.state.staticPageData.content)
    return (
      <div>
        <Helmet>
          <title>{`About ForexGrad | ForexGrad`}</title> todo
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="container row">
          {/*{renderHtml(this.state.staticPageData.content)}*/}
          <div className="main_with_left about_us_wrap">
            <h1>About us</h1>
            <div className="txt_wrap about_us_cont">
              <div className="about_us_block">
                <div>
                  Welcome to Forexgrad - an independent and objective Forex
                  broker rating platform. Our website is designed to provide
                  beginners and professional traders worldwide with a platform
                  where they can find reliable and useful information on various
                  online brokers, compare it and chose the best brokerage
                  companies that offer the most favorable trading conditions and
                  services in the Forex market.
                </div>
              </div>
              <div className="about_us_block">
                <div>
                  Forex industry develops day by day, lots of new brokerage
                  firms offer their cooperation to investors and traders around
                  the world, but it’s not that easy to find a trustworthy,
                  steadily developing and honest Forex broker which will meet
                  your demands. In order to get more clients, some brokers would
                  take unacceptable measures such as leaving unflattering
                  feedback about other companies or self-praising reviews on
                  different platforms that are not strictly moderated. Our team
                  aims to help you avoid scammers and facilitate your search for
                  the best broker which will satisfy your requests.
                </div>
              </div>
              <div className="about_us_block">
                {/*<h3>Forex News and Analytics</h3>*/}
                <div>
                  We strive to give the most objective and honest rating of
                  brokers on our website by taking into account the conditions
                  provided by the top Forex companies and our users’ opinions
                  and reviews as well.
                </div>
                <div>
                  Below you can see which criteria are most important to us when
                  rating brokerage firms presented in the “Brokers” section:
                </div>
              </div>
              <div className="about_us_block">
                {/*<h3>Educational materials</h3>*/}
                <div>
                  {/*Our website provides the best training materials that will help to improve trading skills*/}
                  {/*and achieve success in the FX market. The step by step guide will help beginners to get all*/}
                  {/*the necessary knowledge in the shortest time frame. Professional traders will find a lot of*/}
                  {/*useful information as well.*/}
                  <div>
                    {/*Additionally, we offer the following useful tools:*/}
                    <ul className="ul-tick">
                      <li>Languages supported</li>
                      <li>Withdrawal / Deposit methods</li>
                      <li>Number of currencies that accounts can be open in</li>
                      <li>
                        Trading conditions (spreads, minimal deposit,
                        commissions, maximum leverage size etc.)
                      </li>
                      <li>Opportunity to open an Islamic account</li>
                      <li>Free Analytics</li>
                      <li>Trading platforms</li>
                      <li>Availability of contests</li>
                      <li>Free educational materials</li>
                      <li>Bonuses</li>
                      <li>Personal manager</li>
                      <li>Range of trading instruments offered</li>
                      <li>Free margin interest</li>
                      <li>Affiliate Program</li>
                      <li>Opportunity to trade by Phone/Tablet</li>
                      <li>Stars and reviews by our guests</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="about_us_block">
                {/*<h3>Forex Brokers Reviews</h3>*/}
                <div>
                  Besides the main goal to provide you with an objective and
                  professional view on brokers, we bring to your attention daily
                  analytics, news, articles on various trade topics, charts and
                  economic calendars, expert opinions and opportunity to
                  download the most popular and trustworthy trading terminals.
                </div>
              </div>
              <div className="about_us_block">
                {/*<h3>Forex Brokers Reviews</h3>*/}
                <div>
                  You can contribute to the development of our website and help
                  other traders make a balanced and right choice by sharing your
                  experience of cooperation with a particular Forex broker and
                  leaving a review on our website. Each review is moderated in
                  order to check the authenticity. We guarantee that on
                  Forexgrad you will not see any fake reviews or assessments.
                </div>
              </div>
              <div className="about_us_block">
                {/*<h3>Forex Brokers Reviews</h3>*/}
                <div>
                  If you want to know in details about the work we do and the
                  services we offer, as well as suggest something, contact us
                  via email:
                </div>
              </div>
              <div className="about_us_block">
                {/*<h3>Forex Brokers Reviews</h3>*/}
                <div>Our team wishes you a successful trading!</div>
              </div>
            </div>
          </div>

          <div className="right_box_block">
            <div className="expel_class">
              <AllVideos />
            </div>
            <div className={Global.expel_class}>
              <ExpertCorner />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language
  };
};

export default translate("common")(connect(mapStateToProps)(AboutUs));
