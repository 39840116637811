import React from "react";
import "../assets/css/components/footer.css";
import { translate } from "react-i18next";
import MenuNavFooter from "./header/MenuNavFooter";
class Footer extends React.Component {
  render() {
    const date = new Date().getFullYear();
    const { t } = this.props;
    return (
      <div className="footer">
        <MenuNavFooter />
        <div className="f_text">
          <div className="container">{t("RISK_DISCLAIMER")}</div>
        </div>
        <div>
          <div className="container">
            <p>© {date} ForexGrad. All Rights Reserved</p>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("common")(Footer);
