import React from "react";
import "./../assets/css/components/trading_platforms.css";
import "./../assets/css/components/video_lessons.css";
import TopBrokers from "./../containers/TopBrokers";
import Videos from "./../containers/videoOvervie/Videos";
import { getMarketOverviewList } from "./../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { logger } from "redux-logger/src";
// import EducationSections from "./MarketOverviewDetail";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

const DATA = {
  "ezinearticles-Lance-Winslow": "Lance Winslow",
  "ezinearticles-Ahsan-Khan": "Ahsan Khan",
  "ezinearticles-Norma-Holt": "Norma Holt",
  "ezinearticles-John-Dugan": "John Dugan",
  "ezinearticles-Victor-Epand": "Victor Epand"
};

class MarketOverViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rssItems: null,
      expert:
        DATA[this.props.history.location.pathname.split("/")[2]] ||
        "Ahsan Khan",
      slug:
        this.props.history.location.pathname.split("/")[2] ||
        "ezinearticles-Ahsan-Khan"
    };
  }

  getDate = arg => {
    const date = new Date(arg);
    let month = null;
    if (date.getMonth() + 1 < 10) {
      month = "0" + (date.getMonth() + 1);
    } else month = date.getMonth() + 1;
    let day = null;
    if (date.getDate() + 1 < 10) {
      day = "0" + (date.getDate() + 1);
    } else day = date.getDate() + 1;

    return date.getFullYear() + "-" + month + "-" + day;
  };

  handleChangeExpert(expert, slug) {
    this.setState({ expert, slug });
  }

  attemptGetData() {
    fetch(
      `https://www.ifcm.co.uk:2083/rss/${this.state.slug}?lang=${this.props.language}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data });
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.attemptGetData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.language !== prevProps.language ||
      this.state.expert !== prevState.expert
    ) {
      this.attemptGetData();
    }
  }

  render() {
    console.log(
      this.props.history.location.pathname.split("/")[2],
      "pppppppppppppppppppppppppppppppp"
    );

    const { t } = this.props;
    const { rssItems, expert, slug } = this.state;
    console.log(this.state, "ppppppppppppppppppppppppppp");
    return (
      <div>
        <Helmet>
          <title>{`Market Overview | Daily Forex News | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="row">
          <div className="container row">
            <div className="video_less_wrap main_with_left see_also">
              <h1>Experts</h1>
              <div>
                <button
                  className={`btn btn_sm button_grayish_blue padding ml-1 ${
                    expert === "Ahsan Khan" ? "btnActive" : ""
                  }`}
                  onClick={() => {
                    this.handleChangeExpert(
                      "Ahsan Khan",
                      "ezinearticles-Ahsan-Khan"
                    );
                    this.props.history.push(
                      "/experts/ezinearticles-Ahsan-Khan"
                    );
                  }}
                >
                  Ahsan Khan
                </button>
                <button
                  className={`btn btn_sm button_grayish_blue padding ml-1 ${
                    expert === "Lance Winslow" ? "btnActive" : ""
                  }`}
                  onClick={() => {
                    this.handleChangeExpert(
                      "Lance Winslow",
                      "ezinearticles-Lance-Winslow"
                    );
                    this.props.history.push(
                      "/experts/ezinearticles-Lance-Winslow"
                    );
                  }}
                >
                  Lance Winslow
                </button>
                <button
                  className={`btn btn_sm button_grayish_blue padding ml-1 ${
                    expert === "Norma Holt" ? "btnActive" : ""
                  }`}
                  onClick={() => {
                    this.handleChangeExpert(
                      "Norma Holt",
                      "ezinearticles-Norma-Holt"
                    );
                    this.props.history.push(
                      "/experts/ezinearticles-Norma-Holt"
                    );
                  }}
                >
                  Norma Holt
                </button>
                <button
                  className={`btn btn_sm button_grayish_blue padding ml-1 ${
                    expert === "John Dugan" ? "btnActive" : ""
                  }`}
                  onClick={() => {
                    this.handleChangeExpert(
                      "John Dugan",
                      "ezinearticles-John-Dugan"
                    );
                    this.props.history.push(
                      "/experts/ezinearticles-John-Dugan"
                    );
                  }}
                >
                  John Dugan
                </button>
                <button
                  className={`btn btn_sm button_grayish_blue padding ml-1 ${
                    expert === "Victor Epand" ? "btnActive" : ""
                  }`}
                  onClick={() => {
                    this.handleChangeExpert(
                      "Victor Epand",
                      "ezinearticles-Victor-Epand"
                    );
                    this.props.history.push(
                      "/experts/ezinearticles-Victor-Epand"
                    );
                  }}
                >
                  Victor Epand
                </button>
              </div>
              <div className="bg_wrap">
                <div className="video_cont_block">
                  {/*<div className="vid_cont_block_txt no-padding">*/}
                  <div>
                    <div className="video_less_title">
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={rssItems && rssItems.link}
                        // href={item.url}
                      >
                        {/*<Link to={MARKET_OVERVIEW_DETAILS_CREATOR(item.slug)}>*/}
                        {rssItems && rssItems.title}
                        {/*</Link>*/}
                      </a>
                    </div>
                    <div className="new_cont_block">
                      {renderHtml(rssItems && rssItems.description)}
                      {/*{renderHtml(item.text)}*/}
                    </div>
                    <div className="vdl_footer_bl">
                      {/*<div className="date_block">{this.getDate(item.isoDate)}</div>*/}
                      {/*<div className="date_block">{item.date}</div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg_wrap_list bg_wrap">
                {rssItems ? (
                  rssItems.items.length > 0 ? (
                    rssItems.items.map((item, index) => {
                      return (
                        <div key={index} className="video_cont_block">
                          {/*<div className="vid_cont_block_txt no-padding">*/}
                          <div>
                            <div className="video_less_title">
                              <a
                                target={"_blank"}
                                rel="noopener noreferrer"
                                href={item.link}
                                // href={item.url}
                              >
                                {/*<Link to={MARKET_OVERVIEW_DETAILS_CREATOR(item.slug)}>*/}
                                {item.title}
                                {/*</Link>*/}
                              </a>
                            </div>
                            <div className="new_cont_block">
                              {renderHtml(item.content)}
                              {/*{renderHtml(item.text)}*/}
                            </div>
                            <div className="vdl_footer_bl">
                              <div className="date_block">
                                {this.getDate(item.isoDate)}
                              </div>
                              {/*<div className="date_block">{item.date}</div>*/}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h1 className="loader">
                      {/*loading***/}
                      {t("LOADING")}
                    </h1>
                  )
                ) : (
                  <h1 className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </h1>
                )}
              </div>
            </div>
            <div className="right_box_block">
              <div className="expel_class">
                <Videos />
              </div>
              <div className="expel_class">
                <TopBrokers />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    marketOverviewList: store.marketOverviewList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getMarketOverviewList: lang => dispatch(getMarketOverviewList(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketOverViewList))
);
