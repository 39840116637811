import React from "react";
import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import TopBrokers from "../../containers/TopBrokers";
import EducationSections from "../../containers/education/EducationSections";
import {
  getMarketOverviewDetails,
  onClearMarketOverviewDetails
} from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class MarketOverviewDetail extends React.Component {
  componentDidMount() {
    console.error(this.props);
    this.props.getMarketOverviewDetails(
      this.props.language,
      this.props.match.params.slug
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.match.params.slug !== prevProps.match.params.slug ||
      this.props.language !== prevProps.language
    ) {
      this.props.onClearMarketOverviewDetails();
      this.props.getMarketOverviewDetails(
        this.props.language,
        this.props.match.params.slug
      );
    }
  }

  render() {
    const { MarketOverviewDetails, t } = this.props;
    console.error(
      "this.props.match.params.index",
      this.props.match.params.slug
    );
    return (
      <div className="row">
        <div className="container row">
          <div className="video_less_wrap main_with_left">
            <h1>
              {/*MARKET OVERVIEW*/}
              {t("LIST_MARKET_OVERVIEW")}.toUpperCase()
            </h1>
            <div className="bg_wrap">
              {MarketOverviewDetails ? (
                <div className="video_cont_block">
                  <div className="col-12 no-padding">
                    <div className="video_less_title">
                      {MarketOverviewDetails.title}
                    </div>
                    <div>{renderHtml(MarketOverviewDetails.text)} </div>
                    <div className="vdl_footer_bl">
                      <div className="date_block">
                        {MarketOverviewDetails.date}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="loader">
                  {/*loading***/}
                  {t("LOADING")}
                </div>
              )}
            </div>
          </div>
          <div className="right_box_block">
            <div className="expel_class">
              <EducationSections />
            </div>
            <div className="expel_class">
              <TopBrokers />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    language: store.language,
    MarketOverviewDetails: store.MarketOverviewDetails
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getMarketOverviewDetails: (lang, slug) =>
      dispatch(getMarketOverviewDetails(lang, slug)),
    onClearMarketOverviewDetails: () => dispatch(onClearMarketOverviewDetails())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(MarketOverviewDetail)
);
