import React from "react";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import {ARTICLES_DETAIL_CREATOR, OUR_ARTICLES_DETAIL_CREATOR} from "../../configs/constants";
import {
  getArticleList,
  onClearArticleList
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";
import TopBrokers from "../TopBrokers";
import TopPromotionsBlock from "../../containers/TopPromotionsBlock";
import { Helmet } from "react-helmet";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Global from "../../assets/css/Global.module.css";

const renderHtml = props => (
  <div className="txt_link" dangerouslySetInnerHTML={{ __html: props }}></div>
);

class AllArticles extends React.Component {
  componentDidMount() {
    this.props.getArticleList(this.props.language, 5, 1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.getArticleList(this.props.language, 5, 1);
    }
  }

  componentWillUnmount() {
    this.props.onClearArticleList();
  }

  render() {
    const { article, t } = this.props;
    return (
      <div>
        <Helmet>
          <title>{`FOREX ARTICLES | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className={`${Global.container} ${Global.row}`}>
          <div
            className={`${Global.main_with_left} ${Analytics.tech_analysis}`}
          >
            <h1>Expert Corner - Trading Articles</h1>
            <div className={Global.bg_wrap}>
              {article ? (
                article.list.map((item, index) => {
                  return (
                    <div className={Analytics.tech_wrap} key={index}>
                      <Link to={OUR_ARTICLES_DETAIL_CREATOR(item.slug)}>
                        <div className={Analytics.tech_top}>
                          <h3>{item.title}</h3>
                          <div className="date_block">{`${
                              item.date.split("T")[0]
                          }`}</div>
                        </div>
                      </Link>
                      <div>
                        <Link to={OUR_ARTICLES_DETAIL_CREATOR(item.slug)}>
                          <div className={Analytics.new_cont_block}>
                            {renderHtml(item.description)}
                          </div>
                        </Link>
                        <div className={Analytics.expert_block}>
                          <div>
                            <img
                              src={item.picture}
                              alt={"ex cor."}
                              style={{ width: "50px" }}
                            />
                          </div>
                          <div className={Analytics.expert_right_block}>
                            <div>Author</div>
                            <div>
                              {`${item.author}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h1 className="loader">
                  {/*loading***/}
                  {t("LOADING")}
                </h1>
              )}

              <p className={Global.btm_txt}>
                {/*Technical analysis is a useful methodology of predicting market*/}
                {/*direction and making buy/sell decisions using market data. The*/}
                {/*key points of technical analysis are: Forex Trend, Retracement*/}
                {/*Levels, Channels, Support and Resistance Levels. The wide range*/}
                {/*of Technical Indicators and Forex Oscillators are the main tools*/}
                {/*of making technical analysis.{" "}***/}
                {t("TECHNICAL_ANALYSIS_MAIN_TOOL")}
              </p>
            </div>
          </div>
          <div className={Global.right_box_block}>
            <div className={Global.expel_class}>
              <TopBrokers />
            </div>
            <div className={Global.expel_class}>
              <TopPromotionsBlock />
            </div>
          </div>
        </div>
        ;
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language,
    article: store.article
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getArticleList: (lang, countPerPage, page) =>
      dispatch(getArticleList(lang, countPerPage, page)),
    onClearArticleList: () => dispatch(onClearArticleList())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(AllArticles)
);
