import React from "react";
import "../../assets/css/components/innovation/innovation.css";
import TopBrokers from "../../containers/TopBrokers";
import "../../assets/css/components/articles.css";
import {
  getArticleDetails,
  onClearArticleDetails
} from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import ExpertCorner from "../../containers/articles/ExpertCorner";
import { Helmet } from "react-helmet";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Global from "../../assets/css/Global.module.css";
import Pagination from "react-js-pagination";
import Videos from "../videoOvervie/Videos";
import Analysis from "../Analysis";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      newsCount: 0,
      activePage: 1,
      page: 1
    };
  }

  attemptGetData(slug, lang, countPerPage = 10, activePage) {
    fetch(
      `https://www.ifcm.co.uk:2083/articles/${slug}?lang=${lang}&countPerPage=${countPerPage}&page=${activePage}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ data }, () =>
          console.log(this.state.rssItems, "YUYUYUY")
        );
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.attemptGetData(
      this.props.match.params.slug,
      this.props.language,
      10,
      1
    );
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber }, () =>
      this.attemptGetData(
        this.props.match.params.slug,
        this.props.language,
        10,
        this.state.activePage
      )
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.attemptGetData(
        this.props.match.params.slug,
        this.props.language,
        10,
        this.state.activePage
      );
    } else if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.setState({ activePage: 1 }, () => {
        this.attemptGetData(
          this.props.match.params.slug,
          this.props.language,
          10,
          this.state.activePage
        );
      });
    }
  }

  // componentWillUnmount() {
  //   this.props.onClearArticleDetails();
  // }

  render() {
    const { articleDetails, t } = this.props;
    const { data } = this.state;
    console.log(articleDetails, "555555555");

    return (
      <div>
        <Helmet>
          <title>{`FOREX ARTICLES ${
            articleDetails ? `| ${articleDetails.title}` : ""
          } | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="row">
          <div className="container row">
            <div className="video_less_wrap main_with_left">
              <div className="bg_wrap">
                <div className="video_cont_block">
                  <div>
                    <img
                      style={{ float: "left" }}
                      src={data && data.picture}
                      alt=""
                    />
                    <h2>
                      <a href={data && data.link}>{data && data.title}</a>
                    </h2>
                    <p>{data && data.description}</p>
                  </div>
                </div>
                {data ? (
                  data.items.map((item, index) => {
                    return (
                      <div key={index} className="video_cont_block">
                        <div>
                          <div className="video_less_title">
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={item.link}
                              // href={item.url}
                            >
                              {/*<Link to={MARKET_OVERVIEW_DETAILS_CREATOR(item.slug)}>*/}
                              {item.title}
                              {/*</Link>*/}
                            </a>
                          </div>
                          <div className="new_cont_block">
                            {renderHtml(item.content)}
                            {/*{renderHtml(item.text)}*/}
                          </div>
                          <div className="vdl_footer_bl">
                            <div className="date_block">{`${
                              item.isoDate.split("T")[0]
                            } ${item.isoDate.split("T")[1].slice(0, 5)}`}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </h1>
                )}
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={5}
                  totalItemsCount={data && data.count}
                  pageRangeDisplayed={5}
                  onChange={e => this.handlePageChange(e)}
                />
              </div>
            </div>
            <div className="right_box_block">
              <div className="expel_class">
                <Videos />
              </div>
              <div className={Global.expel_class}>
                <ExpertCorner />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    language: store.language,
    articleDetails: store.articleDetails
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getArticleDetails: (slug, lang, page) =>
      dispatch(getArticleDetails(slug, lang, page)),
    onClearArticleDetails: () => dispatch(onClearArticleDetails())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(Articles)
);
