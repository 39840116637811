import React from "react";
import "../assets/css/components/eco_calendar.css";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

class AllEconomicCalendar extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className={"container"}>
        <Helmet>
          <title>{`FX Economic Calendar | Forex News Calendar | Economic Data Calendar | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>

        <div className={`main_half ${this.props.width}`}>
          <div className="eco_big_cal_h2">
            <h1>
              {/*Economic Calendar*/}
              {t("LIST_ECANOMIC_CALENDAR")}
            </h1>
          </div>
          <div className={`bg_wrap ${this.props.heigth}`}>
            <div className="swipe">
              Swipe table** <span className="icon-right-small"></span>
            </div>
            <div className="economic_calendar_filter">
              <ul>
                <li>
                  <label>
                    <span className="economic-calendar__importance none" />
                    {/*Holidays***/}
                    {t("HOLIDAYS")}
                  </label>
                </li>
                <li>
                  <label>
                    <span className="economic-calendar__importance low" />
                    {/*Low*/}
                    {t("LOW")}
                  </label>
                </li>
                <li>
                  <label>
                    <span className="economic-calendar__importance medium" />
                    {/*Medium***/}
                    {t("MEDIUM")}
                  </label>
                </li>
                <li>
                  <label>
                    <span className="economic-calendar__importance high" />
                    {/*High*/}
                    {t("HIGH")}
                  </label>
                </li>
              </ul>
            </div>

            <div id="economicCalendarWidget" />
            <div className="eco_ifrm">
              <iframe
                src={`https://www.mql5.com/${this.props.language}/economic-calendar/widget`}
                frameBorder="0"
                height="100%"
                width="100%"
                title={"mql5"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language
  };
};

export default translate("common")(
  connect(mapStateToProps)(AllEconomicCalendar)
);
