import React, { Component } from "react";

import { webs } from "./Socket";
import { withRouter } from "react-router-dom";
import { chartConfig, chartConfigCandle } from "./configs";
import moment from "moment";
import { translate } from "react-i18next";

// SELECT REPLACE(catalogue.name, 'front.general.', '') as lang, trans_unit.source, trans_unit.target from trans_unit left JOIN catalogue on catalogue.cat_id = trans_unit.cat_id where catalogue.name like 'front.general.%'
const period = 1440;
let dateNew = Math.round(Date.now() / 1000);

class ChartCFD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bullet: 2,
      period: 240
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currency.value !== prevProps.currency.value) {
      this.getChartData(true);
      console.error(this.props.currency);
    }
  }

  getChartData(renderChart, chartType, rerenderChartData) {
    const Req = {
      period: this.state.period,
      from: dateNew - this.state.period * 60 * 1000,
      to: dateNew,
      cmd: "history",
      symbol: this.props.currency.value
    };
    // "symbol": `${(this.props.match.params.length)?this.props.currency.value:this.props.match.params}`

    const chartSocket = new WebSocket("wss://ifccd.net:2053/");

    chartSocket.onopen = () => {
      chartSocket.send(JSON.stringify(Req));
      chartSocket.onmessage = ({ data }) => {
        const chartData = JSON.parse(data);

        renderChart && this.initializeChart(chartData);
        if (rerenderChartData) {
          this.chart.data = this.getChartFilteredData(chartData.list);
        }
      };
    };
  }

  componentDidMount() {
    console.log(this.props.match.params, "fefefefefef");
    this.getChartData(true);
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  getChartFilteredData(list) {
    let filteredData = null;
    switch (this.state.period) {
      case 1:
      case 5:
      case 15:
      case 30:
      case 60: {
        filteredData = list.map(quote => {
          var ctm_value = new Date(quote.ctm).getTime();
          return {
            ctm: quote.ctm,
            date: moment(quote.ctm).format("h:mm"),
            value: quote.open,
            open: quote.open,
            close: quote.close,
            high: quote.high,
            low: quote.low
          };
        });

        return this.removeDuplicates(filteredData, "date");
      }

      case 240:
      case 1440:
      case 10080: {
        filteredData = list.map(quote => ({
          date: moment(quote.ctm).format("DD.MM.YY"),
          value: `${quote.open}`,
          open: `${quote.open}`,
          close: `${quote.close}`,
          high: `${quote.high}`,
          low: `${quote.low}`
        }));
        return this.removeDuplicates(filteredData, "date");
      }
    }
  }

  initializeChart = chartData => {
    this.chart = chartConfig();
    this.chart.data = this.getChartFilteredData(chartData.list);
    this.chart.yAxes.values[0].axisRanges.values[0].value = this.chart.data[
      this.chart.data.length - 1
    ];
  };

  changeChartType() {
    this.chart.xAxes.values[0].zoom({ start: 0.96, end: 1 });
  }

  render() {
    const { t } = this.props;
    const intervalData = [
      {
        interval: `${t("CHARTS_STEP_1MIN")}`,
        period: 1
      },
      {
        // "5m"
        interval: `${t("CHARTS_STEP0")}`,
        period: 5
      },
      {
        // "15m"
        interval: `${t("CHARTS_STEP1")}`,
        period: 15
      },
      {
        // "30m"
        interval: `${t("CHARTS_STEP2")}`,
        period: 30
      },
      {
        // "1h"
        interval: `${t("CHARTS_STEP3")}`,
        period: 60
      },
      {
        // "4h"
        interval: `${t("CHARTS_STEP4")}`,
        period: 240
      },
      {
        interval: `${t("CHARTS_STEP5")}`,
        period: 1440
      },
      {
        interval: `${t("CHARTS_STEP6")}`,
        period: 10080
      }
    ];
    return (
      <div className="Apper">
        {/*<button onClick={() => this.changeChartType("LineSeries")}>*/}
        {/*/!*<div className={`LineChart ${this.state.LineSeries}`}> </div>*!/*/}
        {/*</button>*/}
        {/*<button onClick={() => this.changeTypes("CandlestickSeries")} >*/}
        {/*/!*<div className={`CalumChart ${this.state.CalumChart}`}> </div>*!/*/}
        {/*</button>*/}
        {/*<button onClick={() => this.changeTypes("OHLCSeries")}>*/}
        {/*/!*<div className={`OHLCSeries ${this.state.OHLCSeries}`}> </div>*!/*/}
        {/*</button>*/}
        <div className="intervalButton">
          {intervalData.map((item, index) => {
            return (
              <button
                key={index}
                className={
                  item.period === this.state.period ? "chart_active_period" : ""
                }
                onClick={() => {
                  this.setState({ period: item.period }, () =>
                    this.getChartData(false, null, true)
                  );
                }}
              >
                {item.interval}
              </button>
            );
          })}
        </div>

        <div id="chartdiv" style={{ width: "100%", height: "450px" }}></div>
      </div>
    );
  }
}

export default withRouter(translate("common")(ChartCFD));
