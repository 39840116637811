import React from "react";
import "./../assets/css/components/trading_platforms.css";
import {
  attemptGetPlatformDetails,
  onClearPlatformDetails
} from "../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { osName } from "react-device-detect";
import { Link } from "react-router-dom";
import { PLATFORM_DETAILS_PAGE_CREATOR } from "../configs/constants";
import TopBrokers from "./TopBrokers";
import TopPromotionsBlock from "./TopPromotionsBlock";
import { Helmet } from "react-helmet";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class TradingPlatformDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  componentDidMount() {
    console.error("P_P_P_P_P_P", this.props);
    this.props.attemptGetPlatformDetails(
      this.props.language,
      this.props.match.params.sysname
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.match.params.sysname !== prevProps.match.params.sysname ||
      this.props.language !== prevProps.language
    ) {
      this.props.onClearPlatformDetails();
      this.props.attemptGetPlatformDetails(
        this.props.language,
        this.props.match.params.sysname
      );
    }
  }

  render() {
    const { platformDetails, t } = this.props;
    console.error("platformDetails", platformDetails);
    const platformDetails_arr = [platformDetails];

    return (
      <div>
        <Helmet>
          <title>{`Trading platforms | ${this.props.match.params.sysname} | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="container row">
          <div className="main_with_left see_also">
            <h2>
              {/*Trading Platform*/}
              {t("TRADING_PLATFORM")}
            </h2>
            <div className="bg_wrap">
              {platformDetails && platformDetails_arr ? (
                platformDetails_arr.map((item, index) => {
                  let downloadUrl = null;
                  item.devices.forEach(e => {
                    console.log(e, osName);
                    if (e.name === osName) {
                      downloadUrl = e.downloadLink;
                    }
                  });

                  return (
                    <div className="block_see_also" key={index}>
                      <div className="row ninja_top">
                        <img src={`${item.logo}`} alt={"ForexGrad"} />
                        {!item.devices.length ? (
                          <button
                            className={`btn btn_md button_green ${
                              !downloadUrl ? "disabled" : ""
                            }`}
                            disabled={!!downloadUrl}
                          >
                            No devices
                          </button>
                        ) : (
                          <a
                            className={`btn btn_md button_green ${
                              !downloadUrl ? "disabled" : ""
                            }`}
                            rel="nofollow"
                            href={`${downloadUrl}`}
                            disabled={!downloadUrl}
                          >
                            Download {item.name}
                          </a>
                        )}
                      </div>
                      {item.devices.length === 0 ? (
                        <h2>No devices</h2>
                      ) : (
                        <div className="plt_option">
                          {item.devices.map((e, index) => {
                            return (
                              <span key={index} className="light_orange">
                                {e.device_name}
                              </span>
                            );
                          })}
                        </div>
                      )}

                      <div className="platform_text_cont">
                        {renderHtml(item.fullDescription)}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="loader">
                  {/*loading***/}
                  {t("LOADING")}
                </div>
              )}
            </div>
            <div className="bg_wrap_list bg_wrap">
              <div className="text_see_also">
                {/*See Also*/}
                {t("SEE_ALSO")}
              </div>

              <div className="block_see_also">
                {platformDetails &&
                platformDetails.platformList &&
                platformDetails.platformList.list ? (
                  platformDetails.platformList.list.map((item, index) => {
                    let downloadUrl = null;
                    item.platformDevices.forEach(e => {
                      console.log(e, osName);
                      if (e.device_name === osName) {
                        downloadUrl = e.download;
                      }
                    });

                    return (
                      <div className="block_see_also" key={index}>
                        <div className="row ninja_top">
                          <Link
                            onClick={this.scrolling.bind(this)}
                            to={PLATFORM_DETAILS_PAGE_CREATOR(item.sysname)}
                          >
                            <img src={`${item.logo}`} alt={"ForexGrad"} />
                          </Link>
                          {!item.platformDevices ? (
                            <button
                              className={`btn btn_md button_green ${
                                !downloadUrl ? "disabled" : ""
                              }`}
                              disabled={!!downloadUrl}
                            >
                              No devices
                            </button>
                          ) : (
                            <a
                              className={`btn btn_md button_green ${
                                !downloadUrl ? "disabled" : ""
                              }`}
                              rel="nofollow"
                              href={`${downloadUrl}`}
                              disabled={!downloadUrl}
                            >
                              Download {item.sysname}
                            </a>
                          )}
                        </div>
                        {item.platformDevices.length === 0 ? (
                          <h2>No devices</h2>
                        ) : (
                          <div className="plt_option">
                            {item.platformDevices.map((e, index) => {
                              return (
                                <span key={index} className="light_orange">
                                  {e.device_name}
                                </span>
                              );
                            })}
                          </div>
                        )}

                        <div className="platform_text_cont">
                          {renderHtml(item.description)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </div>
                )}
                {/*<h1>Loading...</h1>*/}
              </div>
            </div>
          </div>

          <div className="right_box_block">
            <div className="expel_class">
              <TopBrokers />
            </div>
            <div className="expel_class">
              <TopPromotionsBlock />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    platformDetails: store.platformDetails
  };
};
function mapDispatchToProps(dispatch) {
  return {
    attemptGetPlatformDetails: (lang, sysname) =>
      dispatch(attemptGetPlatformDetails(lang, sysname)),
    onClearPlatformDetails: () => dispatch(onClearPlatformDetails())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(TradingPlatformDetails)
);
