import React from "react";
import { Link } from "react-router-dom";
import {
  BROKERS_CHARTS,
  BROKERS_CHARTS_CUR_CREATOR
} from "../../configs/constants";
import "../../assets/css/components/charts/live_charts.css";
import TopBrokers from "../../containers/TopBrokers";
import Analysis from "../../containers/Analysis";
import RightBoxBlock from "./RightBoxBlock";
import ChartTable from "./ChartTable";
import CurrencyPairs from "./CurrencyPairs";
import PriceHistory from "./PriceHistory";
import Select from "react-select";
import ChartCFD from "./chart2/ChartCFD";
import {
  changeInstrGroup,
  changeInstrName,
  selectInstrument
} from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#404040"
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  }
};

const initialInstrGroup = [
  { value: "forex", label: "forex" },
  { value: "indices", label: "indices" },
  { value: "commodities", label: "commodities" },
  { value: "stocks", label: "stocks" },
  { value: "precious-metals", label: "precious-metals" },
  { value: "gold-trading", label: "gold-trading" },
  { value: "personal-instrument-pci", label: "personal-instrument-pci" },
  { value: "futures", label: "futures" },
  { value: "cfd-etf", label: "cfd-etf" },
  { value: "cryptocurrency", label: "cryptocurrency" },
  { value: "cripto", label: "cripto" }
];

class LiveChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instrGroup: { value: "forex", label: "forex" },
      selectedCategory: "forex",
      selectedInstrument: [],
      countPerPage: 1,
      activePage: 1
    };
  }

  handleInstrumentChange = instrName => {
    this.setState({ instrumentChanged: false }, () => {
      this.props.changeInstrName(instrName);
      const kaka = this.getByValue2(
        this.props.chartInstrument,
        instrName.value,
        "name"
      );
      console.log("KAKA", kaka);

      this.props.history.push(
        BROKERS_CHARTS_CUR_CREATOR(this.state.instrGroup.value, kaka.slug)
      );
    });
  };

  getByValue2(arr, value, findValue) {
    return arr.find(obj => obj[findValue] === value) || { name: "" };
  }

  handleInstrGroupChange = instrGroup => {
    this.setState({ instrGroup, instrumentChanged: true }, () => {
      this.props.selectInstrument(instrGroup.value);
      this.props.changeInstrName({ value: "", label: "select" });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.instrGroup.value !== prevState.instrGroup.value) {
      // console.error(`this.props.instrName.value---> ${this.props.instrName.value},prevState.instrName.value---> ${prevState.instrName.value}`  );

      this.props.changeInstrName({ value: null, label: "Select..." });
    }
  }

  componentDidMount() {
    // console.error("::::::::::::::::::", this.props.match.params);
    if (this.props.match.params.type) {
      this.setState({
        instrGroup: {
          value: `${this.props.match.params.type}`,
          label: `${this.props.match.params.type}`
        }
      });
    }
    this.props.selectInstrument(
      `${
        this.props.match.params.type
          ? `${this.props.match.params.type}`
          : "forex"
      }`
    );
  }

  componentWillUnmount() {
    this.props.changeInstrName({ value: "AUDUSD", label: "AUDUSD" });
  }

  getInstrumentValue() {
    const {
      match: { params },
      instrName,
      chartInstrument
    } = this.props;
    const { instrumentChanged } = this.state;
    if (instrumentChanged) {
      return {
        value: null,
        label: `Select...`
      };
    }

    if (!params.type || !params.cur) {
      return instrName;
    }
    return {
      value: `${
        this.getByValue2(chartInstrument, this.props.match.params.cur, "slug")
          .name
      }`,
      label: `${
        this.getByValue2(chartInstrument, this.props.match.params.cur, "slug")
          .name
      }`
    };
  }

  render() {
    console.warn(this.props.match.params, "(this.props.match.params");

    const { chartInstrument, instrName, t } = this.props;
    const { instrGroup } = this.state;

    const instruments = chartInstrument.map(item => ({
      value: `${item.name}`,
      label: `${
        item.alternate_name
          ? `${`${item.name} ${item.alternate_name}`}`
          : `${item.name}`
      }`
    }));

    return (
      <div>
        <div className="container row">
          <h2>
            {t("$_FOREX_CURRENCIES_LIVE_CHARTS")}
            {/*Live Charts*/}
          </h2>
          <div className="bg_wrap row">
            <div className="chart_wrapper_block">
              <div className="row chart_top">
                <h2>{`${
                  this.props.match.params.cur
                    ? this.props.match.params.cur
                    : instrName.value
                    ? instrName.value
                    : "AUDUSD"
                }`}</h2>
                <div className="category_block">
                  <div className="category_group">
                    <Select
                      value={instrGroup}
                      onChange={this.handleInstrGroupChange}
                      options={initialInstrGroup}
                      styles={customStyles}
                    />
                  </div>
                  <div className="category_group">
                    <Select
                      value={this.getInstrumentValue()}
                      onChange={this.handleInstrumentChange}
                      options={instruments}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>
              <div className="char_wrapper">
                <ChartCFD currency={this.getInstrumentValue()} />
              </div>
              <div className="row chart_btm">
                <a
                  className="btn btn_sm button_green"
                  href={
                    "https://www.ifcmarkets.com/en/open-trading-account?utm_campaign=forexGrad-website"
                  }
                  target="_blank"
                >
                  {t("REG_BANNER_HEADER")}
                  {/*Start Trading Now*/}
                </a>

                {/*<Link className="btn btn_sm button_green" to={BROKERS_CHARTS}>Start Trading Now</Link>*/}
                <div>
                  {/*Sign up with recommended broker***/}
                  {t("RECOMMENDED_BROKER")}
                </div>
              </div>
            </div>
            <div className="right_box_block block_val">
              <RightBoxBlock
                currency={this.getInstrumentValue()}
                title={`${
                  this.getByValue2(
                    chartInstrument,
                    this.props.match.params.cur,
                    "slug"
                  ).name
                }`}
              />
              <ChartTable
                currency={
                  this.props.match.params.cur
                    ? {
                        value: `${this.props.match.params.cur}`,
                        label: `${this.props.match.params.cur}`
                      }
                    : instrName
                }
              />
            </div>
          </div>
        </div>
        <div className="container row">
          <div className="main_with_left curr_p_wrap">
            <CurrencyPairs
              currency={
                this.props.match.params.cur
                  ? {
                      value: `${this.props.match.params.cur}`,
                      label: `${this.props.match.params.cur}`
                    }
                  : instrName
              }
            />
            <PriceHistory
              cur={`${this.getByValue2(
                chartInstrument,
                this.props.match.params.cur,
                "slug"
              )}`}
              currency={
                this.props.match.params.cur
                  ? {
                      value: `${this.props.match.params.cur}`,
                      label: `${this.props.match.params.cur}`
                    }
                  : instrName
              }
            />
          </div>
          <div className="right_box_block">
            <div className="expel_class">
              <TopBrokers />
            </div>
            <div className="expel_class">
              <Analysis />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    language: store.language,
    instrName: store.instrName,
    instrGroup: store.instrGroup,
    chartInstrument: store.chartInstrument
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeInstrName: instrName => dispatch(changeInstrName(instrName)),
    selectInstrument: Group => dispatch(selectInstrument(Group))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(LiveChart)
);
