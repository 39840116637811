import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import ForexGradSocket from "../../api/WebSocket";
import LiveQoute from "../../assets/css/components/LiveQoutes.module.css";
import { translate } from "react-i18next";

let dateNew = Math.round(Date.now() / 1000);
let from = dateNew - 240 * 60 * 1000;

class QuoteBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartIsAvailable: true
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.quote.name !== prevProps.quote.name) {
      this.getChartData();
    }
  }

  initializeChart() {
    if (document.getElementById(`chartdiv${this.props.index}`)) {
      am4core.options.commercialLicense = true;
      let chart = am4core.create(
        `chartdiv${this.props.index}`,
        am4charts.XYChart
      );
      chart.paddingRight = 20;
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.tooltip.disabled = true;
      dateAxis.labelsEnabled = false;
      dateAxis.renderer.labels.template.disabled = true;
      dateAxis.renderer.grid.disabled = true;
      dateAxis.renderer.grid.template.disabled = true;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.labelsEnabled = false;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.disabled = true;
      valueAxis.gridThickness = 0;
      valueAxis.renderer.grid.template.disabled = true;

      let series = chart.series.push(new am4charts.CandlestickSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "close";
      series.dataFields.openValueY = "open";
      series.dataFields.lowValueY = "low";
      series.dataFields.highValueY = "high";
      series.simplifiedProcessing = true;
      series.tooltip.disabled = true;

      series.dropFromOpenState.properties.fill = am4core.color("#ff4f65");
      series.dropFromOpenState.properties.stroke = am4core.color("#ff4f65");

      series.riseFromOpenState.properties.fill = am4core.color("#68ac1d");
      series.riseFromOpenState.properties.stroke = am4core.color("#68ac1d");

      this.chart = chart;
    }
  }

  getChartData(renderChart) {
    const Socket = new ForexGradSocket(
      () => Socket.sendHistory(60, from, dateNew, this.props.quote.name),
      ({ list }) => {
        renderChart && this.initializeChart();
        if (list.length && this.chart) {
          this.setState({ chartIsAvailable: true });
          this.chart.data = list
            .slice(list.length - 7, list.length - 1)
            .map(e => ({
              date: new Date(e.ctm),
              high: e.high,
              open: e.open,
              low: e.low,
              close: e.close
            }));
        } else {
          if (this.chart) {
            this.chart.data = [];
            this.setState({ chartIsAvailable: false });
          }
        }

        Socket.close();
      }
    );
  }

  componentDidMount() {
    this.getChartData(true);
  }

  render() {
    const { t } = this.props;

    return (
      <div className={LiveQoute.quote_block}>
        <div className={LiveQoute.select_cont}>
          <select
            onChange={e =>
              this.props.onChangeLiveQuote({
                ...this.props.quote,
                name: e.target.value,
                value: 154.5
              })
            }
            value={this.props.quote.name}
            className={LiveQoute.live_quotes_selectbox}
          >
            {this.props.instruments.length ? (
              this.props.instruments.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.name}
                  </option>
                );
              })
            ) : (
              <div className="loader">
                {/*loading***/}
                {t("LOADING")}
              </div>
            )}
          </select>
        </div>
        <div className={LiveQoute.chart_body}>
          <div className={LiveQoute.child}>
            <div className={LiveQoute.big_num}>
              {this.props.quote.value.bid}
            </div>
            <div
              className={`${
                this.props.quote.value.state === "down" ? "red_num" : "green"
              }`}
            >
              {this.props.quote.value.low ? (
                `+${(
                  this.props.quote.value.high - this.props.quote.value.low
                ).toFixed(3)} / ${this.props.quote.value.percent}%`
              ) : (
                <div>
                  <div className={`${LiveQoute.big_num} ${LiveQoute.black}`}>
                    ---
                  </div>
                  <div className={LiveQoute.black}>---/---</div>
                </div>
              )}
            </div>
          </div>
          <div className={LiveQoute.child}>
            <div
              id={`chartdiv${this.props.index}`}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default translate("common")(QuoteBlock);
