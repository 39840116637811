import React from "react";
import { attemptGetLatestReviews } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import ForexGradSocket from "../../api/WebSocket";

class ChartTableItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      livePrice: "---/---",
      symbol: "---/---",
      percent: "---",
      status: ""
    };
  }

  // (item.symbol.slice(0,3), item.symbol.slice(3,6));

  getTableItemQuote() {
    const Socket = new ForexGradSocket(
      () => Socket.sendQuote(this.props.currency),
      data => {
        this.setState({
          livePrice: `${data.ask} / ${data.bid}`,
          status: data.state,
          percent: data.percent,
          symbol: `${data.symbol}`
        });
      }
    );
  }

  getTableItemQuoteClose() {
    const Socket = new ForexGradSocket(() => Socket.close());
  }

  componentDidMount() {
    this.getTableItemQuote();
  }

  componentWillUnmount() {
    this.getTableItemQuoteClose();
  }

  render() {
    return (
      <tr>
        <td>{this.state.symbol}</td>
        <td>{this.state.livePrice}</td>
        <td className={`tableItemStatus${this.state.status}`}>
          {`${
            this.state.status === "up"
              ? "+"
              : this.state.status === "down"
              ? "-"
              : ""
          }${this.state.percent}%`}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language
  };
};
function mapDispatchToProps(dispatch) {
  return {
    attemptGetLatestReviews: (sysname, countPerPage, page) =>
      dispatch(attemptGetLatestReviews(sysname, countPerPage, page))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(ChartTableItem)
);
