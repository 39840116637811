import React from "react";
import "../.././assets/css/components/analysis/currency_market_technical_analysis.css";
import { connect } from "react-redux";
import PriceHistoryItem from "./PriceHistoryItem";
import { selectInstrument } from "../../redux/actions/indexActions";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";

let dateNew = Math.round(Date.now() / 1000);

class PriceHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoCurrency: [],
      list: []
    };
  }

  getByValue2(arr, value, findValue) {
    return arr.find(obj => obj[findValue] === value) || { name: "" };
  }

  getChartData() {
    // if(!this.props.match.params.type || !this.props.match.params.cur){
    //     return this.props.instrName
    // }
    console.warn(
      "[p[wrpet][pwr][ptw]r[ept][",
      this.getByValue2(
        this.props.chartInstrument,
        this.props.match.params.cur,
        "slug"
      ).name
    );

    const Req = {
      period: 30,
      from: dateNew - 30 * 60 * 1000,
      to: dateNew,
      cmd: "history",
      symbol: `${
        !this.props.match.params.type || !this.props.match.params.cur
          ? "AUDUSD"
          : this.getByValue2(
              this.props.chartInstrument,
              this.props.match.params.cur || this.props.instrName.value,
              "slug"
            ).name
      }`
      // "symbol": this.getByValue2(this.props.chartInstrument, this.props.match.params.cur, "slug").name
    };
    console.log("======", Req);

    const HistorySocket = new WebSocket("wss://ifccd.net:2053/");

    HistorySocket.onopen = () => {
      HistorySocket.send(JSON.stringify(Req));
      HistorySocket.onmessage = ({ data }) => {
        const chartData = JSON.parse(data);
        this.setState({ infoCurrency: chartData });
      };
    };
  }

  componentDidMount() {
    if (this.props.match.params.type) {
      this.setState({
        instrGroup: {
          value: `${this.props.match.params.type}`,
          label: `${this.props.match.params.type}`
        }
      });
    }
    this.props.selectInstrument(
      `${
        this.props.match.params.type
          ? `${this.props.match.params.type}`
          : "forex"
      }`
    );
    this.getChartData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.chartInstrument !== nextProps.chartInstrument) {
      this.getChartData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currency.value !== prevProps.currency.value) {
      this.getChartData();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="live_chart">
        <h2>Currency Pair price History</h2>
        <div className="bg_wrap">
          <div className="swipe">
            {/*Swipe table <span className="icon-right-small"></span>*/}
            {t("SWIPE_TABLE")}
          </div>
          <div className="cur_history">
            <table>
              <thead>
                <tr>
                  <th>
                    {/*Date*/}
                    {t("DATE")}
                  </th>
                  <th>
                    {/*Open Price*/}
                    {t("OPENING")}
                  </th>
                  <th>
                    {/*High*/}
                    {t("HIGH")}
                  </th>
                  <th>
                    {/*Low*/}
                    {t("LOW")}
                  </th>
                  <th>
                    {/*Close Price*/}
                    {t("CLOSE_PRICE")}
                  </th>
                  <th>
                    {/*Change %*/}
                    {t("CHANGE")} %
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.infoCurrency ? (
                  this.state.infoCurrency.list &&
                  this.state.infoCurrency.list.length > 0 ? (
                    this.state.infoCurrency.list.slice(1, 20).map(item => {
                      return <PriceHistoryItem data={item} />;
                    })
                  ) : (
                    <h3>
                      {t("NO_DATA_FOR_SHOW")}
                      {/*NO DATA FOR SHOW***/}
                    </h3>
                  )
                ) : (
                  <h1 className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </h1>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    selectInstruments: store.selectInstruments,
    chartInstrument: store.chartInstrument,
    instrName: store.instrName,
    newQuoteHistory: store.newQuoteHistory
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectInstrument: Group => dispatch(selectInstrument(Group))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PriceHistory))
);
