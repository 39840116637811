export const HOME_PAGE_ROUTE = "/";
export const BROKERS_ABOUT_US = "/about-us";
export const BROKERS_CHARTS = "/charts";
export const BROKERS_CHARTS_CUR = "/charts/:type/:cur";
export const BROKERS_CHARTS_CUR_CREATOR = (type, cur) =>
  `/charts/${type}/${cur}`;
export const BROKERS_ROUTE = "/brokers-ratings";
export const BROKER_DETAILS_PAGE = "/brokers-ratings/:sysname";
export const BROKER_DETAILS_PAGE_CREATOR = sysname =>
  `/brokers-ratings/${sysname}`;
export const BROKERS_FOREX_TOOLS = "/forex-tools";
export const BROKERS_TRADING_PLATFORMS = "/trading-platform";
export const BROKERS_CALENDARS = "/economic-calendar";
export const BROKERS_NEWS = "/financial-news";
export const BROKERS_EDUCATIONS = "/educations";
export const BROKERS_INNOVATION = "/innovation-in-trading";
export const BROKERS_FOREX_TRADING_BOOK = "/forex-trading-books";
export const BROKERS_FOREX_VIDEO_TUTORIAL = "/forex-video-tutorials";
export const BROKERS_FOREX_VIDEO_OVERVIEW = "/forex-video-overview";
export const BROKER_PAGE = "/broker_page";
export const EDUCATION_TRADERS_GLASSARY = "/traders_glossary";
export const ARTICLES = "/trading-articles";
export const ARTICLES_DETAIL = "/trading-articles/:slug";
export const ARTICLES_DETAIL_CREATOR = slug => `/trading-articles/${slug}`;

export const OUR_ARTICLES_DETAIL = "/trading-article/:slug";
export const OUR_ARTICLES_DETAIL_CREATOR = slug => `/trading-article/${slug}`;

export const PLATFORM_DETAILS_PAGE = `/trading-platforms/:sysname`;
export const PLATFORM_DETAILS_PAGE_CREATOR = sysname =>
  `/trading-platforms/${sysname}`;
export const MARKET_OVERVIEW_LIST = "/financial-news";
export const MARKET_OVERVIEW_DETAILS = `/financial-news/:slug`;
export const MARKET_OVERVIEW_DETAILS_CREATOR = slug =>
  `/financial-news/${slug}`;
export const BROKERS_ANALYTICS = "/analytics";
export const MARKET_SENTIMENT = "/markets_sentiment";
export const MARKET_COMMODITY_CALENDAR = "/trade-calendar";
export const MARKET_MOVERS = "/market_movers";
export const TOP_TRADERS = "/top_traders";
export const MARKET_OVERVIEW = "/market_overview";
export const BROKERS_ANALYTICS_DETAILS = `/analytics/:instrName/:date`;
export const BROKERS_ANALYTICS_DETAILS_CREATOR = (instrName, date) =>
  `/analytics/${instrName}/${date}`;

export const RSS_1 = "/rss1";
export const RSS_2 = "/rss2";
export const RSS_3 = "/rss3";
export const RSS_4 = "/rss4";
export const RSS_5 = "/rss5";
export const RSS_6 = "/rss6";
export const RSS_7 = "/rss7";
export const RSS_8 = "/rss8";
export const RSS_9 = "/rss9";
export const RSS_10 = "/rss10";
export const RSS_11 = "/rss11";
export const RSS_12 = "/rss12";
export const RSS_13 = "/rss13";
export const RSS_14 = "/rss14";
export const RSS_15 = "/rss15";
export const RSS_16 = "/rss16";
export const RSS_17 = "/rss17";
export const RSS_18 = "/rss18";
export const RSS_19 = "/rss19";
export const RSS_20 = "/rss20";
export const RSS_21 = "/rss21";
export const RSS_22 = "/rss22";

export const EXPERTS = "/experts";
export const ARTICLES_RSS = "/expert_rss";

/**
 * Sockets
 */

export const SOCKET_URL = "wss://ifccd.net:2053/";
