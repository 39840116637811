import React from "react";
import "./../../../assets/css/components/Tabs.css";
import { translate } from "react-i18next";
import TabsContUp from "../../TabsContUp";
import TabsContDown from "../../TabsContDown";
import TopLosersItem from "../../TopLosersItem";
import ForexGradSocket from "../../../api/WebSocket";
import {
  getPeriodDateName,
  setStockExchangeArray
} from "../../../redux/actions/indexActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  PERIOD_DATE_RANGE,
  PERIOD_DATE_RANGE_ROUTING,
  SELECT_CATEGORY_ARR_ROUTING
} from "./constants";

class TopGainLosersTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      up: [],
      down: [],
      activeTabIndex: 0
    };
  }
  getTopGainer(lang, category, range, group, select) {
    const Socket = new ForexGradSocket(
      // () => (Socket.getTopGainers(this.props.language,1 )),
      () =>
        Socket.getMarketMoversTableFrom(lang, category, range, group, select),
      data => {
        this.setState({ up: data.up, down: data.down });
      }
    );
  }
  getYesterdayTopGainer(lang, category, group, select) {
    const Socket = new ForexGradSocket(
      // () => (Socket.getTopGainers(this.props.language,1 )),
      () => Socket.getTopGainerFromYesterday(lang, category, group, select),
      data => {
        this.setState({ up: data.up, down: data.down });
      }
    );
  }
  getTodayTopGainersLose(lang, category, group, select) {
    const Socket = new ForexGradSocket(
      // () => (Socket.getTopGainers(this.props.language,1 )),
      () => Socket.getMarketMoversTableFromToday(lang, category, group, select),
      data => {
        this.setState({ up: data.up, down: data.down });
      }
    );
  }
  getCustomRangeTopGainerLoser(lang, category, from, to, group, select) {
    const Socket = new ForexGradSocket(
      // () => (Socket.getTopGainers(this.props.language,1 )),
      () =>
        Socket.getTopGainerLoserFromCustomRange(
          lang,
          category,
          from,
          to,
          group,
          select
        ),
      data => {
        this.setState({ up: data.up, down: data.down });
      }
    );
  }

  firstCalculate() {
    const { stockExchangeArray, selectorArray } = this.props;
    const date = new Date();
    const now = date.getTime();
    const endDate = Date.parse(date) / 1000;
    let startDateW = null;
    this.props.getPeriodDateName(this.props.periodDate);
    switch (this.props.periodDate) {
      case "Within 7 days":
        const last7 = now - 7 * 24 * 60 * 60 * 1000;
        const day7 = new Date(last7);
        startDateW = Date.parse(day7) / 1000;
        break;
      case "Within 30 Days":
        const last30 = now - 30 * 24 * 60 * 60 * 1000;
        const day30 = new Date(last30);
        startDateW = Date.parse(day30) / 1000;
        break;
      case "Within 90 Days":
        const last90 = now - 90 * 24 * 60 * 60 * 1000;
        const day90 = new Date(last90);
        startDateW = Date.parse(day90) / 1000;
        break;
      case "Within 365 Days":
        const last365 = now - 365 * 24 * 60 * 60 * 1000;
        const day365 = new Date(last365);
        startDateW = Date.parse(day365) / 1000;
        break;
      case "From the Start of a Week":
        const first = date.getDate() - date.getDay();
        const weekFirstday = new Date(date.setDate(first));
        startDateW = Date.parse(weekFirstday) / 1000;
        break;
      case "From the Start of a Month":
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        startDateW = Date.parse(firstDay) / 1000;
        break;
      case "From the Start of a Quarter":
        const quarter = Math.floor(date.getMonth() / 3);
        const firstDate = new Date(date.getFullYear(), quarter * 3, 1);
        startDateW = Date.parse(firstDate) / 1000;
        break;
      case "From the Start of a Year":
        const fusirstDayYear = new Date(date.getFullYear(), 0, 1);
        startDateW = Date.parse(fusirstDayYear) / 1000;
        break;
      default:
        startDateW = null;
    }

    this.props.periodDate === "Today"
      ? this.getTodayTopGainersLose(
          "en",
          this.props.category,
          stockExchangeArray,
          selectorArray
        )
      : this.props.periodDate === "Yesterday"
      ? this.getYesterdayTopGainer(
          "en",
          this.props.category,
          stockExchangeArray,
          selectorArray
        )
      : this.props.periodDate === "Custom Range"
      ? this.getCustomRangeTopGainerLoser(
          "en",
          this.props.category,
          endDate,
          startDateW,
          stockExchangeArray,
          selectorArray
        )
      : this.getTopGainer(
          "en",
          this.props.category,
          startDateW,
          stockExchangeArray,
          selectorArray
        );
  }

  calculate = () => {
    const startDate = Date.parse(this.props.startDate) / 1000;
    const endDate = Date.parse(this.props.endDate) / 1000;
    const { stockExchangeArray, selectorArray } = this.props;
    this.props.getPeriodDateName(this.props.periodDate);
    this.props.periodDate === "Today"
      ? this.getTodayTopGainersLose(
          "en",
          this.props.category,
          stockExchangeArray,
          selectorArray
        )
      : this.props.periodDate === "Yesterday"
      ? this.getYesterdayTopGainer(
          "en",
          this.props.category,
          stockExchangeArray,
          selectorArray
        )
      : this.props.periodDate === "Custom Range"
      ? this.getCustomRangeTopGainerLoser(
          "en",
          this.props.category,
          endDate,
          startDate,
          stockExchangeArray,
          selectorArray
        )
      : this.getTopGainer(
          "en",
          this.props.category,
          startDate,
          stockExchangeArray,
          selectorArray
        );
  };

  componentDidMount() {
    const {
      stockExchangeArray,
      selectorArray,
      category,
      startDate,
      endDate,
      range
    } = this.props;
    this.props.periodDate === "Today"
      ? this.getTodayTopGainersLose(
          "en",
          category,
          stockExchangeArray,
          selectorArray
        )
      : this.props.periodDate === "Yesterday"
      ? this.getYesterdayTopGainer(
          "en",
          category,
          stockExchangeArray,
          selectorArray
        )
      : this.props.periodDate === "Custom Range"
      ? this.getCustomRangeTopGainerLoser(
          "en",
          category,
          startDate,
          endDate,
          stockExchangeArray,
          selectorArray
        )
      : this.getTopGainer(
          "en",
          category,
          range,
          stockExchangeArray,
          selectorArray
        );
    this.firstCalculate();
  }

  render() {
    const { t } = this.props;
    const { activeTabIndex } = this.state;
    const currentRangeForRouting = PERIOD_DATE_RANGE.indexOf(
      this.props.periodDate
    );
    const content = [
      {
        title: "TOP_GAINERS",
        component: <TabsContUp />
      },
      {
        title: "TOP_LOSERS",
        component: <TabsContDown />
      }
    ];
    const data = activeTabIndex ? this.state.down : this.state.up;

    return (
      <div>
        <div className="calculatorBtn_block">
          <button
            className={"btn btn_md button_grayish_blue"}
            onClick={() => {
              this.calculate();
              this.props.history.push(
                `/market_movers/${
                  SELECT_CATEGORY_ARR_ROUTING[this.props.category]
                }?date-range=${
                  PERIOD_DATE_RANGE_ROUTING[currentRangeForRouting]
                }`
              );
            }}
          >
            Calculate
          </button>

          <div className="separationWall" />
        </div>
        <div className="tab_wrap">
          <div className="tabs_tab custom-style">
            {content.map((tab, i) => (
              <div
                key={i}
                className={this.state.activeTabIndex === i ? "active" : ""}
                onClick={() => this.setState({ activeTabIndex: i })}
              >
                {t(tab.title)}
              </div>
            ))}
          </div>
          <div className="tab_container custom-style-trigger">
            <div>
              <table>
                <thead>
                  <tr>
                    <td className="table_head">
                      {/*Name*/}
                      {t("NAME")}
                    </td>
                    <td className="table_head">
                      {/*Instrument*/}
                      {t("INSTRUMENT")}
                    </td>
                    <td className="table_head">
                      {/*Change*/}
                      {t("CHANGE")}, %
                    </td>
                    <td className="table_head">
                      {/*Change*/}
                      {t("CHANGE")}, $
                    </td>
                    <td className="table_head">
                      {/*Live Prices*/}
                      {t("LIVE_PRICES")}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.up.length || this.state.down.length ? (
                    data.map((item, index) => {
                      return (
                        <TopLosersItem
                          symbol={item.symbol}
                          transname={item.transname}
                          percent={item.percent}
                          change={item.change}
                          key={index}
                          number={index}
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td className="table_head">
                        {/*Name*/}
                        ---
                      </td>
                      <td className="table_head">
                        {/*Instrument*/}
                        ---
                      </td>
                      <td className="table_head">
                        {/*Change*/}
                        ---
                      </td>
                      <td className="table_head">
                        {/*Instrument*/}
                        ---
                      </td>
                      <td className="table_head">
                        {/*Change*/}
                        ---
                      </td>
                      <td className="table_head">
                        {/*Change*/}
                        ---
                      </td>
                      <td className="table_head">
                        {/*Live Prices*/}
                        ---
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          ;
        </div>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    stockExchangeArray: store.stockExchangeArray.filter(item => {
      return item !== "";
    }),
    selectorArray: store.selectorArray.filter(item => {
      return item !== "";
    })
  };
};
function mapDispatchToProps(dispatch) {
  return {
    setStockExchangeArray: componentState =>
      dispatch(setStockExchangeArray(componentState)),
    getPeriodDateName: periodName => dispatch(getPeriodDateName(periodName))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TopGainLosersTrigger))
);
