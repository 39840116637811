/**Instruments **/
export const ATTEMPT_GET_INSTRUMENTS_LIST = "ATTEMPT_GET_INSTRUMENTS_LIST";
export const GET_INSTRUMENTS_LIST_SUCCESS = "GET_INSTRUMENTS_LIST_SUCCESS";
export const GET_INSTRUMENTS_LIST_FAILED = "GET_INSTRUMENTS_LIST_FAILED";
export const LANGUAGE_CHANGE = "LANGUAGE_CHANGE";
export const LIVE_QUOTE_CHANGE = "LIVE_QUOTE_CHANGE";
export const LIVE_QUOTE_CHANGE_SUCESSED = "LIVE_QUOTE_CHANGE_SUCESSED";
export const ATTEMPT_GET_PLATFORM_LIST = "ATTEMPT_GET_PLATFORM_LIST";
export const GET_PLATFORM_LIST_SUCCESS = "GET_PLATFORM_LIST_SUCCESS";
export const GET_PLATFORM_LIST_FAILED = "GET_PLATFORM_LIST_FAILED";
export const CLEAR_BROKER_INFO = "CLEAR_BROKER_INFO";
export const CLEAR_PLATFORM_DETAILS_INFO = "CLEAR_PLATFORM_DETAILS_INFO";
export const CLEAR_MARKET_OVERVIEW_DETAILS_INFO =
  "CLEAR_MARKET_OVERVIEW_DETAILS_INFO";
export const CLEAR_GROUP_INFO = "CLEAR_GROUP_INFO";
export const CLEAR_ARTICLE_LIST = "CLEAR_ARTICLE_LIST";
export const ATTEMPT_SELECT_INSTRUMENTS_LIST =
  "ATTEMPT_SELECT_INSTRUMENTS_LIST";
export const SELECT_INSTRUMENTS_LIST_SUCCESS =
  "SELECT_INSTRUMENTS_LIST_SUCCESS";
export const SELECT_INSTRUMENTS_LIST_FAILED = "SELECT_INSTRUMENTS_LIST_FAILED";
export const CLEAR_ARTICLE_DETAILS_INFO = "CLEAR_ARTICLE_DETAILS_INFO";

export const onClearBrokerInfo = () => ({ type: CLEAR_BROKER_INFO });
export const onClearPlatformDetails = () => ({
  type: CLEAR_PLATFORM_DETAILS_INFO
});
export const onClearMarketOverviewDetails = () => ({
  type: CLEAR_MARKET_OVERVIEW_DETAILS_INFO
});
export const onClearArticleDetails = () => ({
  type: CLEAR_ARTICLE_DETAILS_INFO
});
export const onClearGroupInfo = () => ({ type: CLEAR_GROUP_INFO });
export const onClearArticleList = () => ({ type: CLEAR_ARTICLE_LIST });

export const attemptGetInstrumentsList = () => ({
  type: ATTEMPT_GET_INSTRUMENTS_LIST
});
export const getInstrumentsListSuccess = instruments => ({
  type: GET_INSTRUMENTS_LIST_SUCCESS,
  payload: { instruments }
});
export const getInstrumentsListFailed = error => ({
  type: GET_INSTRUMENTS_LIST_FAILED,
  payload: { error }
});

export const attemptSelectInstrumentsList = group => ({
  type: ATTEMPT_SELECT_INSTRUMENTS_LIST,
  payload: { group }
});
export const selectInstrumentsListSuccess = selectInstruments => ({
  type: SELECT_INSTRUMENTS_LIST_SUCCESS,
  payload: { selectInstruments }
});
export const selectInstrumentsListFailed = error => ({
  type: SELECT_INSTRUMENTS_LIST_FAILED,
  payload: { error }
});

/**Last Market Overview **/
export const ATTEMPT_GET_LAST_MARKET_OVERVIEW =
  "ATTEMPT_GET_LAST_MARKET_OVERVIEW";
export const GET_LAST_MARKET_OVERVIEW_SUCCESS =
  "GET_LAST_MARKET_OVERVIEW_SUCCESS";
export const GET_LAST_MARKET_OVERVIEW_FAILED =
  "GET_LAST_MARKET_OVERVIEW_FAILED";

export const getLastMarketOverview = lang => ({
  type: ATTEMPT_GET_LAST_MARKET_OVERVIEW,
  payload: { lang }
});
export const getLastMarketOverviewSuccess = (marketOverview, lang) => ({
  type: GET_LAST_MARKET_OVERVIEW_SUCCESS,
  payload: { marketOverview, lang }
});
export const getLastMarketOverviewFailed = error => ({
  type: GET_LAST_MARKET_OVERVIEW_FAILED,
  payload: { error }
});

/**Market Overview List**/
export const ATTEMPT_GET_MARKET_OVERVIEW_LIST =
  "ATTEMPT_GET_MARKET_OVERVIEW_LIST";
export const GET_MARKET_OVERVIEW_LIST_SUCCESS =
  "GET_MARKET_OVERVIEW_LIST_SUCCESS";
export const GET_MARKET_OVERVIEW_LIST_FAILED =
  "GET_MARKET_OVERVIEW_LIST_FAILED";

export const getMarketOverviewList = lang => ({
  type: ATTEMPT_GET_MARKET_OVERVIEW_LIST,
  payload: { lang }
});
export const getMarketOverviewListSuccess = (marketOverviewList, lang) => ({
  type: GET_MARKET_OVERVIEW_LIST_SUCCESS,
  payload: { marketOverviewList, lang }
});
export const getMarketOverviewListFailed = error => ({
  type: GET_MARKET_OVERVIEW_LIST_FAILED,
  payload: { error }
});

/**Market Overview Details**/
export const ATTEMPT_GET_MARKET_OVERVIEW_DETAILS =
  "ATTEMPT_GET_MARKET_OVERVIEW_DETAILS";
export const GET_MARKET_OVERVIEW_DETAILS_SUCCESS =
  "GET_MARKET_OVERVIEW_DETAILS_SUCCESS";
export const GET_MARKET_OVERVIEW_DETAILS_FAILED =
  "GET_MARKET_OVERVIEW_DETAILS_FAILED";

export const getMarketOverviewDetails = (lang, slug) => ({
  type: ATTEMPT_GET_MARKET_OVERVIEW_DETAILS,
  payload: { lang, slug }
});
export const getMarketOverviewDetailsSuccess = (
  MarketOverviewDetails,
  lang
) => ({
  type: GET_MARKET_OVERVIEW_DETAILS_SUCCESS,
  payload: { MarketOverviewDetails, lang }
});
export const getMarketOverviewDetailsFailed = error => ({
  type: GET_MARKET_OVERVIEW_DETAILS_FAILED,
  payload: { error }
});

/** brokers **/

export const ATTEMPT_GET_BROKER_LIST = "ATTEMPT_GET_BROKER_LIST";
export const GET_BROKER_LIST_SUCCESS = "GET_BROKER_LIST_SUCCESS";
export const GET_BROKER_LIST_FAILED = "GET_BROKER_LIST_FAILED";

export const attemptGetBrokerList = (
  lang,
  countPerPage,
  page,
  orderby,
  sortBy,
  filter
) => ({
  type: ATTEMPT_GET_BROKER_LIST,
  payload: { lang, countPerPage, page, orderby, sortBy, filter }
});
export const getBrokerListSuccess = brokers => ({
  type: GET_BROKER_LIST_SUCCESS,
  payload: { brokers }
});
export const getBrokerListFailed = error => ({
  type: GET_BROKER_LIST_FAILED,
  payload: { error }
});

/** brokersPage **/

export const ATTEMPT_GET_BROKER_PAGE = "ATTEMPT_GET_BROKER_PAGE";
export const ATTEMPT_GET_BROKER_PAGE_SUCCESS =
  "ATTEMPT_GET_BROKER_PAGE_SUCCESS";
export const ATTEMPT_GET_BROKER_PAGE_FAILED = "ATTEMPT_GET_BROKER_PAGE_FAILED";

export const attemptGetBrokerPage = (lang, sysname) => ({
  type: ATTEMPT_GET_BROKER_PAGE,
  payload: { lang, sysname }
});
export const getBrokerPageSuccess = brokerPage => ({
  type: ATTEMPT_GET_BROKER_PAGE_SUCCESS,
  payload: { brokerPage }
});
export const getBrokerPageFailed = error => ({
  type: ATTEMPT_GET_BROKER_PAGE_FAILED,
  payload: { error }
});

/** instrName && instrGroup**/

export const CHANGE_INSTRNAME = "CHANGE_INSTRNAME";
export const CHANGE_INSTRGROUP = "CHANGE_INSTRGROUP";

export const changeInstrName = instrName => ({
  type: CHANGE_INSTRNAME,
  payload: { instrName }
});
export const changeInstrGroup = instrGroup => ({
  type: CHANGE_INSTRGROUP,
  payload: { instrGroup }
});

/** Market movers stock exchange**/
export const SET_STOCK_EXCHANGE_ARRAY = "SET_STOCK_EXCHANGE_ARRAY";
export const setStockExchangeArray = componentState => ({
  type: SET_STOCK_EXCHANGE_ARRAY,
  payload: { componentState }
});
/** Market movers period name**/
export const GET_PERIOD_NAME = "GET_PERIOD_NAME";
export const getPeriodDateName = periodName => ({
  type: GET_PERIOD_NAME,
  payload: { periodName }
});

/** Market movers selector**/
export const SET_SELECTOR_ARRAY = "SET_SELECTOR_ARRAY";
export const setSelectorArray = componentState => ({
  type: SET_SELECTOR_ARRAY,
  payload: { componentState }
});

/** selectInstruments **/

export const SELECT_INSTRUMENTS = "SELECT_INSTRUMENTS";
export const SELECT_INSTRUMENTS_SUCCESS = "SELECT_INSTRUMENTS_SUCCESS";
export const SELECT_INSTRUMENTS_FAILED = "SELECT_INSTRUMENTS_FAILED";

export const selectInstrument = Group => ({
  type: SELECT_INSTRUMENTS,
  payload: { Group }
});
export const selectInstrumentSuccess = chartInstrument => ({
  type: SELECT_INSTRUMENTS_SUCCESS,
  payload: { chartInstrument }
});
export const selectInstrumentFailed = error => ({
  type: SELECT_INSTRUMENTS_FAILED,
  payload: { error }
});

/** PlatformDetails **/

export const ATTEMPT_GET_PLATFORM_DETAILS = "ATTEMPT_GET_PLATFORM_DETAILS";
export const ATTEMPT_GET_PLATFORM_DETAILS_SUCCESS =
  "ATTEMPT_GET_PLATFORM_DETAILS_SUCCESS";
export const ATTEMPT_GET_PLATFORM_DETAILS_FAILED =
  "ATTEMPT_GET_PLATFORM_DETAILS_FAILED";

export const attemptGetPlatformDetails = (lang, sysname) => ({
  type: ATTEMPT_GET_PLATFORM_DETAILS,
  payload: { lang, sysname }
});
export const getPlatformDetailsSuccess = platformDetails => ({
  type: ATTEMPT_GET_PLATFORM_DETAILS_SUCCESS,
  payload: { platformDetails }
});
export const getPlatformDetailsFailed = error => ({
  type: ATTEMPT_GET_PLATFORM_DETAILS_FAILED,
  payload: { error }
});

/** language_filter **/

export const GET_BROKERS_FILTER = "GET_BROKERS_FILTER";
export const GET_BROKERS_FILTER_SUCCESS = "GET_BROKERS_FILTER_SUCCESS";
export const GET_BROKERS_FILTER_FAILED = "GET_BROKERS_FILTER_FAILED";

export const getBrokersFilter = lang => ({
  type: GET_BROKERS_FILTER,
  payload: { lang }
});
export const getBrokersFilterSuccess = brokersLang => ({
  type: GET_BROKERS_FILTER_SUCCESS,
  payload: { brokersLang }
});
export const getBrokersFilterFailed = error => ({
  type: GET_BROKERS_FILTER_FAILED,
  payload: { error }
});

/** brokers **/

export const attemptGetPlatformList = lang => ({
  type: ATTEMPT_GET_PLATFORM_LIST,
  payload: { lang }
});
export const getPlatformListSuccess = platform => ({
  type: GET_PLATFORM_LIST_SUCCESS,
  payload: { platform }
});
export const getPlatformListFailed = error => ({
  type: GET_PLATFORM_LIST_FAILED,
  payload: { error }
});

/** TopPromotions **/

export const ATTEMPT_GET_TOP_PROMOTIONS = "ATTEMPT_GET_TOP_PROMOTIONS";
export const GET_TOP_PROMOTIONS_SUCCESS = "GET_TOP_PROMOTIONS_SUCCESS";
export const GET_TOP_PROMOTIONS_FAILED = "GET_TOP_PROMOTIONS_FAILED";

export const attemptGetTopPromotions = lang => ({
  type: ATTEMPT_GET_TOP_PROMOTIONS,
  payload: { lang }
});
export const getTopPromotionsSuccess = topPromotions => ({
  type: GET_TOP_PROMOTIONS_SUCCESS,
  payload: { topPromotions }
});
export const getTopPromotionsFailed = error => ({
  type: GET_TOP_PROMOTIONS_FAILED,
  payload: { error }
});

/** LatestReviews **/

export const ATTEMPT_GET_LATEST_REVIEWS = "ATTEMPT_GET_LATEST_REVIEWS";
export const GET_LATEST_REVIEWS_SUCCESS = "GET_LATEST_REVIEWS_SUCCESS";
export const GET_LATEST_REVIEWS_FAILED = "GET_LATEST_REVIEWS_FAILED";

export const attemptGetLatestReviews = (sysname, countPerPage, page) => ({
  type: ATTEMPT_GET_LATEST_REVIEWS,
  payload: { sysname, countPerPage, page }
});
export const getLatestReviewsSuccess = latestReviews => ({
  type: GET_LATEST_REVIEWS_SUCCESS,
  payload: { latestReviews }
});
export const getLatestReviewsFailed = error => ({
  type: GET_LATEST_REVIEWS_FAILED,
  payload: { error }
});

/** Videos **/
export const ATTEMPT_LAST_VIDEO = "ATTEMPT_LAST_VIDEO";
export const GET_LAST_VIDEO_SUCCESS = "GET_LAST_VIDEO_SUCCESS";
export const GET_LAST_VIDEO_FAILED = "GET_LAST_VIDEO_FAILED";

export const getLastVideo = lang => ({
  type: ATTEMPT_LAST_VIDEO,
  payload: { lang }
});
export const getLastVideoSuccess = (video, lang) => ({
  type: GET_LAST_VIDEO_SUCCESS,
  payload: { video, lang }
});
export const getLastVideoFailed = error => ({
  type: GET_LAST_VIDEO_FAILED,
  payload: { error }
});

/** VideosList **/
export const ATTEMPT_VIDEO_LIST = "ATTEMPT_VIDEO_LIST";
export const GET_VIDEO_LIST_SUCCESS = "GET_VIDEO_LIST_SUCCESS";
export const GET_VIDEO_LIST_FAILED = "GET_VIDEO_LIST_FAILED";

export const getVideoList = (lang, countPerPage, page) => ({
  type: ATTEMPT_VIDEO_LIST,
  payload: { lang, countPerPage, page }
});
export const getVideoListSuccess = videoList => ({
  type: GET_VIDEO_LIST_SUCCESS,
  payload: { videoList }
});
export const getVideoListFailed = error => ({
  type: GET_VIDEO_LIST_FAILED,
  payload: { error }
});

/** TopTrades **/
export const ATTEMPT_TOP_TRADES = "ATTEMPT_TOP_TRADES";
export const GET_TOP_TRADES_SUCCESS = "GET_TOP_TRADES_SUCCESS";
export const GET_TOP_TRADES_FAILED = "GET_TOP_TRADES_FAILED";

export const attemptGetTopTrades = (lang, group) => ({
  type: ATTEMPT_TOP_TRADES,
  payload: { lang, group }
});
export const getTopTradesSuccess = groupData => ({
  type: GET_TOP_TRADES_SUCCESS,
  payload: { groupData }
});
export const getTopTradesFailed = error => ({
  type: GET_TOP_TRADES_FAILED,
  payload: { error }
});

/**Tech analys **/
export const ATTEMPT_GET_TECH_ANALYS_LIST = "ATTEMPT_GET_TECH_ANALYS_LIST";
export const GET_TECH_ANALYS_LIST_SUCCESS = "GET_TECH_ANALYS_LIST_SUCCESS";
export const GET_TECH_ANALYS_LIST_FAILED = "GET_TECH_ANALYS_LIST_FAILED";

export const getTechAnalysList = (
  lang,
  countPerPage,
  page,
  dateInterval,
  instrGroup,
  instrName
) => ({
  type: ATTEMPT_GET_TECH_ANALYS_LIST,
  payload: { lang, countPerPage, page, dateInterval, instrGroup, instrName }
});
export const getTechAnalysListSuccess = techAnalys => ({
  type: GET_TECH_ANALYS_LIST_SUCCESS,
  payload: { techAnalys }
});
export const getTechAnalysListFailed = error => ({
  type: GET_TECH_ANALYS_LIST_FAILED,
  payload: { error }
});

/**Tech analys **/
export const ATTEMPT_GET_TECH_ANALYS_DETAILS =
  "ATTEMPT_GET_TECH_ANALYS_DETAILS";
export const GET_TECH_ANALYS_DETAILS_SUCCESS =
  "GET_TECH_ANALYS_DETAILS_SUCCESS";
export const GET_TECH_ANALYS_DETAILS_FAILED = "GET_TECH_ANALYS_DETAILS_FAILED";

export const getTechAnalysDetails = (lang, instrName, date) => ({
  type: ATTEMPT_GET_TECH_ANALYS_DETAILS,
  payload: { lang, instrName, date }
});
export const getTechAnalysDetailsSuccess = techAnalysDetails => ({
  type: GET_TECH_ANALYS_DETAILS_SUCCESS,
  payload: { techAnalysDetails }
});
export const getTechAnalysDetailsFailed = error => ({
  type: GET_TECH_ANALYS_DETAILS_FAILED,
  payload: { error }
});

/**Tech analys **/
export const ATTEMPT_GET_ARTICLE_LIST = "ATTEMPT_GET_ARTICLE_LIST";
export const GET_TECH_ARTICLE_LIST_SUCCESS = "GET_TECH_ARTICLE_LIST_SUCCESS";
export const GET_TECH_ARTICLE_LIST_FAILED = "GET_TECH_ARTICLE_LIST_FAILED";

export const getArticleList = (lang, countPerPage, page) => ({
  type: ATTEMPT_GET_ARTICLE_LIST,
  payload: { lang, countPerPage, page }
});
export const getArticleListSuccess = article => ({
  type: GET_TECH_ARTICLE_LIST_SUCCESS,
  payload: { article }
});
export const getArticleListFailed = error => ({
  type: GET_TECH_ARTICLE_LIST_FAILED,
  payload: { error }
});

/**Tech analys **/
export const ATTEMPT_GET_ARTICLE_DETAILS = "ATTEMPT_GET_ARTICLE_DETAILS";
export const GET_TECH_ARTICLE_DETAILS_SUCCESS =
  "GET_TECH_ARTICLE_DETAILS_SUCCESS";
export const GET_TECH_ARTICLE_DETAILS_FAILED =
  "GET_TECH_ARTICLE_DETAILS_FAILED";

export const getArticleDetails = (slug, lang, page) => ({
  type: ATTEMPT_GET_ARTICLE_DETAILS,
  payload: { slug, lang, page }
});
export const getArticleDetailsSuccess = articleDetails => ({
  type: GET_TECH_ARTICLE_DETAILS_SUCCESS,
  payload: { articleDetails }
});
export const getArticleDetailsFailed = error => ({
  type: GET_TECH_ARTICLE_DETAILS_FAILED,
  payload: { error }
});

/**multi **/
export const onLanguageChange = language => ({
  type: LANGUAGE_CHANGE,
  payload: { language }
});

/**quoteChange **/
export const onChangeLiveQuote = (newQuote, index) => {
  // console.log(index, newQuote)
  return { type: LIVE_QUOTE_CHANGE, payload: { newQuote, index } };
};

/**getSymbolQuoteData **/
export const GET_SYMBOL_QUOTE_DATA = "GET_SYMBOL_QUOTE_DATA";
export const GET_SYMBOL_QUOTE_DATA_SUCCESS = "GET_SYMBOL_QUOTE_DATA_SUCCESS";

export const getSymbolQuotData = newCurrency => ({
  type: GET_SYMBOL_QUOTE_DATA,
  payload: { newCurrency }
});
export const getSymbolQuotDataSuccess = newQuoteData => {
  return { type: GET_SYMBOL_QUOTE_DATA_SUCCESS, payload: { newQuoteData } };
};

export const CLOSE_QUOTE_SOCKET = "CLOSE_QUOTE_SOCKET";

export const closeQuoteSocket = () => ({ type: CLOSE_QUOTE_SOCKET });

/** getCurrencyDescription **/

export const GET_CURRENCY_DESCRIPTION = "GET_CURRENCY_DESCRIPTION";
export const GET_CURRENCY_DESCRIPTION_SUCCESS =
  "GET_CURRENCY_DESCRIPTION_SUCCESS";
export const GET_CURRENCY_DESCRIPTION_FAILED =
  "GET_CURRENCY_DESCRIPTION_FAILED";

export const getCurrencyDescription = cur => ({
  type: GET_CURRENCY_DESCRIPTION,
  payload: { cur }
});
export const getCurrencyDescriptionSuccess = currencyDescriotion => ({
  type: GET_CURRENCY_DESCRIPTION_SUCCESS,
  payload: { currencyDescriotion }
});
export const getCurrencyDescriptionFailed = error => ({
  type: GET_CURRENCY_DESCRIPTION_FAILED,
  payload: { error }
});
