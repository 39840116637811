import { SOCKET_URL } from "../configs/constants";

const socketQuoteMaker = symbol => JSON.stringify({ cmd: "quote", symbol });
const socketHistoryMaker = (period, from, to, symbol) =>
  JSON.stringify({ period, from, to, cmd: "history", symbol });
const socketTopGainer = lang => JSON.stringify({ cmd: "gainlose", lang });

// const getMarketMoversInitial = (lang,) => (JSON.stringify({ cmd: "gainlose", lang}));

const getMarketMoversTableFrom = (lang, category, from, group, select) =>
  category === 9
    ? JSON.stringify({ cmd: "gainlose", lang, from })
    : category === 4
    ? JSON.stringify({ cmd: "gainlose", lang, category, from, group, select })
    : JSON.stringify({ cmd: "gainlose", lang, category, from });

const getMarketMoversTableFromToday = (lang, category, group, select) =>
  category === 9
    ? JSON.stringify({ cmd: "gainlose", lang, price: 1 })
    : category === 4
    ? JSON.stringify({
        cmd: "gainlose",
        lang,
        category,
        price: 1,
        group,
        select
      })
    : JSON.stringify({ cmd: "gainlose", lang, category, price: 1 });

const getTopGainerFromYesterday = (lang, category, group, select) =>
  category === 9
    ? JSON.stringify({ cmd: "gainlose", lang })
    : category === 4
    ? JSON.stringify({ cmd: "gainlose", lang, category, group, select })
    : JSON.stringify({ cmd: "gainlose", lang, category });

const getTopGainerLoserFromCustomRange = (
  lang,
  category,
  from,
  to,
  group,
  select
) =>
  category === 9
    ? JSON.stringify({ cmd: "gainlose", lang, from, to })
    : category === 4
    ? JSON.stringify({
        cmd: "gainlose",
        lang,
        category,
        from,
        to,
        group,
        select
      })
    : JSON.stringify({ cmd: "gainlose", lang, category, from, to });

class ForexGradSocket {
  constructor(readySocketCallback, receiveData) {
    this.socket = new WebSocket(SOCKET_URL);
    this.socket.onopen = () => {
      readySocketCallback();
    };

    this.socket.onclose = event => {
      // console.log("Socket Closed")
    };

    this.socket.onmessage = message => {
      receiveData(JSON.parse(message.data));
    };

    this.socket.onerror = error => {
      console.error("Socket error", error);
    };
  }

  sendQuote(quote) {
    this.socket.send(socketQuoteMaker(quote));
  }

  sendHistory(period, from, to, symbol) {
    this.socket.send(socketHistoryMaker(period, from, to, symbol));
  }

  getTopGainers(lang) {
    this.socket.send(socketTopGainer(lang));
  }

  getMarketMoversTableFrom(lang, category, from, group, select) {
    this.socket.send(
      getMarketMoversTableFrom(lang, category, from, group, select)
    );
  }
  getMarketMoversTableFromToday(lang, category, group, select) {
    this.socket.send(
      getMarketMoversTableFromToday(lang, category, group, select)
    );
  }
  getTopGainerFromYesterday(lang, category, group, select) {
    this.socket.send(getTopGainerFromYesterday(lang, category, group, select));
  }
  getTopGainerLoserFromCustomRange(lang, category, from, to, group, select) {
    this.socket.send(
      getTopGainerLoserFromCustomRange(lang, category, from, to, group, select)
    );
  }

  close() {
    this.socket.close();
  }
}

export default ForexGradSocket;
