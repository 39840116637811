import React from "react";

import FxVideoTutorial from "../../assets/css/components/education/ForexVideoTutorial.module.css";
import Global from "../../assets/css/Global.module.css";
import TopBrokers from "../.././containers/TopBrokers";
import { getVideoList } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import "react-modal-video/scss/modal-video.scss";
import VideoPlayer from "../../components/VideoPlayer";
import Pagination from "react-js-pagination";

class VideoOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      activeVideoIndex: null,
      activePage: 1,
      page: 1
    };
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
    this.props.getVideoList(this.props.language, 10, pageNumber);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.getVideoList(this.props.language, 10, this.state.activePage);
    }
  }

  componentDidMount() {
    this.props.getVideoList(this.props.language, 10, this.state.page);
  }

  render() {
    const { t } = this.props;
    console.error("STATE", this.state.activeVideoIndex);

    return (
      <div className="row">
        <div className="container row">
          <div className="main_with_left market_overview_videos">
            <h2>{t("WEEKLY_MARKET_OVERVIEW_VIDEOS")}</h2>
            <div
              className={`${FxVideoTutorial.video_toturial} ${Global.bg_wrap}`}
            >
              {this.props.videoList && this.props.videoList.list ? (
                this.props.videoList.list.length ? (
                  this.props.videoList.list.map((videoTutorial, index) => (
                    <div
                      key={index}
                      className={`${
                        (index + 1) % 2
                          ? `${FxVideoTutorial.leftSide}`
                          : `${FxVideoTutorial.rightSide}`
                      }`}
                    >
                      <div
                        className={FxVideoTutorial.video_les}
                        data-video-hash=""
                        onClick={() => {
                          this.setState({
                            activeVideoIndex:
                              this.state.activeVideoIndex === index
                                ? null
                                : index
                          });
                        }}
                        style={{
                          backgroundImage:
                            "url(" +
                            `https://i.ytimg.com/vi_webp/${videoTutorial.videoID}/maxresdefault.webp` +
                            ")",
                          backgroundSize: "contain"
                        }}
                      >
                        <VideoPlayer
                          videoId={videoTutorial.videoID}
                          isVideoModalOpened={
                            this.state.activeVideoIndex === index
                          }
                          openModal={() =>
                            this.setState({ activeVideoIndex: index })
                          }
                          closeModal={() =>
                            this.setState({ activeVideoIndex: null })
                          }
                        />
                      </div>

                      <h3>{videoTutorial.title}</h3>
                      <span className="date_block">{videoTutorial.date}</span>
                    </div>
                  ))
                ) : (
                  <h3>
                    {/*NO DATA FOR SHOW***/}
                    {t("NO_DATA_FOR_SHOW")}
                  </h3>
                )
              ) : (
                <div className="loader">
                  {/*loading***/}
                  {t("LOADING")}
                </div>
              )}
            </div>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={
                this.props.videoList && this.props.videoList.count
              }
              pageRangeDisplayed={5}
              onChange={e => this.handlePageChange(e)}
            />
          </div>
          <div className={Global.right_box_block}>
            {/*<div className="expel_class">*/}
            {/*<EducationSections/>*/}
            {/*</div>*/}

            <div className={Global.expel_class}>
              <TopBrokers />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    videoList: store.videoList
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getVideoList: (lang, countPerPage, page) =>
      dispatch(getVideoList(lang, countPerPage, page))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(VideoOverview)
);
