import React from "react";
import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import TopBrokers from "../../containers/TopBrokers";
import Videos from "../../containers/videoOvervie/Videos";
import { getMarketOverviewList } from "../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import Global from "../../assets/css/Global.module.css";
import Analytics from "../../assets/css/components/Analytics.module.css";
import Select from "react-select";
import Pagination from "react-js-pagination";

const instrGroup = [
  // { value: "", label: "select all" },
  { value: "gurutrade-news-finance", label: "Gurutrade" },
  { value: "ifc", label: "ifc" },
  { value: "instaforex-news", label: "instaforex" },
  { value: "cnbc-device", label: "cnbc" },
  { value: "myfxbook-forex-community", label: "myfxbook" }
];

const DATA = {
  "gurutrade-news-finance": "GURUTRADE",
  ifc: "ifc",
  "nbc-device": "cnbc",
  "myfxbook-forex-community": "myfxbook",
  "instaforex-news": "instaforex"
};

// import EducationSections from "./MarketOverviewDetail";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class MarketOverViewList extends React.Component {
  constructor(props) {
    super(props);
    const path = this.props.history.location.pathname.split("/")[2] || "ifc";
    this.state = {
      rssItems: null,
      brokerName:
        DATA[this.props.history.location.pathname.split("/")[2]] || "ifc",
      news: "gurutrade-news-finance",
      brokerData: { value: path, label: DATA[path] },

      newsCount: 0,
      activePage: 1,
      page: 1
    };
  }
  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  attemptGetData(lang, activePage, countPerPage = 10) {
    fetch(
      `https://www.ifcm.co.uk:2083/news-list?lang=${lang}&countPerPage=${countPerPage}&page=${activePage}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data.list, newsCount: data.count }, () =>
          console.log(this.state.rssItems, "YUYUYUY")
        );
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.attemptGetData(this.props.language, this.state.activePage);
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
    // this.attemptGetData(this.props.language, this.state.activePage)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.language !== prevProps.language ||
      this.state.activePage !== prevState.activePage
    ) {
      this.attemptGetData(this.props.language, this.state.activePage);
      this.scrolling()
    }
  }

  render() {
    console.log(
      this.props.history.location.pathname.split("/")[2],
      "pppppppppppppppppppppppppppppppp"
    );
    const { marketOverviewList, t } = this.props;
    const { rssItems, brokerName } = this.state;

    return (
      <div>
        <Helmet>
          <title>{`Market Overview | Daily Forex News | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="row">
          <div className="container row">
            <div className="video_less_wrap main_with_left">
              <h1>
                {/*NEWS*/}
                {t("WIDGETS_NEWS")}
              </h1>
              {/*date: "2020-04-23T08:32:36.000Z"*/}
              {/*link: "https://www.ifcmarkets.com/en/market-overview/netflix1125445?_ga=2.126882634.677625070.1587360945-1944631613.1570616471"*/}
              {/*newsDescription: "Today's data on changes in the US oil reserves for the week may affect the dynamics of the markets. The main reason for the decrease in world oil prices is that world production is still 90 million barrels per day, while global demand is only 75 million. Due to the quarantine and the reduction of passenger traffic, the world’s demand for motor fuel has fallen against the backdrop of coronavirus. The surplus so far goes to oil storages of different countries. The decline in oil prices has a negative impact on stock markets, as it is a sign of a global economic downturn."*/}
              {/*newsTitle: "US oil reserves data may affect markets"*/}
              {/*sysname: "ifcmarkets"*/}
              <div className="bg_wrap">
                {/*{marketOverviewList ? (*/}
                {/*    marketOverviewList.length > 0 ? (*/}
                {/*        marketOverviewList.map((item, index) => {*/}
                {rssItems ? (
                  rssItems.map((item, index) => {
                    return (
                      <div key={index} className="video_cont_block">
                        {/*<div className="vid_cont_block_txt no-padding">*/}
                        <div className={"w-100"}>
                          <div className="video_less_title">
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={item.link}
                              // href={item.url}
                            >
                              {/*<Link to={MARKET_OVERVIEW_DETAILS_CREATOR(item.slug)}>*/}
                              {item.title}
                              {/*</Link>*/}
                            </a>
                          </div>
                          <div className="new_cont_block">
                            {renderHtml(item.description)}
                            {/*{renderHtml(item.text)}*/}
                          </div>
                          <div className="vdl_footer_bl">
                            {/*<div className="date_block">*/}
                              {/*{`${*/}
                              {/*item.date.split("T")[0]*/}
                            {/*} ${item.date.split("T")[1].slice(0, 5)}`}*/}
                            {/*</div>*/}
                            <div className="date_block">{item.date}</div>
                            <div>
                              source by: <a href={item.link}>{item.sysname}</a>
                            </div>
                            {/*<div className="date_block">{item.date}</div>*/}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </h1>
                )}
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.newsCount}
                  pageRangeDisplayed={5}
                  onChange={e => this.handlePageChange(e)}
                />
              </div>
            </div>
            <div className="right_box_block">
              <div className="expel_class">
                <Videos />
              </div>
              <div className="expel_class">
                <TopBrokers />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    marketOverviewList: store.marketOverviewList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getMarketOverviewList: lang => dispatch(getMarketOverviewList(lang))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketOverViewList))
);
