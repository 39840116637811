import React from "react";
import GLBTAB from "./glbTab";
import { translate } from "react-i18next";
import "../assets/css/components/gainers_losers.css";

class GainersLosers extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="top_losers_tables main_half">
        <h2>
          {t("TOP_GAINERS")} / {t("LOSERS")}
        </h2>
        <div className="swipe">
          {/*Swipe table <span className="icon-right-small"></span>*/}
          {t("SWIPE_TABLE")}
        </div>
        <GLBTAB />
      </div>
    );
  }
}
export default translate("common")(GainersLosers);
