import {
  ATTEMPT_GET_INSTRUMENTS_LIST,
  getInstrumentsListSuccess,
  ATTEMPT_GET_LAST_MARKET_OVERVIEW,
  getLastMarketOverviewSuccess,
  ATTEMPT_LAST_VIDEO,
  getLastVideoSuccess,
  ATTEMPT_GET_TECH_ANALYS_LIST,
  getTechAnalysListSuccess,
  LIVE_QUOTE_CHANGE,
  LIVE_QUOTE_CHANGE_SUCESSED,
  ATTEMPT_GET_BROKER_LIST,
  getBrokerListSuccess,
  ATTEMPT_GET_PLATFORM_LIST,
  getPlatformListSuccess,
  GET_BROKERS_FILTER,
  getBrokersFilterSuccess,
  getBrokerListFailed,
  getBrokerPageSuccess,
  getBrokerPageFailed,
  ATTEMPT_GET_BROKER_PAGE,
  ATTEMPT_TOP_TRADES,
  getTopTradesSuccess,
  getTopTradesFailed,
  ATTEMPT_SELECT_INSTRUMENTS_LIST,
  selectInstrumentsListSuccess,
  ATTEMPT_GET_TOP_PROMOTIONS,
  getTopPromotionsSuccess,
  getLatestReviewsSuccess,
  ATTEMPT_GET_LATEST_REVIEWS,
  ATTEMPT_GET_PLATFORM_DETAILS,
  getPlatformDetailsSuccess,
  getPlatformDetailsFailed,
  ATTEMPT_VIDEO_LIST,
  getVideoListSuccess,
  ATTEMPT_GET_MARKET_OVERVIEW_LIST,
  getMarketOverviewListSuccess,
  ATTEMPT_GET_MARKET_OVERVIEW_DETAILS,
  getMarketOverviewDetailsSuccess,
  getMarketOverviewDetailsFailed,
  ATTEMPT_GET_TECH_ANALYS_DETAILS,
  getTechAnalysDetailsSuccess,
  ATTEMPT_GET_ARTICLE_DETAILS,
  ATTEMPT_GET_ARTICLE_LIST,
  getArticleListSuccess,
  getArticleDetailsSuccess,
  selectInstrumentSuccess,
  selectInstrumentFailed,
  SELECT_INSTRUMENTS,
  GET_SYMBOL_QUOTE_DATA_SUCCESS,
  GET_CURRENCY_DESCRIPTION,
  getCurrencyDescriptionSuccess,
  CLOSE_QUOTE_SOCKET,
  GET_SYMBOL_QUOTE_DATA
} from "./actions/indexActions";
import ForexGradSocket from "../api/WebSocket";

import GeneralApi from "../api";
const Api = new GeneralApi();

let openedSocket = {};
let QuoteSocketData = null;

const apiMiddleWare = store => next => action => {
  let QuoteSocket = null;

  const { type, payload } = action;
  switch (type) {
    case ATTEMPT_GET_INSTRUMENTS_LIST:
      Api.getInstruments()
        .then(data => data.json())
        .then(res => {
          next(getInstrumentsListSuccess(res));
        });
      break;
    case ATTEMPT_SELECT_INSTRUMENTS_LIST:
      Api.selectInstruments()
        .then(data => data.json())
        .then(res => {
          next(selectInstrumentsListSuccess(res));
        });
      break;
    case ATTEMPT_GET_BROKER_LIST:
      Api.getBrokers(
        payload.lang,
        payload.countPerPage,
        payload.page,
        payload.orderby,
        payload.sortBy,
        payload.filter
      )
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getBrokerListSuccess(res.list));
          } else {
            next(getBrokerListFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_BROKER_PAGE:
      Api.getBrokerPage(payload.lang, payload.sysname)
        .then(data => data.json())
        .then(res => {
          console.log(res);
          if (res) {
            next(getBrokerPageSuccess(res));
          } else {
            next(getBrokerPageFailed(res));
          }
        });
      break;

    case ATTEMPT_GET_PLATFORM_DETAILS:
      Api.getPlatformDetails(payload.lang, payload.sysname)
        .then(data => data.json())
        .then(res => {
          console.log(res);
          if (res) {
            next(getPlatformDetailsSuccess(res));
          } else {
            next(getPlatformDetailsFailed(res));
          }
        });
      break;
    case SELECT_INSTRUMENTS:
      Api.getChartInstruments(payload.Group)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(selectInstrumentSuccess(res));
          } else {
            next(selectInstrumentFailed(res));
          }
        });
      break;
    case ATTEMPT_GET_PLATFORM_LIST:
      Api.getPlatforms(payload.lang)
        .then(data => data.json())
        .then(res => {
          next(getPlatformListSuccess(res));
        });
      break;
    case ATTEMPT_GET_TOP_PROMOTIONS:
      Api.getTopPromotions(payload.lang)
        .then(data => data.json())
        .then(res => {
          next(getTopPromotionsSuccess(res));
        });
      break;
    case ATTEMPT_GET_LATEST_REVIEWS:
      Api.getLatestReviews(payload.sysname, payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          next(getLatestReviewsSuccess(res));
        });
      break;
    case ATTEMPT_GET_LAST_MARKET_OVERVIEW:
      Api.getLastMarketOverview(payload.lang)
        .then(data => data.json())
        .then(res => {
          next(getLastMarketOverviewSuccess(res));
        });
      break;
    case ATTEMPT_GET_MARKET_OVERVIEW_LIST:
      Api.getOverviewList(payload.lang)
        .then(data => data.json())
        .then(res => {
          next(getMarketOverviewListSuccess(res));
        });
      break;
    case ATTEMPT_GET_MARKET_OVERVIEW_DETAILS:
      Api.getOverviewDetails(payload.lang, payload.slug)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getMarketOverviewDetailsSuccess(res));
          } else {
            next(getMarketOverviewDetailsFailed(res));
          }
        });
      break;
    case ATTEMPT_LAST_VIDEO:
      Api.getLastVideo(payload.lang)
        .then(data => data.json())
        .then(res => {
          next(getLastVideoSuccess(res));
        });
      break;
    case ATTEMPT_VIDEO_LIST:
      Api.getVideosList(payload.lang, payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          next(getVideoListSuccess(res));
        });
      break;
    case ATTEMPT_GET_TECH_ANALYS_LIST:
      Api.getTechAnalysList(
        payload.lang,
        payload.countPerPage,
        payload.page,
        payload.dateInterval,
        payload.instrGroup,
        payload.instrName
      )
        .then(data => data.json())
        .then(res => {
          next(getTechAnalysListSuccess(res));
        });
      break;
    case ATTEMPT_GET_TECH_ANALYS_DETAILS:
      Api.getTechAnalysDetail(payload.lang, payload.instrName, payload.date)
        .then(data => data.json())
        .then(res => {
          next(getTechAnalysDetailsSuccess(res));
        });
      break;
    case ATTEMPT_GET_ARTICLE_LIST:
      Api.getArticlesList(payload.lang, payload.countPerPage, payload.page)
        .then(data => data.json())
        .then(res => {
          next(getArticleListSuccess(res));
        });
      break;
    case ATTEMPT_GET_ARTICLE_DETAILS:
      Api.getArticleDetails(payload.slug, payload.lang, payload.page)
        .then(data => data.json())
        .then(res => {
          next(getArticleDetailsSuccess(res));
        });
      break;

    case GET_BROKERS_FILTER:
      Api.getLanguagesFilter(payload.lang)
        .then(data => data.json())
        .then(res => {
          next(getBrokersFilterSuccess(res));
        });
      break;

    case ATTEMPT_TOP_TRADES:
      Api.getTopTrades(payload.lang, payload.group)
        .then(data => data.json())
        .then(res => {
          if (res) {
            next(getTopTradesSuccess(res));
          } else {
            next(getTopTradesFailed(res));
          }
        });
      break;

    case LIVE_QUOTE_CHANGE:
      {
        const { index } = payload;
        if (openedSocket[index]) {
          openedSocket[index].close();
          openedSocket[index] = null;
        }
        const readyConnection = quote => {
          Socket.sendQuote(payload.newQuote.name.toUpperCase());
        };

        const getQuote = response => {
          next({
            type: LIVE_QUOTE_CHANGE_SUCESSED,
            payload: {
              ...action.payload,
              newQuote: { ...payload.newQuote, value: response }
            }
          }); //todo asdasds
        };

        const Socket = new ForexGradSocket(readyConnection, getQuote);

        openedSocket[index] = Socket;
      }
      break;

    case GET_SYMBOL_QUOTE_DATA:
      {
        if (QuoteSocketData) {
          QuoteSocketData.close();
          QuoteSocketData = null;
        }

        const readyConnection = newQuoteData => {
          QuoteSocket.sendQuote(payload.newCurrency);
        };

        const getQuote = response => {
          if (QuoteSocket) {
            // console.error("QuoteSocket", QuoteSocket);
            // QuoteSocket.close();
            next({
              type: GET_SYMBOL_QUOTE_DATA_SUCCESS,
              payload: { newQuoteData: response }
            }); //todo asdasds
          }
        };

        QuoteSocket = new ForexGradSocket(readyConnection, getQuote);
        QuoteSocketData = QuoteSocket;
      }
      break;

    case GET_CURRENCY_DESCRIPTION:
      Api.getCurDesc(payload.cur)
        .then(data => data.json())
        .then(res => {
          next(getCurrencyDescriptionSuccess(res));
        });
      break;

    case CLOSE_QUOTE_SOCKET: {
      // console.error("QuoteSocketQuoteSocketQuoteSocket", QuoteSocket);
      if (QuoteSocket) {
        QuoteSocket.close();
        QuoteSocket = null;
      }
    }

    default:
      next(action);
  }
};

export default apiMiddleWare;
