import React from "react";
import OutsideAlerter from "./OutsideAlerter";
import "./Rss.css";
import {
  setSelectorArray,
  setStockExchangeArray
} from "../../../redux/actions/indexActions";
import { translate } from "react-i18next";
import { connect } from "react-redux";

class Rss2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      show: false,

      Materials: false,
      Real_estate: false,
      Energy: false,
      Financials: false,
      Consumer_Staples: false,
      Health_Care: false,
      Industrials: false,
      Information_Technolo: false,
      Communication_Servic: false,
      Trading: false,
      Consumer_Discretiona: false,
      Utilities: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // alert("You clicked outside of me!");
    }
  }
  render() {
    console.log(this.state.show, "show");
    const {
      selectAll,
      show,
      Materials,
      Real_estate,
      Energy,
      Financials,
      Consumer_Staples,
      Health_Care,
      Industrials,
      Information_Technolo,
      Communication_Servic,
      Trading,
      Consumer_Discretiona,
      Utilities
    } = this.state;
    const totalCount =
      Materials +
      Real_estate +
      Energy +
      Financials +
      Consumer_Staples +
      Health_Care +
      Industrials +
      Information_Technolo +
      Communication_Servic +
      Trading +
      Consumer_Discretiona +
      Utilities;

    return (
      <div style={{ width: "200px", marginBottom: "20px" }}>
        <OutsideAlerter handledShow={() => this.setState({ show: false })}>
          <div className="first" onClick={() => this.setState({ show: !show })}>
            <div
              className={`diiiv ${totalCount === 12 ? "active" : ""}`}
              onClick={() => {
                this.setState({ selectAll: !selectAll, show: true }, () => {
                  selectAll
                    ? this.setState(
                        {
                          Materials: false,
                          Real_estate: false,
                          Energy: false,
                          Financials: false,
                          Consumer_Staples: false,
                          Health_Care: false,
                          Industrials: false,
                          Information_Technolo: false,
                          Communication_Servic: false,
                          Trading: false,
                          Consumer_Discretiona: false,
                          Utilities: false
                        },
                        () => {
                          const stockExchangeData = [];
                          this.props.setSelectorArray(stockExchangeData);
                        }
                      )
                    : this.setState(
                        {
                          Materials: true,
                          Real_estate: true,
                          Energy: true,
                          Financials: true,
                          Consumer_Staples: true,
                          Health_Care: true,
                          Industrials: true,
                          Information_Technolo: true,
                          Communication_Servic: true,
                          Trading: true,
                          Consumer_Discretiona: true,
                          Utilities: true
                        },
                        () => {
                          const stockExchangeData = [
                            "36",
                            "92",
                            "39",
                            "40",
                            "89",
                            "41",
                            "42",
                            "43",
                            "44",
                            "90",
                            "91",
                            "45"
                          ];
                          this.props.setSelectorArray(stockExchangeData);
                        }
                      );
                });
              }}
            />
            <div className="text">{`Sector(${Materials +
              Real_estate +
              Energy +
              Financials +
              Consumer_Staples +
              Health_Care +
              Industrials +
              Information_Technolo +
              Communication_Servic +
              Trading +
              Consumer_Discretiona +
              Utilities})`}</div>
          </div>
          {show && (
            <div
              className="second"
              onClick={() => this.setState({ show: true })}
            >
              <div className="secondItem">
                <div
                  className={`diiiv ${Materials ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Materials: !Materials }, () => {
                      const stockExchangeData = [
                        !Materials ? "36" : "",
                        Real_estate ? "92" : "",
                        Energy ? "39" : "",
                        Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        Health_Care ? "41" : "",
                        Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Materials</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Real_estate ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Real_estate: !Real_estate }, () => {
                      const stockExchangeData = [
                        Materials ? "36" : "",
                        !Real_estate ? "92" : "",
                        Energy ? "39" : "",
                        Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        Health_Care ? "41" : "",
                        Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Real_estate</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Energy ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Energy: !Energy }, () => {
                      const stockExchangeData = [
                        Materials ? "36" : "",
                        Real_estate ? "92" : "",
                        !Energy ? "39" : "",
                        Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        Health_Care ? "41" : "",
                        Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Energy</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Financials ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Financials: !Financials }, () => {
                      const stockExchangeData = [
                        Materials ? "36" : "",
                        Real_estate ? "92" : "",
                        Energy ? "39" : "",
                        !Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        Health_Care ? "41" : "",
                        Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Financials</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Consumer_Staples ? "active" : ""}`}
                  onClick={() =>
                    this.setState(
                      { Consumer_Staples: !Consumer_Staples },
                      () => {
                        const stockExchangeData = [
                          Materials ? "36" : "",
                          Real_estate ? "92" : "",
                          Energy ? "39" : "",
                          Financials ? "40" : "",
                          !Consumer_Staples ? "89" : "",
                          Health_Care ? "41" : "",
                          Industrials ? "42" : "",
                          Information_Technolo ? "43" : "",
                          Communication_Servic ? "44" : "",
                          Trading ? "90" : "",
                          Consumer_Discretiona ? "91" : "",
                          Utilities ? "45" : ""
                        ];
                        this.props.setSelectorArray(stockExchangeData);
                      }
                    )
                  }
                />
                <div className="text">Consumer_Staples</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Health_Care ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Health_Care: !Health_Care }, () => {
                      const stockExchangeData = [
                        Materials ? "36" : "",
                        Real_estate ? "92" : "",
                        Energy ? "39" : "",
                        Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        !Health_Care ? "41" : "",
                        Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Health_Care</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Industrials ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Industrials: !Industrials }, () => {
                      const stockExchangeData = [
                        Materials ? "36" : "",
                        Real_estate ? "92" : "",
                        Energy ? "39" : "",
                        Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        Health_Care ? "41" : "",
                        !Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Industrials</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Information_Technolo ? "active" : ""}`}
                  onClick={() =>
                    this.setState(
                      { Information_Technolo: !Information_Technolo },
                      () => {
                        const stockExchangeData = [
                          Materials ? "36" : "",
                          Real_estate ? "92" : "",
                          Energy ? "39" : "",
                          Financials ? "40" : "",
                          Consumer_Staples ? "89" : "",
                          Health_Care ? "41" : "",
                          Industrials ? "42" : "",
                          !Information_Technolo ? "43" : "",
                          Communication_Servic ? "44" : "",
                          Trading ? "90" : "",
                          Consumer_Discretiona ? "91" : "",
                          Utilities ? "45" : ""
                        ];
                        this.props.setSelectorArray(stockExchangeData);
                      }
                    )
                  }
                />
                <div className="text">Information_Technolo</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Communication_Servic ? "active" : ""}`}
                  onClick={() =>
                    this.setState(
                      { Communication_Servic: !Communication_Servic },
                      () => {
                        const stockExchangeData = [
                          Materials ? "36" : "",
                          Real_estate ? "92" : "",
                          Energy ? "39" : "",
                          Financials ? "40" : "",
                          Consumer_Staples ? "89" : "",
                          Health_Care ? "41" : "",
                          Industrials ? "42" : "",
                          Information_Technolo ? "43" : "",
                          !Communication_Servic ? "44" : "",
                          Trading ? "90" : "",
                          Consumer_Discretiona ? "91" : "",
                          Utilities ? "45" : ""
                        ];
                        this.props.setSelectorArray(stockExchangeData);
                      }
                    )
                  }
                />
                <div className="text">Communication_Servic</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Trading ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Trading: !Trading }, () => {
                      const stockExchangeData = [
                        Materials ? "36" : "",
                        Real_estate ? "92" : "",
                        Energy ? "39" : "",
                        Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        Health_Care ? "41" : "",
                        Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        !Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Trading</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Consumer_Discretiona ? "active" : ""}`}
                  onClick={() =>
                    this.setState(
                      { Consumer_Discretiona: !Consumer_Discretiona },
                      () => {
                        const stockExchangeData = [
                          Materials ? "36" : "",
                          Real_estate ? "92" : "",
                          Energy ? "39" : "",
                          Financials ? "40" : "",
                          Consumer_Staples ? "89" : "",
                          Health_Care ? "41" : "",
                          Industrials ? "42" : "",
                          Information_Technolo ? "43" : "",
                          Communication_Servic ? "44" : "",
                          Trading ? "90" : "",
                          !Consumer_Discretiona ? "91" : "",
                          Utilities ? "45" : ""
                        ];
                        this.props.setSelectorArray(stockExchangeData);
                      }
                    )
                  }
                />
                <div className="text">Consumer_Discretiona</div>
              </div>
              <div className="secondItem">
                <div
                  className={`diiiv ${Utilities ? "active" : ""}`}
                  onClick={() =>
                    this.setState({ Utilities: !Utilities }, () => {
                      const stockExchangeData = [
                        Materials ? "36" : "",
                        Real_estate ? "92" : "",
                        Energy ? "39" : "",
                        Financials ? "40" : "",
                        Consumer_Staples ? "89" : "",
                        Health_Care ? "41" : "",
                        Industrials ? "42" : "",
                        Information_Technolo ? "43" : "",
                        Communication_Servic ? "44" : "",
                        Trading ? "90" : "",
                        Consumer_Discretiona ? "91" : "",
                        !Utilities ? "45" : ""
                      ];
                      this.props.setSelectorArray(stockExchangeData);
                    })
                  }
                />
                <div className="text">Utilities</div>
              </div>
            </div>
          )}
        </OutsideAlerter>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    selectorArray: store.selectorArray
  };
};
function mapDispatchToProps(dispatch) {
  return {
    setStockExchangeArray: componentState =>
      dispatch(setStockExchangeArray(componentState)),
    setSelectorArray: componentState =>
      dispatch(setSelectorArray(componentState))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(Rss2)
);
