import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  BROKERS_ABOUT_US,
  BROKERS_ANALYTICS,
  BROKERS_CALENDARS,
  BROKERS_CHARTS,
  BROKERS_EDUCATIONS,
  BROKERS_FOREX_TRADING_BOOK,
  BROKERS_FOREX_VIDEO_TUTORIAL,
  BROKERS_ROUTE,
  BROKERS_TRADING_PLATFORMS,
  HOME_PAGE_ROUTE,
  BROKER_PAGE,
  BROKERS_INNOVATION,
  EDUCATION_TRADERS_GLASSARY,
  BROKER_DETAILS_PAGE,
  ARTICLES,
  PLATFORM_DETAILS_PAGE,
  BROKERS_FOREX_VIDEO_OVERVIEW,
  MARKET_OVERVIEW_LIST,
  MARKET_OVERVIEW_DETAILS,
  BROKERS_ANALYTICS_DETAILS,
  ARTICLES_DETAIL,
  BROKERS_CHARTS_CUR,
  MARKET_SENTIMENT,
  MARKET_MOVERS,
  TOP_TRADERS,
  MARKET_OVERVIEW,
  MARKET_COMMODITY_CALENDAR,
  RSS_1,
  RSS_2,
  RSS_3,
  RSS_4,
  RSS_5,
  RSS_6,
  RSS_7,
  RSS_8,
  RSS_9,
  RSS_10,
  RSS_11,
  RSS_12,
  RSS_13,
  RSS_14,
  RSS_15,
  RSS_16,
  RSS_18,
  RSS_17,
  RSS_19,
  EXPERTS,
  RSS_20,
  RSS_21,
  RSS_22,
  ARTICLES_RSS,
  OUR_ARTICLES_DETAIL
} from "./constants";
import Content from "../components/content/Index";
import AboutUs from "../components/AboutUs";
import Broker from "../components/Broker";
import TradingPlatform from "../components/TradingPlatform";
import Educations from "../containers/Educations";
import ForexTradingBooks from "../containers/education/ForexTradingBooks";
import ForexVideoTutorial from "../containers/education/ForexVideoTutorial";
import CurrencyTechnicalAnalysis from "../containers/analysis/CurrencyMarketTechnicalAnalysis";
import LiveChart from "../containers/charts";
import AllEconomicCalendar from "../containers/AllEconomicCalendar";
import BrokerPage from "../containers/brokers/BrokerPage";
import Innovations from "../containers/innovations/Innovations";
import EducationTradersGlossary from "../containers/EducationTradersGlossary";
import Articles from "../containers/articles/Articles";
import { connect } from "react-redux";
import TradingPlatformDetails from "../containers/TradingPlatformDetails";
import VideoOverview from "../containers/videoOvervie/VideoOverview";
import MarketOverViewList from "../containers/marketOverview/MarketOverViewList";
import MarketOverviewDetail from "../containers/marketOverview/MarketOverviewDetail";
import TechnicalAnalysisDetails from "../containers/analysis/TechnicalAnalysisDetails";
import AllArticles from "../containers/articles/AllArticles";
import MarketSentiment from "../containers/analytics/MarketSentiment";
import CommodityMarketCalendar from "../containers/analytics/CommodityMarketCalendar";
import MarketMovers from "../containers/analytics/MarketMovers/MarketMovers";
import TopTrades from "../containers/analytics/TopTrades";
import MarketOverview from "../containers/analytics/MarketOverview";
import RssParser from "../containers/rss/RssParser";
import { images } from "../containers/rss/helper";
import RssParserWithImage from "../containers/rss/RssParserWithImage";
import RssParserWithImageClick from "../containers/rss/RssParserWithImageClick";
import RssParserExpert from "../containers/rss/RssParserExpert";
import Experts from "../containers/Experts";
import TechnicalAnalysisRss from "../containers/analysis/TechnicalAnalysisRss";
import ArticlesRss from "../containers/articles/ArticlesRss";
import OurArticles from "../containers/articles/OurArticles";

class Routes extends React.Component {
  componentDidMount() {
    document.body.dir = `${
      this.props.language === "en" ? "global_EN" : "wwww"
    }`;
  }

  render() {
    return (
      <Switch>
        <Route exact path={HOME_PAGE_ROUTE} component={Content} />
        <Route exact path={BROKERS_ABOUT_US} component={AboutUs} />
        <Route exact path={BROKERS_ROUTE} component={Broker} />
        <Route path={BROKER_DETAILS_PAGE} component={BrokerPage} />
        <Route
          exact
          path={PLATFORM_DETAILS_PAGE}
          component={TradingPlatformDetails}
        />
        <Route
          path={BROKERS_CALENDARS}
          component={() => (
            <AllEconomicCalendar
              width={"widthcalendarTab"}
              heigth={"heigthNewTab"}
            />
          )}
        />
        <Route path={BROKERS_TRADING_PLATFORMS} component={TradingPlatform} />
        <Route path={BROKERS_EDUCATIONS} component={Educations} />
        <Route
          path={EDUCATION_TRADERS_GLASSARY}
          component={EducationTradersGlossary}
        />
        <Route
          path={BROKERS_FOREX_TRADING_BOOK}
          component={ForexTradingBooks}
        />
        <Route
          path={BROKERS_FOREX_VIDEO_TUTORIAL}
          component={ForexVideoTutorial}
        />
        <Route path={BROKERS_FOREX_VIDEO_OVERVIEW} component={VideoOverview} />
        <Route exact path={BROKERS_CHARTS} component={LiveChart} />
        <Route exact path={BROKERS_CHARTS_CUR} component={LiveChart} />
        <Route path={BROKER_PAGE} component={BrokerPage} />
        <Route path={BROKERS_INNOVATION} component={Innovations} />
        <Route exact path={ARTICLES} component={AllArticles} />
        <Route path={ARTICLES_DETAIL} component={Articles} />
        <Route path={OUR_ARTICLES_DETAIL} component={OurArticles} />

        {/*<Route path={EXPERTS} component={Experts} />*/}

        <Route
          exact
          path={BROKERS_ANALYTICS}
          component={TechnicalAnalysisRss}
          // component={CurrencyTechnicalAnalysis}
        />
        {/*<Route exact path={MARKET_SENTIMENT} component={MarketSentiment} />*/}
        <Route
          exact
          path={MARKET_COMMODITY_CALENDAR}
          component={CommodityMarketCalendar}
        />
        <Route path={MARKET_MOVERS} component={MarketMovers} />
        {/*<Route exact path={TOP_TRADERS} component={TopTrades} />*/}
        {/*<Route exact path={MARKET_OVERVIEW} component={MarketOverview} />*/}
        <Route
          exact
          path={BROKERS_ANALYTICS_DETAILS}
          component={TechnicalAnalysisDetails}
        />

        <Route path={MARKET_OVERVIEW_LIST} component={MarketOverViewList} />
        <Route
          path={MARKET_OVERVIEW_DETAILS}
          component={MarketOverviewDetail}
        />
        <Route
          path={RSS_1}
          component={() => <RssParser currentPage="economictimes-forex" />}
        />
        <Route
          path={RSS_2}
          component={() => (
            <RssParser
              // images={images}
              currentPage="myfxbook-forex-news"
            />
          )}
        />
        <Route
          path={RSS_3}
          component={() => (
            <RssParser
              images={images}
              currentPage="gurutrade-fundamental-analysis"
            />
          )}
        />
        <Route
          path={RSS_4}
          component={() => (
            <RssParser images={images} currentPage="cnbc-device" />
          )}
        />
        <Route
          path={RSS_5}
          component={() => (
            <RssParser
              // images={images}
              currentPage="tradingfloor-steen-jakobsen"
            />
          )}
        />
        <Route
          path={RSS_9}
          component={() => (
            <RssParser
              // images={images}
              currentPage="tradingfloor-john-j-hardy"
            />
          )}
        />
        <Route
          path={RSS_10}
          component={() => (
            <RssParser
              // images={images}
              currentPage="tradingfloor-ole-hansen"
            />
          )}
        />
        <Route
          path={RSS_15}
          component={() => (
            <RssParser
              // images={images}
              currentPage="tradingfloor-tv-rss"
            />
          )}
        />
        <Route
          path={RSS_16}
          component={() => (
            <RssParser
              // images={images}
              currentPage="tradingfloor-tv-atom"
            />
          )}
        />

        <Route
          path={RSS_6}
          component={() => (
            <RssParser
              // images={images}
              currentPage="instaforex-news"
            />
          )}
        />
        <Route
          path={RSS_7}
          component={() => (
            <RssParser
              // images={images}
              currentPage="instaforex-analytics"
            />
          )}
        />
        <Route
          path={RSS_8}
          component={() => (
            <RssParser
              // images={images}
              currentPage="myfxbook-forex-community"
            />
          )}
        />
        <Route
          path={RSS_11}
          component={() => (
            <RssParserWithImageClick
              // images={images}
              currentPage="orbex-technical-analysis"
            />
          )}
        />
        <Route
          path={RSS_12}
          component={() => (
            <RssParserWithImage
              // images={images}
              currentPage="orbex-fundamental-analysis"
            />
          )}
        />
        <Route
          path={RSS_13}
          component={() => (
            <RssParser
              // images={images}
              currentPage="liteforex-trading"
            />
          )}
        />
        <Route
          path={RSS_14}
          component={() => (
            <RssParser
              // images={images}
              currentPage="freshforex-analitics"
            />
          )}
        />
        <Route
          path={RSS_17}
          component={() => (
            <RssParser
              // images={images}
              currentPage="ezinearticle-category"
            />
          )}
        />
        <Route
          path={RSS_18}
          component={() => (
            <RssParserExpert
              // images={images}
              currentPage="ezinearticles-Ahsan-Khan"
            />
          )}
        />
        <Route
          path={RSS_19}
          component={() => (
            <RssParserExpert
              // images={images}
              currentPage="ezinearticles-Lance-Winslow"
            />
          )}
        />
        {/*https://127.0.0.1:2083/rss/ifcm-market-sentiment?lang=en*/}
        <Route path={MARKET_SENTIMENT} component={MarketSentiment} />
        <Route path={MARKET_OVERVIEW} component={MarketOverview} />
        <Route path={ARTICLES} component={ArticlesRss} />

        <Route path={TOP_TRADERS} component={TopTrades} />
      </Switch>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language
  };
};

export default connect(mapStateToProps)(Routes);
