import React from "react";
// import LangMenu from "./LangMenu";
import ButtonNav from "./ButtonNav";
import "./../../assets/css/components/head.css";
import { Link } from "react-router-dom";

export default class Index extends React.Component {
  render() {
    return (
      <div className="header container">
        <div className="top container">
          <Link to={"/"} className="logo" />
          <div className="right_part">
            {/*<Search />*/}
            {/*<Watch />*/}
            {/*<LangMenu />*/}
            <ButtonNav />
          </div>
        </div>
      </div>
    );
  }
}
