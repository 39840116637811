import React, { Component } from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import apiMiddleWare from "./redux/apiMiddleWare";
import mainReducer from "./redux/reducers";
import Header from "./components/header/Index";
import Footer from "./components/Footer";
import "./assets/css/App.css";
import "./assets/css/global.css";
import "./assets/css/gorexgrad.css";
import MenuNav from "./components/header/MenuNav";
import { BrowserRouter } from "react-router-dom";
import Routes from "./configs/routes";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-KCLGMJQ"
};

TagManager.initialize(tagManagerArgs);
const store = createStore(
  mainReducer,
  applyMiddleware(
    // logger,
    apiMiddleWare
    // logger
  )
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Header />
          <MenuNav />
          <div className="content">
            <Routes />
          </div>
          <Footer />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
