import React from "react";
import { Link } from "react-router-dom";
import {
  ARTICLES,
  BROKERS_ABOUT_US,
  BROKERS_CHARTS,
  MARKET_COMMODITY_CALENDAR,
  MARKET_MOVERS,
  MARKET_OVERVIEW,
  MARKET_SENTIMENT,
  TOP_TRADERS
} from "../../configs/constants";
import { BROKERS_ROUTE } from "../../configs/constants";
import { BROKERS_ANALYTICS } from "../../configs/constants";
import { BROKERS_TRADING_PLATFORMS } from "../../configs/constants";
import { BROKERS_CALENDARS } from "../../configs/constants";
import { MARKET_OVERVIEW_LIST } from "../../configs/constants";
import { BROKERS_INNOVATION } from "../../configs/constants";
import { translate } from "react-i18next";
import LangMenu from "./LangMenu";
import Watch from "./Watch";

class MenuNavFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag_none: "none"
    };
  }

  openNav = () => {
    document.getElementById("mobMenu").style.width = "300px";
    var body = document.body;
    body.classList.add("body_hover");
    if (document.getElementById("fil")) {
      document.getElementById("fil").style.width = "0";
      var content = document.getElementsByClassName("content")[0];
      content.classList.remove("content_hover");
      document
        .getElementsByClassName("black_opacity")[0]
        .classList.remove("active");
    }

    document.getElementsByTagName("body")[0].addEventListener("click", () => {
      document.getElementById("mobMenu").style.width = "0";
      var body = document.body;
      body.classList.remove("body_hover");
    });
  };

  closeNav = () => {
    document.getElementById("mobMenu").style.width = "0";
    var body = document.body;
    body.classList.remove("body_hover");
  };

  scrolling() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="menu">
        <div className="container">
          <LangMenu />
          <Watch />
          <div>
            <button type="img" className="close sendsubmit">
              <i className="icon-search"></i>
            </button>
            <div id="menu_button" onClick={this.openNav.bind(this)}>
              &#9776;
            </div>
          </div>
          <div className="top_menu_wrapper">
            <ul
              id="mobMenu"
              className="menu_list"
              onClick={this.closeNav.bind(this)}
            >
              <li>
                <div className="closebtn">&times;</div>
              </li>
              <li>
                <Link onClick={this.scrolling.bind(this)} to={BROKERS_ABOUT_US}>
                  {/*About Us*/}
                  {t("ABOUT_US")}
                </Link>
              </li>
              <li>
                <Link onClick={this.scrolling.bind(this)} to={BROKERS_CHARTS}>
                  {/*Charts*/}
                  {t("GO_TO_CHART_HEADER")}
                </Link>
              </li>
              <li>
                <Link onClick={this.scrolling.bind(this)} to={BROKERS_ROUTE}>
                  {/*Brokers*/}
                  {t("BROKERS")}
                </Link>
              </li>
              {/*<li>*/}
              {/*    <Link onClick={this.scrolling.bind(this)} to={BROKERS_ANALYTICS}>*/}
              {/*        /!*Analytics*!/*/}
              {/*        {(t('ANALYTICS'))}*/}
              {/*    </Link>*/}
              {/*</li>*/}
              <li>
                <Link onClick={this.scrolling.bind(this)} to={ARTICLES}>
                  {/*Articles*/}
                  {t("ADVISORS_LINKS")}
                </Link>
              </li>
              <li>
                {/*<Link onClick={this.scrolling.bind(this)} to={BROKERS_FOREX_TOOLS}>*/}
                {/*/!*Forex Tools*!/*/}
                {/*{(t('FOREX_TOOLS'))}*/}
                {/*</Link>*/}
              </li>
              <li>
                <Link
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_TRADING_PLATFORMS}
                >
                  {/*Trading Platform***/}
                  {t("TRADING_PLATFORM")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_CALENDARS}
                >
                  {/*Calendars*/}
                  {t("CALENDARS")}
                </Link>
              </li>
              <li>
                <Link
                  onClick={this.scrolling.bind(this)}
                  to={MARKET_OVERVIEW_LIST}
                >
                  {/*News*/}
                  {t("WIDGETS_NEWS")}
                </Link>
              </li>
              <li>
                {/*<Link onClick={this.scrolling.bind(this)} to={BROKERS_EDUCATIONS}>*/}
                {/*/!*Educations*!/*/}
                {/*{(t('EDUCATIONS'))}*/}
                {/*</Link>*/}
              </li>
              <li>
                <Link
                  onClick={this.scrolling.bind(this)}
                  to={BROKERS_INNOVATION}
                >
                  {/*Innovations*/}
                  {t("INNOVATIONS")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("common")(MenuNavFooter);
