import React from "react";
import * as RSSParser from "rss-parser";
import "./styles/TopTrades.css";

import "./../../assets/css/components/trading_platforms.css";
import "./../../assets/css/components/video_lessons.css";
import Pagination from "react-js-pagination";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Videos from "../videoOvervie/Videos";
import TopBrokers from "../TopBrokers";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class TopTrades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topTradesList: [],
      articleCount: 0,
      activePage: 1,
      page: 1
    };
  }

  attemptGetTopTradersList = page => {
    const MY_URL = `https://www.ifcmarkets.com/${this.props.language}/api/top-trades-list.xml?page=${page}&showDesc=true`;
    let parser = new RSSParser({
      customFields: {
        feed: ["feed", "articlesCount"]
      }
    });
    fetch(MY_URL).then(response =>
      parser.parseURL(MY_URL, (err, feed) => {
        if (err) throw err;
        this.setState({
          topTradesList: feed.items,
          articleCount: feed.articlesCount
        });
      })
    );
  };

  componentDidMount() {
    // this.attemptGetTopTradersList(this.state.activePage);
    fetch(
      `https://www.ifcm.co.uk:2083/rss/ifcm-top-trades?lang=${this.props.language}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data });
      })
      .catch(err => console.log(err));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) {
      this.attemptGetTopTradersList(this.state.activePage);
    }
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber }, () =>
      this.attemptGetTopTradersList(this.state.activePage)
    );
  }
  getDate = arg => {
    const date = new Date(arg);
    let month = null;
    if (date.getMonth() + 1 < 10) {
      month = "0" + (date.getMonth() + 1);
    } else month = date.getMonth() + 1;
    let day = null;
    if (date.getDate() + 1 < 10) {
      day = "0" + (date.getDate() + 1);
    } else day = date.getDate() + 1;

    return date.getFullYear() + "-" + month + "-" + day;
  };
  render() {
    const { topTradesList, articleCount, rssItems } = this.state;
    const { t } = this.props;
    console.log(topTradesList, "ppppppppppp");
    return (
      <div>
        <Helmet>
          <title>{`Market Overview | Daily Forex News | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="row">
          <div className="container row">
            <div className="video_less_wrap main_with_left">
              <h1>
                {/*NEWS*/}
                {t("TOP_TRADES")}
              </h1>
              <div className="bg_wrap">
                {/*{marketOverviewList ? (*/}
                {/*    marketOverviewList.length > 0 ? (*/}
                {/*        marketOverviewList.map((item, index) => {*/}
                {rssItems ? (
                  rssItems.items.length &&
                  rssItems.items.map((item, index) => {
                    return (
                      <div key={index} className="video_cont_block">
                        {/*<div className="vid_cont_block_txt no-padding">*/}
                        <div>
                          <div className="video_less_title">
                            <a
                              target={"_blank"}
                              rel="noopener noreferrer"
                              href={item.link}
                              // href={item.url}
                            >
                              {/*<Link to={MARKET_OVERVIEW_DETAILS_CREATOR(item.slug)}>*/}
                              {item.title}
                              {/*</Link>*/}
                            </a>
                          </div>
                          <div className="new_cont_block tech_analysis_img">
                            {renderHtml(item.content)}
                            {/*{renderHtml(item.text)}*/}
                          </div>
                          <div className="vdl_footer_bl">
                            <div className="date_block">
                              {this.getDate(item.isoDate)}
                            </div>
                            {/*<div>source by:  <a href={item.link}>{item.sysname}</a></div>*/}
                            {/*<div className="date_block">{item.date}</div>*/}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1 className="loader">
                    {/*loading***/}
                    {t("LOADING")}
                  </h1>
                )}
              </div>
            </div>
            <div className="right_box_block">
              <div className="expel_class">
                <Videos />
              </div>
              <div className="expel_class">
                <TopBrokers />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language
  };
};
export default translate("common")(
  connect(mapStateToProps)(withRouter(TopTrades))
);
