import React from "react";
import LatestReviews from "../containers/brokers/LatestReviews";
import PerfectBroker from "../containers/brokers/ChoosingPerfectBroker";
import BrokerRating from "../components/BrokerRating";
import "../assets/css/components/brokers/forex_brokers_rating.css";
import {
  attemptGetBrokerList,
  getBrokersFilter
} from "../redux/actions/indexActions";
import { connect } from "react-redux";
import Checkbox from "../containers/filters/Checkbox";
import { Helmet } from "react-helmet";
import "../assets/css/components/brokers/sorting.css";
import { translate } from "react-i18next";

const BROKER_RATING_HEADERS = [
  "",
  "",
  "",
  "MINIMUM_SPREAD",
  "MINIMUM_DEPOSIT",
  "FOUNDED",
  ""
];

const renderHtml = props => (
  <span dangerouslySetInnerHTML={{ __html: props }}></span>
);

class Broker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterForBrokers: {
        paymentOptions: [],
        languages: [],
        advantages: [],
        tradingPlatforms: [],
        pageCount: 25,
        currentPage: 1
      },
      orderBy: "rating",
      sortBy: "desc"
    };
    this.handleChange = this.handleChange.bind(this);
    this.submituserFilterForm = this.submituserFilterForm.bind(this);
  }

  handleChange(type, value) {
    let { filterForBrokers } = this.state;
    let newArray = filterForBrokers[type];
    if (filterForBrokers[type].includes(value)) {
      // remove element frm array
      newArray = filterForBrokers[type].filter(fil => fil !== value);
    } else {
      newArray.push(value);
    }
    this.setState({
      filterForBrokers: {
        ...this.state.filterForBrokers,
        [type]: newArray
      }
    });
  }

  isOnlyMt4Checked() {
    const {
      filterForBrokers: {
        tradingPlatforms,
        paymentOptions,
        languages,
        advantages
      }
    } = this.state;
    if (paymentOptions.length || languages.length || advantages.length) {
      return false;
    }
    if (tradingPlatforms.length === 1 && tradingPlatforms[0] === "mt4") {
      return true;
    }
  }

  submituserFilterForm() {
    // console.log("OOOOOOOOO", this.props.history)
    const query = "?mt4-brokers";
    if (this.isOnlyMt4Checked()) {
      this.props.history.push(query);
    } else this.props.history.push("/brokers-ratings");
    console.log(this.state.filterForBrokers.tradingPlatformss);
    this.props.attemptGetBrokerList(
      this.props.language,
      this.state.pageCount,
      this.state.currentPage,
      this.state.sortBy,
      this.state.orderBy,
      { filterData: this.state.filterForBrokers }
    );
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    var window_width = window.innerWidth;
    if (window_width <= 900) {
      document.getElementById("fil").style.width = "0";
      var content = document.getElementsByClassName("content")[0];
      content.classList.remove("content_hover");
      document
        .getElementsByClassName("black_opacity")[0]
        .classList.remove("active");
      document.body.classList.remove("body_overflow_hidden");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      this.props.attemptGetBrokerList(
        this.props.language,
        this.state.pageCount,
        this.state.currentPage,
        this.state.sortBy,
        this.state.orderBy
      );
      this.props.getBrokersFilter(this.props.language);
    }
  }

  componentDidMount() {
    console.log("OOOOOOOOOO", this.props.location.search);
    if (
      this.props.location.search &&
      this.props.location.search === "?mt4-brokers"
    ) {
      this.setState(
        {
          filterForBrokers: {
            ...this.state.filterForBrokers,
            tradingPlatforms: ["mt4"]
          }
        },
        () => {
          this.props.attemptGetBrokerList(
            this.props.language,
            25,
            this.state.currentPage,
            this.state.sortBy,
            this.state.orderBy,
            { filterData: this.state.filterForBrokers }
          );
          this.props.getBrokersFilter(this.props.language);
        }
      );
    } else {
      this.props.attemptGetBrokerList(
        this.props.language,
        25,
        this.state.currentPage,
        this.state.sortBy,
        this.state.orderBy
      );
      this.props.getBrokersFilter(this.props.language);
    }
  }

  attemptGetBrokerList() {
    this.props.attemptGetBrokerList(
      this.props.language,
      this.state.pageCount,
      this.state.currentPage,
      this.state.sortBy,
      this.state.orderBy,
      { filterData: this.state.filterForBrokers }
    );
  }

  openFilter = () => {
    document.getElementById("fil").style.width = "300px";
    document.getElementsByClassName("black_opacity")[0].classList.add("active");
    var content = document.getElementsByClassName("content")[0];
    content.classList.add("content_hover");
    document.body.classList.add("body_overflow_hidden");
  };

  closeFilter = () => {
    document.getElementById("fil").style.width = "0";
    var content = document.getElementsByClassName("content")[0];
    content.classList.remove("content_hover");
    document
      .getElementsByClassName("black_opacity")[0]
      .classList.remove("active");
    document.body.classList.remove("body_overflow_hidden");
  };

  render() {
    console.log(this.props.history.location.search, "hihihih");
    const {
      filterForBrokers: { tradingPlatforms }
    } = this.state;
    const { history, t } = this.props;
    const date = new Date().getFullYear();

    return (
      <div>
        <Helmet>
          <title>
            {`Best Forex Brokers ${date} | Forex Brokers Reviews | ForexGrad`}
          </title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="container row aaa">
          <div
            className="black_opacity"
            onClick={this.closeFilter.bind(this)}
          />
          <div className="left_box_block left_box" id="fil">
            <div className="anim_filter_block">
              <h2>
                {/*Filters***/}
                {t("FILTERS")}
              </h2>
              <div className="filter_block">
                <div className="fitler_title">
                  {/*Payment methods***/}
                  {t("PAYMENT_METHODS")}
                </div>
                <div className="filters_list loader_block">
                  {this.props.brokersLang.paymentOptions ? (
                    this.props.brokersLang.paymentOptions.length ? (
                      this.props.brokersLang.paymentOptions.map(
                        (item, index) => {
                          return (
                            <Checkbox
                              group="paymentOptions"
                              key={index}
                              id={item.sysname}
                              title={item.name}
                              onChange={e =>
                                this.handleChange(
                                  "paymentOptions",
                                  item.sysname
                                )
                              }
                            />
                          );
                        }
                      )
                    ) : (
                      <h3>
                        {/*NO DATA FOR SHOW***/}
                        {t("NO_DATA_FOR_SHOW")}
                      </h3>
                    )
                  ) : (
                    <div className="loader">
                      {/*loading***/}
                      {t("LOADING")}
                    </div>
                  )}
                </div>
              </div>
              <div className="filter_block">
                <div className="fitler_title">
                  {/*Supported Languages*/}
                  {t("SUPPORTED_LANGUAGES")}
                </div>
                <div className="filters_list loader_block">
                  <form onSubmit={this.handleSubmit}>
                    {this.props.brokersLang.languages ? (
                      this.props.brokersLang.languages.length ? (
                        this.props.brokersLang.languages.map((item, index) => {
                          return (
                            <Checkbox
                              group="languages"
                              key={index}
                              id={item.shortCode}
                              title={item.name}
                              onChange={e =>
                                this.handleChange("languages", item.sysname)
                              }
                            />
                          );
                        })
                      ) : (
                        <h3>
                          {/*NO DATA FOR SHOW***/}
                          {t("NO_DATA_FOR_SHOW")}
                        </h3>
                      )
                    ) : (
                      <div className="loader">
                        {/*loading***/}
                        {t("LOADING")}
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="filter_block">
                <div className="fitler_title">
                  {/*Advantages***/}
                  {t("ADVANTAGES")}
                </div>
                <div className="filters_list loader_block">
                  <form onSubmit={this.handleSubmit}>
                    {this.props.brokersLang.advantages ? (
                      this.props.brokersLang.advantages.length ? (
                        this.props.brokersLang.advantages.map((item, index) => {
                          return (
                            <Checkbox
                              group="advantages"
                              key={index}
                              id={item.sysname}
                              title={item.name}
                              onChange={e =>
                                this.handleChange("advantages", item.sysname)
                              }
                            />
                          );
                        })
                      ) : (
                        <h3>
                          {/*NO DATA FOR SHOW***/}
                          {t("NO_DATA_FOR_SHOW")}
                        </h3>
                      )
                    ) : (
                      <div className="loader">
                        {/*loading***/}
                        {t("LOADING")}
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="filter_block">
                <div className="fitler_title">
                  {/*Trading platforms***/}
                  {t("TRADING_PLATFORMS")}
                </div>
                <div className="filters_list loader_block">
                  <form onSubmit={this.handleSubmit}>
                    {this.props.brokersLang.tradingPlatforms ? (
                      this.props.brokersLang.tradingPlatforms.length ? (
                        this.props.brokersLang.tradingPlatforms.map(
                          (item, index) => {
                            return (
                              <Checkbox
                                group="tradingPlatforms"
                                key={index}
                                id={item.sysname}
                                title={item.name}
                                checked={tradingPlatforms.includes(
                                  item.sysname
                                )}
                                onChange={e =>
                                  this.handleChange(
                                    "tradingPlatforms",
                                    item.sysname
                                  )
                                }
                              />
                            );
                          }
                        )
                      ) : (
                        <h3>
                          {/*NO DATA FOR SHOW***/}
                          {t("NO_DATA_FOR_SHOW")}
                        </h3>
                      )
                    ) : (
                      <div className="loader">
                        {/*loading***/}
                        {t("LOADING")}
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <button
                className="btn btn_lg button_green"
                onClick={this.submituserFilterForm}
              >
                {/*SUBMIT*/}
                {/*Search Brokers*/}
                {t("SEARCH_BROKER")}
              </button>
            </div>
          </div>
          <div className="main_with_right">
            {history.location.search ? (
              <h1>
                {/*MT4 Brokers Rating*/}
                {t("MT4_BROKERS_RAITING")}
              </h1>
            ) : (
              <h1>
                {/*Best Forex Brokers {date}***/}
                {/*{t("BEST_FOREX_BROKERS_DATE")}*/}
                {renderHtml(
                  t("BEST_FOREX_BROKERS_DATE", {
                    date: date
                  })
                )}
              </h1>
            )}
            <div className="bg_wrap container">
              <p className="brok_txt">
                {/*Looking for the best forex brokers of 2019? The choice is not*/}
                {/*easy since there is a huge variety of forex brokers in the*/}
                {/*global financial market offering trading services. The best*/}
                {/*choice is based on what requirements do the trader have. Traders*/}
                {/*should pay attention to local jurisdiction and broker*/}
                {/*regulation. In some cases, regulated forex brokers do not*/}
                {/*provide services to clients from certain regions based on their*/}
                {/*regulation. In our forex broker rating we have gathered top*/}
                {/*trusted brokers with the full package of information regarding*/}
                {/*their product and services. By looking through our rating you*/}
                {/*can choose the best forex broker for you.***/}
                {t("BETS_FOREX_BROKER")}
              </p>
              <div className="sort_by">
                <ul>
                  <li
                    className="filter_mobile_li"
                    onClick={this.openFilter.bind(this)}
                  >
                    <i className="icon-filter"></i>
                    <div>
                      {/*Filters***/}
                      {t("FILTERS")}
                    </div>
                  </li>
                  <li>
                    {/*Sort by:***/}
                    {t("SORT_BY")}:
                  </li>

                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "rating"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {/*Rating***/}
                    {t("RAITING")}
                    {this.state.orderBy === "rating" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>

                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "minDeposit"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {/*Minimal Deposit*/}
                    {t("MINIMUM_DEPOSIT")}
                    {this.state.orderBy === "minDeposit" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>

                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "minSpread"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {/*Minimal Spread*/}
                    {t("MINIMUM_SPREAD")}
                    {this.state.orderBy === "minSpread" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>
                  <li
                    onClick={() => {
                      this.setState(
                        {
                          sortBy: this.state.sortBy === "desc" ? "asc" : "desc",
                          orderBy: "founded"
                        },
                        () => this.attemptGetBrokerList()
                      );
                    }}
                  >
                    {/*Year of Foundation***/}
                    {t("FOUNDATION_YEAR")}
                    {this.state.orderBy === "founded" && (
                      <div
                        className={`arrow_${
                          this.state.sortBy === "asc" ? "up" : "down"
                        }`}
                      />
                    )}
                  </li>
                </ul>
              </div>
              <div className="sort_by mobile">
                <div>
                  <ul>
                    <li onClick={this.openFilter.bind(this)}>
                      <i className="icon-filter" />
                      <div>
                        {/*Filters***/}
                        {t("FILTERS")}
                      </div>
                    </li>
                    <li
                      onClick={() => {
                        this.setState(
                          {
                            sortBy:
                              this.state.sortBy === "desc" ? "asc" : "desc",
                            orderBy: "rating"
                          },
                          () => this.attemptGetBrokerList()
                        );
                      }}
                    >
                      <div>
                        {/*Sort by:***/}
                        {t("SORT_BY")}
                      </div>
                      <div>
                        {/*Rating***/}
                        {t("RAITING")}
                      </div>
                      {this.state.orderBy === "rating" && (
                        <div
                          className={`arrow_${
                            this.state.sortBy === "asc" ? "up" : "down"
                          }`}
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="swipe">
                Swipe table** <span className="icon-right-small" />
              </div>
              <div className="broker_list">
                <table>
                  <thead className="RatingCustom">
                    <tr>
                      {BROKER_RATING_HEADERS.map((header, index) => (
                        <th key={index}>{t(header)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.brokers ? (
                      this.props.brokers.length ? (
                        this.props.brokers.map((item, index) => {
                          return (
                            <BrokerRating
                              id={index}
                              key={index}
                              logo={item.logo}
                              name={item.name}
                              rate={item.averageRate.toFixed(1)}
                              minSpread={item.minSpread}
                              minDeposit={item.minDeposit}
                              founded={item.founded}
                              btnText={item.externalUrl}
                              sysname={item.sysname}
                            />
                          );
                        })
                      ) : (
                        <h3>
                          {/*NO DATA FOR SHOW***/}
                          {t("NO_DATA_FOR_SHOW")}
                        </h3>
                      )
                    ) : (
                      <h1 className="loader">
                        {/*loading***/}
                        {t("LOADING")}
                      </h1>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <h2>
              {/*Latest Reviews***/}
              {t("LATEST_REVIEWS")}
            </h2>
            <div className="bg_wrap container">
              <LatestReviews countPerPage={5} />
            </div>
            {history.location.search ? (
              <h1>
                {/*Choosing Perfect MT4 Broker***/}
                {t("PERFECT_BROKER_MT4")}
              </h1>
            ) : (
              <h2>
                {/*Choosing Perfect Broker***/}
                {t("PERFECT_BROKER")}
              </h2>
            )}
            {/*<h2>Choosing Perfect Broker</h2>*/}
            <div className="bg_wrap">
              <PerfectBroker search={history.location.search} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brokers: store.brokers,
    brokersLang: store.brokersLang,
    language: store.language,
    filterForBrokers: store.filterForBrokers
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetBrokerList: (lang, countPerPage, page, orderby, sortBy, filter) =>
      dispatch(
        attemptGetBrokerList(lang, countPerPage, page, orderby, sortBy, filter)
      ),
    getBrokersFilter: lang => dispatch(getBrokersFilter(lang))
    // getFiltersArray: (e) => dispatch(getFiltersArray(e))
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(Broker)
);
