// import urls from "../configs/urls";

export default class GeneralApi {
  constructor() {
    this.env = process.env.REACT_APP_API_LINK || "development";
  }

  getInstruments(group) {
    let get_params = "";
    if (group) get_params = "?group=" + group;

    return fetch(process.env.REACT_APP_API_URL + `/instrument${get_params}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  getChartInstruments(Group) {
    // console.log(Group,"ppppppppppppppppppppppppppp")
    return fetch(`https://www.ifcm.co.uk:2083/instrument?group=${Group}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  selectInstruments(group) {
    return fetch(process.env.REACT_APP_API_URL + `/instrument?group=${group}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  getBrokers(
    lang,
    countPerPage = 25,
    page = 1,
    orderby = "desc",
    sortBy = "rating",
    filter = {}
  ) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/broker?lang=${lang}&countPerPage=${countPerPage}&page=${page}&orderBy=${orderby}&sortBy=${sortBy}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(filter)
      }
    );
  }

  getBrokerPage(lang, sysname = "ifcmarkets") {
    return fetch(
      process.env.REACT_APP_API_URL + `/broker/${sysname}?lang=${lang}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getPlatformDetails(lang, sysname) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/trading-platform/details?lang=${lang}&sysname=${sysname}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getPlatforms(lang) {
    return fetch(
      process.env.REACT_APP_API_URL + `/trading-platform?lang=${lang}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getTopPromotions(lang) {
    return fetch(
      process.env.REACT_APP_API_URL + `/top-promotions?lang=${lang}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getLatestReviews(sysname, countPerPage, page) {
    let apiUrl =
      process.env.REACT_APP_API_URL +
      `/broker/latest-reviews?page=${page}&countPerPage=${countPerPage}`;
    if (sysname) {
      apiUrl += `&sysname=${sysname}`;
    }
    return fetch(apiUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  // getTechAnalysList(lang, countPerPage = 4, page = 1) {
  //     return fetch(process.env.REACT_APP_API_URL + `/tech-analys/list?lang=${lang}&count_per_page=${countPerPage}&page=${page}`, {
  //         method: 'get',
  //         headers: {
  //             'Content-Type': 'application/json',
  //         }
  //     })
  // }

  getTechAnalysList(
    lang,
    countPerPage,
    page,
    dateInterval,
    instrGroup,
    instrName
  ) {
    let query = "";
    if (lang) {
      query += `lang=${lang}`;
    }
    if (countPerPage) {
      if (lang) {
        query += `&countPerPage=${countPerPage}`;
      } else query += `?countPerPage=${countPerPage}`;
    }
    if (page) {
      if (lang && countPerPage) {
        query += `&page=${page}`;
      } else query += `?page=${page}`;
    }
    if (dateInterval) {
      if (lang && countPerPage && page) {
        query += `&dateInterval=${dateInterval}`;
      } else query += `?dateInterval=${dateInterval}`;
    }
    if (instrGroup) {
      if (lang && countPerPage && page && dateInterval) {
        query += `&instrGroup=${instrGroup}`;
      } else query += `?instrGroup=${instrGroup}`;
    }
    if (instrName) {
      if (lang && countPerPage && page && dateInterval && instrGroup) {
        query += `&instrName=${instrName}`;
      } else query += `?instrName=${instrName}`;
    }
    return fetch(process.env.REACT_APP_API_URL + `/tech-analys/list?${query}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  getTechAnalysDetail(lang, instrName, date) {
    return fetch(process.env.REACT_APP_API_URL + `/tech-analys/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ lang, instrName, date })
    });
  }

  getLastMarketOverview(lang) {
    return fetch(
      process.env.REACT_APP_API_URL + `/market-overview/last?lang=${lang}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }
  getOverviewList(lang) {
    return fetch(
      process.env.REACT_APP_API_URL + `/market-overview/list?lang=${lang}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }
  getOverviewDetails(lang, slug) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/market-overview/details?lang=${lang}&slug=${slug}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getArticlesList(lang) {
    return fetch(
      // `https://www.ifcm.co.uk:2083/articles/last?lang=en&countPerPage=7&page=1`,
      `https://www.ifcm.co.uk:2083/articles?lang=en&page=1&countPerPage=7&company=ifcm`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  //todo rss expert
  getArticleDetails(slug = "lance-winslow", lang, page) {
    return fetch(
      `https://www.ifcm.co.uk:2083/articles/${slug}?lang=${lang}&countPerPage=5&page=${page}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  //todo ifc expert
  getArticleDetailsUs(lang, slug) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/articles/details?lang=${lang}&slug=${slug}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getLastVideo(lang) {
    return fetch(process.env.REACT_APP_API_URL + `/videos/last?lang=${lang}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  getVideosList(lang, countPerPage, page) {
    return fetch(
      process.env.REACT_APP_API_URL +
        `/videos/list?lang=${lang}&countPerPage=${countPerPage}&page=${page}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getLanguagesFilter(lang) {
    return fetch(
      process.env.REACT_APP_API_URL + `/broker/filter-fields?lang=${lang}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getTopTrades(lang, group) {
    return fetch(
      process.env.REACT_APP_API_URL + `/top-trades?lang=${lang}&group=${group}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }

  getCurDesc(lang = "en", cur) {
    return fetch(
      `https://www.ifcm.co.uk:2083/phrases?lang=${lang}&phrases=${cur}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ lang, cur })
      }
    );
  }

  getPriceHistory(currency) {
    return fetch(
      `http://ifcmarkets.com:2087/api/v1/instrument/${currency}/history`,
      {
        method: "get",
        params: { from: 1562048041 },
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  }
}
