import React from "react";
import "./Rss.css";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { images } from "./helper";

const renderHtml = props => <div dangerouslySetInnerHTML={{ __html: props }} />;

class RssParser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rssItems: null
    };
  }

  attemptGetData() {
    fetch(
      `https://www.ifcm.co.uk:2083/rss/${this.props.currentPage}?lang=${this.props.language}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ rssItems: data });
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.attemptGetData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) {
      this.attemptGetData();
    }
  }

  getDate = arg => {
    const date = new Date(arg);
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  };

  render() {
    const { rssItems } = this.state;
    console.log(rssItems, "rssItems");
    const { t } = this.props;
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="main-without-left">
              <div className="container page_title">
                <div className="row">
                  <h1 className="">{this.props.currentPage}</h1>
                </div>
              </div>
              <div className="page_content">
                <div className="container">
                  {rssItems
                    ? rssItems.items.map((item, index) => {
                        return (
                          <div key={index}>
                            <div className="childlist childlist_wrapper">
                              <div className="news_title component_title">
                                <div>
                                  <h2>
                                    <a href={item.link}>{item.title}</a>
                                  </h2>
                                </div>
                              </div>
                              <div className="ifc_markets_new_wrapper_data">
                                <div className="ifc_markets_new_wrapper_data_cont">
                                  {item.isoDate
                                    ? this.getDate(item.isoDate)
                                    : item.pubDate}
                                  {/*{item.pubDate}*/}
                                </div>
                              </div>
                              <div className="advant_cont_block component_text">
                                {this.props.images && (
                                  <img
                                    src={
                                      images[
                                        Math.round(
                                          Math.floor(Math.random() * 10)
                                        )
                                      ]
                                    }
                                    height="50px"
                                    alt="image"
                                  />
                                )}
                                <p>{renderHtml(item.content)}</p>
                                <div className="button_line">
                                  <div className="getchild_wrap">
                                    <a
                                      className=" btn-sm button_outline_green"
                                      href={item.link}
                                    >
                                      {t("LEARN_MORE")}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : t("LOADING")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language
  };
};
export default translate("common")(
  connect(mapStateToProps)(withRouter(RssParser))
);
