import React from "react";
import { translate } from "react-i18next";
import {
  attemptGetTopTrades,
  onClearGroupInfo
} from "../../redux/actions/indexActions";
import { connect } from "react-redux";

class TopTradesGroup extends React.Component {
  componentDidMount() {
    this.props.attemptGetTopTrades(this.props.language, this.props.group);
  }
  componentWillUnmount() {
    this.props.onClearGroupInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.group !== prevProps.group ||
      this.props.language !== prevProps.language
    ) {
      this.props.attemptGetTopTrades(this.props.language, this.props.group);
    }
  }

  render() {
    // console.error(this.props.group, this.props.groupData);
    const { t } = this.props;
    const { groupData } = this.props;
    return groupData ? (
      <div className="trades_info tab_container">
        <div className="trades_block">
          <div className="trades_part">
            <span className="startment text_color">{groupData.instrument}</span>
            <span>
              {/*Instrument*/}
              {t("INSTRUMENT")}
            </span>
          </div>
          <div className="trades_part">
            <span className="startment">
              {`${groupData.investment.toFixed(2)}`}
            </span>
            <span>{t("INVESTMENT")}</span>
          </div>
          <div className="trades_part">
            <span className="startment text_color">
              {`$${groupData.netProfit.toFixed(2)}`}
            </span>
            <span>{t("NET_PROFIT")}</span>
          </div>
          <div className="trades_part">
            <span className="startment">{groupData.leverage}</span>
            <span>{t("LEVERAGE")}</span>
          </div>
          <div className="trades_part">
            <span className="startment">{groupData.tradingVolume}</span>
            <span>{t("TRADING_VOLUME")}</span>
          </div>
          <div className="trades_part">
            <span className="startment text_color">
              {/*BUY/LONG*/}
              {groupData.position}
            </span>
            <span>{t("POSITION")}</span>
          </div>
        </div>
        <div className="deal_cont">
          <div className="deal_left">
            <div className="deal_ttl">{t("DEAL_DETAILS")}</div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Buy Price:*/}
                {t("BUY_PRICE")}
              </span>
              <span className="deal_num">{groupData.buyPrice}</span>
            </div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Close Price:*/}
                {t("CLOSE_PRICE")}
              </span>
              <span className="deal_num">{groupData.closePrice}</span>
            </div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Opening Date:*/}
                {t("OPENING_DATE")}
              </span>
              <span className="deal_num">{groupData.openingDate}</span>
            </div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Closing Date:*/}
                {t("CLOSING_DATE")}
              </span>
              <span className="deal_num">{groupData.closingDate}</span>
            </div>
          </div>
          <div className="deal_right">
            <div className="deal_ttl">
              {/*Deal Profit / Loss Statement*/}
              {t("DEAL_PROFIT")} / {t("LOSS_STATEMENT")}
            </div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Investment:*/}
                {t("INVESTMENT")}
              </span>
              <span className="deal_num">
                ${groupData.investment.toFixed(2)}
              </span>
            </div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Equity Gain:*/}
                {t("EQUITY_GAIN")}
              </span>
              <span className="deal_num">
                ${groupData.equityGain.toFixed(2)}
              </span>
            </div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Swap Charge/Credit:*/}
                {t("SWAP_CHARGE")}/{t("CREDIT")}
              </span>
              <span className="deal_num">
                ${groupData.swapCharge.toFixed(2)}
              </span>
            </div>
            <div className="deal_block">
              <span className="deal_txt">
                {/*Return on Investment:*/}
                {t("RETURN_ON_INVESTMENT")}
              </span>
              <span className="deal_num">
                {groupData.returnOnInvestment.toFixed(2)}%
              </span>
            </div>
          </div>
        </div>
        <div className="deal_footer">
          <span>
            {/*Source:*/}
            {t("SOURCE")}
          </span>
          <a href={groupData.url} target="_blank" rel="noopener noreferrer">
            {`${groupData.instrument} `}
            {/*Technical Analysis*/}
            {t("TECHNICAL_ANALYSIS")}
          </a>
        </div>
      </div>
    ) : (
      <h1 className="loader">
        {/*loading***/}
        {t("LOADING")}
      </h1>
    );
  }
}

const mapStateToProps = store => {
  return {
    language: store.language,
    groupData: store.groupData
  };
};

function mapDispatchToProps(dispatch) {
  return {
    attemptGetTopTrades: (lang, group) =>
      dispatch(attemptGetTopTrades(lang, group)),
    onClearGroupInfo: () => dispatch(onClearGroupInfo())
  };
}

export default translate("common")(
  connect(mapStateToProps, mapDispatchToProps)(TopTradesGroup)
);
