import React from "react";
import "../../assets/css/components/innovation/innovation.css";
import AllVideos from "../videoOvervie/Videos";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const renderHtml = props => (
  <div dangerouslySetInnerHTML={{ __html: props }}></div>
);

class Innovations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staticPageData: ""
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      fetch(
        `https://www.ifcm.co.uk:2083/page/innovations?lang=${this.props.language}`,
        {
          method: "get",
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
      )
        .then(res => res.json())
        .then(data => {
          this.setState({ staticPageData: data });
        })
        .catch(err => console.log(err));
    }
  }
  componentDidMount() {
    fetch(
      `https://www.ifcm.co.uk:2083/page/innovations?lang=${this.props.language}`,
      {
        method: "get",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ staticPageData: data });
      })
      .catch(err => console.log(err));
    if (this.props.marketOverview) {
      this.setState({ marketOverview: this.props.marketOverview });
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{`Innovation in trading | ForexGrad`}</title>
          <meta name="description" content="" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>
        <div className="container row">
          <div className="main_with_left innov_block">
            {renderHtml(this.state.staticPageData.content)}
          </div>
          <div className="right_box_block">
            <div className="expel_class">
              <AllVideos />
            </div>
            <div className="expel_class">{/*<EducationSections/>*/}</div>
          </div>
        </div>
        ;
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    language: store.language
  };
};

export default translate("common")(connect(mapStateToProps)(Innovations));
