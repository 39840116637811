import React from "react";
import "./../assets/css/components/trading_platforms.css";
import "./../assets/css/components/video_lessons.css";
import Global from "../assets/css/Global.module.css";
import TopBrokers from "../containers/TopBrokers";
import EducationSteps from "../components/content/EducationSteps";
import EducationSections from "../containers/education/EducationSections";
const arr = [
  {
    img: "video_les",
    title: "What is Forex | Forex (FX) Trading Market",
    p:
      "Forex Market is a decentralized global market where all the world's currencies are traded against each other, and traders make a profit or loss from the … currencies’ value changes.",
    pdfIcon: "ifcm-pdf",
    pdfText: "Forex Trading Tutorial",
    href: "/en/video-other",
    btnHref: "/about-us",
    btnText: "Read More"
  },
  {
    img: "video_les",
    title: "What is Forex | Forex (FX) Trading Market",
    p:
      "Forex Market is a decentralized global market where all the world's currencies are traded against each other, and traders make a profit or loss from the … currencies’ value changes.",
    pdfIcon: "ifcm-pdf",
    pdfText: "Forex Trading Tutorial",
    href: "/en/video-other",
    btnHref: "/about-us",
    btnText: "Read More"
  }
];

export default class Educations extends React.Component {
  render() {
    return (
      <div className="row">
        <EducationSteps />
        <div className="container row">
          <div className="video_less_wrap main_with_left">
            <h1>Education</h1>
            <div className={Global.bg_wrap}>
              {arr.map((item, index) => {
                return (
                  <div key={index} className="video_cont_block">
                    <a href={item.href} className={item.img}>
                      <div className={Global.yout_video_icn}>
                        <div />
                      </div>
                    </a>
                    <div className="vid_cont_block_txt no-padding">
                      <div className="video_less_title">{item.title}</div>
                      <p>{item.p}</p>
                      <div className="vdl_footer_bl">
                        <div>
                          <span className={item.pdfIcon} />
                          <span className="pdf_text">{item.pdfText}</span>
                        </div>
                        <div>
                          <a
                            className="btn btn_sm button_green"
                            href={item.btnHref}
                          >
                            {item.btnText}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={Global.right_box_block}>
            <div className={Global.expel_class}>
              <EducationSections />
            </div>
            <div className={Global.expel_class}>
              <TopBrokers />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
